import { createSelector } from 'reselect'

export const readySelector = (state) => state.ready

export const socketOnlineSelector = (state) => state.socket.online
export const socketReadySelector = (state) => state.socket.ready

export const authSelector = (state) => state.auth
export const authTokenSelector = createSelector(
  [authSelector],
  (auth) => auth.accessToken
)
export const authPresentSelector = createSelector(
  [authSelector],
  (auth) => auth.refreshToken != null
)

export * from './site'
export * from './floor'
export * from './space'
export * from './room'
export * from './object'
export * from './resource'
export * from './scene'
export * from './key'
