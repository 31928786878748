import LigIcon from '../assets/lig.png'
import Sw1Icon from '../assets/sw1.png'
import Sw2Icon from '../assets/sw2.png'
import MotIcon from '../assets/mot.png'
import DooIcon from '../assets/doo.png'
import CurIcon from '../assets/cur.png'
import EnvIcon from '../assets/env.png'
import NoiIcon from '../assets/noi.png'

export const shouldEnableBleReceiverOnAssign = (object, resource) => {
  if (!object.bleScanner) return false
  if (object.attachedResources.length !== 0) return false

  return resource.protocol === "wirepas"
}

export const canModifyResourceBleReceiver = (object, objectAllAttachedResources, resource) => {
  if (!resource.bleReceiverEnabled) return true
  if(!object || !object.bleScanner) return true
  if(objectAllAttachedResources.some(r => r.id !== resource.id && r.bleReceiverEnabled)) return true

  return false
}

export const getIconSrcForObject = obj =>
  obj.type === "light"
    ? LigIcon
    : obj.type === "switch"
    ? obj.variant==="1-key"
      ? Sw1Icon
      : Sw2Icon
    : obj.type === "motionSensor"
    ? MotIcon
    : obj.type === "doorSensor"
    ? DooIcon
    : obj.type === "curtain"
    ? CurIcon
    : obj.type === "environmentalSensor"
    ? EnvIcon
    : obj.type === "noiseSensor"
    ? NoiIcon
    : "???"


export const getIconSrcForResource = r =>
  r.type === 'actuator'
    ? LigIcon
    : r.type === 'curtain'
    ? CurIcon
    : r.type === 'motionSensor'
    ? MotIcon
    : r.type === 'noiseSensor'
    ? NoiIcon
    :'?'

export const downloadFile = (fileName, mimeType, data) => {
  const blob = new Blob([data], { type: mimeType })

  const elem = window.document.createElement('a')
  elem.href = window.URL.createObjectURL(blob)
  elem.download = fileName
  document.body.appendChild(elem)
  elem.click()
  document.body.removeChild(elem)
}

export const getScrollParent = (node) => {
  if (node == null) {
    return null
  }

  if (node.scrollHeight > node.clientHeight) {
    return node
  } else {
    return getScrollParent(node.parentNode)
  }
}


export const resourceLastSeenFormatted = lastSeenTimestamp => {
  if(!lastSeenTimestamp) {
    return "NO DATA FOUND"
  }

  const secondsSinceLastSeen = Math.floor((Date.now() - lastSeenTimestamp) / 1000)
  if (secondsSinceLastSeen < 60) {
    return `${secondsSinceLastSeen} sec`
  }

  const minutesSinceLastSeen = Math.floor(secondsSinceLastSeen / 60)
  if (minutesSinceLastSeen < 30) {
    return `${minutesSinceLastSeen} min`
  } else if(minutesSinceLastSeen < 60) {
    return `${minutesSinceLastSeen} min`
  }

  const hoursSinceLastSeen = Math.floor(minutesSinceLastSeen / 60)
  if (hoursSinceLastSeen < 24) {
    return `${hoursSinceLastSeen} h`
  }

  const daysSinceLastSeen = Math.floor(hoursSinceLastSeen / 24)
  return `${daysSinceLastSeen} days`
}

export const isResourceLastSeenOffline = lastSeenTimestamp => {
  const secondsSinceLastSeen = Math.floor((Date.now() - lastSeenTimestamp) / 1000)
  const minutesSinceLastSeen = Math.floor(secondsSinceLastSeen / 60)

  return lastSeenTimestamp && minutesSinceLastSeen >= 30
}

export const getObjectDisplayText = object => {
  const { type, name } = object
  switch(type) {
    case 'light': return name
    case 'doorSensor': return `Door sensor${name ? ` • ${name}` : ''}`
    case 'motionSensor': return `Motion sensor${name ? ` • ${name}` : ''}`
    case 'switch': return `Switch${name ? ` • ${name}` : ''}`
    case 'curtain': return name
    case 'environmentalSensor': return `Environmental sensor${name ? ` • ${name}` : ''}`
    case 'noiseSensor': return `Noise sensor${name ? ` • ${name}` : ''}`
    default: return name
  }
}
