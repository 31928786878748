import React, { Component } from 'react'
import ReactDOM from 'react-dom'

import s from './Modal.module.scss'

class Modal extends Component {
  static isModalOpen() {
    return document.getElementById('modal-root').childElementCount > 0
  }

  constructor(...args) {
    super(...args)
    this.el = document.createElement('div')
  }

  componentDidMount() {
    document.getElementById('modal-root').appendChild(this.el)
  }

  componentWillUnmount() {
    document.getElementById('modal-root').removeChild(this.el)
  }

  handleOverlayClick = () => {
    const { onRequestClose } = this.props
    if (onRequestClose) {
      onRequestClose()
    }
  }

  render() {
    const { children } = this.props

    const container = (
      <div className={s.container}>
        <div className={s.overlay} onClick={this.handleOverlayClick} />
        <div className={s.modal}>
          {children}
        </div>
      </div>
    )

    return ReactDOM.createPortal(container, this.el)
  }
}

export default Modal
