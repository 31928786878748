import React, { Component } from 'react'
import classnames from 'classnames'

import s from './ListItemInput.module.scss'

class ListItemInput extends Component {
  constructor() {
    super()
    this.input = React.createRef()
  }

  handleChange = (ev) => {
    this.props.onValueChange(ev.target.value)
  }

  handleKeyDown = (ev) => {
    if (ev.key === 'Enter') {
      this.props.onConfirm && this.props.onConfirm()
    }
    if (ev.key === 'Escape') {
      this.props.onCancel && this.props.onCancel()
    }
  }

  handleBlur = (ev) => {
    this.props.onConfirm && this.props.onConfirm()
  }

  render() {
    const { value, className } = this.props

    return (
      <div className={classnames(s.container, className)}>
        <input
          ref={this.input}
          autoFocus={true}
          type="text"
          className={s.input}
          value={value}
          onChange={this.handleChange}
          onBlur={this.handleBlur}
          onKeyDown={this.handleKeyDown}
        />
      </div>
    )
  }
}

export default ListItemInput
