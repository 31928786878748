import { createSelector } from 'reselect'
import * as R from 'ramda'
import { siteConfigurationSelector } from '.'
import { siteSelector } from './site'
import { scenesSort } from '../utils/sort'

export const scenesSelector = createSelector(
  [siteConfigurationSelector],
  (siteConfiguration) => R.sortBy(scenesSort, siteConfiguration.scenes)
)

const defaultIdSelector = (state, props) => props.sceneId
const lastCreatedSceneIdSelector = createSelector([siteSelector], site => site.lastCreatedSceneId)
export const makeIsSceneJustCreatedSelector = (idSelector) => createSelector(
  [idSelector, lastCreatedSceneIdSelector],
  (sceneId, lastCreatedSceneId) => sceneId === lastCreatedSceneId
)
export const isSceneJustCreatedSelector = makeIsSceneJustCreatedSelector(defaultIdSelector)
