import React from 'react'
import cn from 'classnames'
import s from './SortOrder.module.scss'

const SortOrder = ({ active, order, asc = true, desc = true }) => (
  <span className={s.sortOrderArrows}>
    {asc && <span className={cn(s.sortOrderArrow, { [s.sortOrderActiveArrow]: active && order === 'asc'})} role="img">↑</span>}
    {desc && <span className={cn(s.sortOrderArrow, { [s.sortOrderActiveArrow]: active && order === 'desc'})} role="img">↓</span>}
  </span>
)

export default SortOrder
