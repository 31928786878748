import React, { Fragment } from 'react'
import { BrowserRouter, Switch as SwitchRoute, Route } from 'react-router-dom'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import { readySelector } from './selectors'
import SocketStatusNotificationContainer from './containers/SocketStatusNotificationContainer'
import Chrome from './containers/Chrome'
import PrivateRoute from './containers/PrivateRoute'
import SiteScreen from './containers/SiteScreen'
import SiteIndexScreen from './containers/SiteIndexScreen'
import RootScreen from './containers/RootScreen'
import SplashScreen from './containers/SplashScreen'

const PropertyManager = ({ ready }) => (
  ready
    ?
    <Fragment>
      <SocketStatusNotificationContainer />
      <Chrome>
        <BrowserRouter>
          <SwitchRoute>
            <Route exact path="/" component={RootScreen} />
            <PrivateRoute path="/sites/:siteId" component={SiteScreen} />
            <PrivateRoute path="/sites" component={SiteIndexScreen} />
          </SwitchRoute>
        </BrowserRouter>
      </Chrome>
    </Fragment>
    :
    <SplashScreen />
)

const mapStateToProps = createStructuredSelector({
  ready: readySelector,
})

export default connect(mapStateToProps)(PropertyManager)
