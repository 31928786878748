import React, { Component } from 'react'
import cn from 'classnames'

import s from './ListItem.module.scss'

const stateMouseEnter = () => ({ hover: true })
const stateMouseLeave = () => ({ hover: false })

class ListItem extends Component {
  constructor() {
    super()
    this.state = {
      hover: false,
    }
  }

  handleDoubleClick = () => {
    const { onEditClick } = this.props
    if (onEditClick) {
      onEditClick()
    }
  }

  handleMouseMove = () => {
    this.setState(stateMouseEnter)
  }

  handleMouseLeave = () => {
    this.setState(stateMouseLeave)
  }

  handleEditClick = (ev) => {
    ev.stopPropagation()
    this.props.onEditClick()
  }

  handleDeleteClick = (ev) => {
    ev.stopPropagation()
    this.props.onDeleteClick()
  }

  render() {
    const { className, onClick, onEditClick, onDeleteClick, label, children, selected, incomplete, loading, innerRef } = this.props
    const { hover } = this.state

    const hoverable = onEditClick != null || onDeleteClick != null
    const clickable = hoverable || onClick != null

    return (
      <div
        ref={innerRef}
        onClick={onClick}
        onDoubleClick={this.handleDoubleClick}
        onMouseMove={hoverable ? this.handleMouseMove : null}
        onMouseLeave={hoverable ? this.handleMouseLeave : null}
        className={cn(s.container, className, { 
          [s.containerSelected]: selected,
          [s.hoverable]: hoverable,
          [s.incomplete]: incomplete,
          [s.clickable]: clickable
        })}
      >
        {label != null &&
          <span className={s.label}>{label}</span>
        }
        {children}
        {hoverable && hover &&
          <div className={s.actionContainer}>
            {onEditClick != null &&
              <div className={s.action} onClick={this.handleEditClick}>Edit</div>
            }
            {onDeleteClick != null &&
              <div className={cn(s.action, s.actionVariantDestructive)} onClick={this.handleDeleteClick}>Del</div>
            }
          </div>
        }
        {loading &&
          <div className={s.loadingIndicator} />
        }
      </div>
    )
  }
}

export default ListItem
