import { all } from 'redux-saga/effects'

import ready from './ready'
import auth from './auth'
import supervisor from './supervisor'
import api from './api'
import inputCapture from './inputCapture'
import validateSiteView from './validateSiteView'

export default function * () {
  yield all([
    ready(),
    auth(),
    api(),
    supervisor(),
    inputCapture(),
    validateSiteView()
  ])
}
