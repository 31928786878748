import React, { Component } from 'react'

import s from './Input.module.scss'

class Input extends Component {
  handleChange = (ev) => {
    this.props.onValueChange(ev.target.value)
  }

  render() {
    return (
      <div className={s.container}>
        <input
          type="text"
          className={s.input}
          value={this.props.value}
          onChange={this.handleChange}
        />
      </div>
    )
  }
}

export default Input
