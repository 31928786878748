import React from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import s from './SocketStatusNotificationContainer.module.scss'
import { socketOnlineSelector } from '../selectors'

const SocketStatusNotificationContainer = ({ online }) => (
  online !== true &&
    <div className={s.container}>
      <div className={s.message}>
        You are disconnected from the Mount Kelvin ☁️, trying to reconnect. Changes made may not be saved.
      </div>
    </div>
)

const mapStateToProps = createStructuredSelector({
  online: socketOnlineSelector,
})

export default connect(mapStateToProps)(SocketStatusNotificationContainer)
