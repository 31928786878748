import * as R from 'ramda'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector, createSelector } from 'reselect'
import cn from 'classnames'
import { v4 as uuidv4 } from 'uuid'

import s from './FloorSectionContainer.module.scss'
import { unassignedObjectsSelector, floorsSelector, spacesSelector, indexedSpacesSelector, offlineWirepasDevicesSelector } from '../selectors'
import { siteSpaceCreate, updateSiteViewParams } from '../actions/site'
import Section from '../components/Section'
import Modal from '../components/Modal'
import EntityList from '../components/EntityList'

import wp from '../assets/wp.png'
import ble from '../assets/ble.png'
import { spacesSort } from '../utils/sort'

const resolveNextSpaceName = R.compose(
  R.toString,
  R.inc,
  R.reduce(R.max, 0),
  R.filter(R.test(/^[0-9]+$/)),
  R.map(R.compose(R.trim, R.prop('name')))
)

const spaceTemplateLabel = space => space.template || space.name

class FloorSectionContainer extends Component {
  componentDidMount() {
    window.document.addEventListener("keydown", this.onKeyDown)
  }

  componentWillUnmount() {
    window.document.removeEventListener("keydown", this.onKeyDown)
  }

  onKeyDown = (ev) => {
    if(Modal.isModalOpen()) {
      return
    }

    if (ev.key === 'ArrowUp') {
      ev.preventDefault()
      ev.stopPropagation()
      this.gotoPrev()
    } else if (ev.key === 'ArrowDown') {
      ev.preventDefault()
      ev.stopPropagation()
      this.gotoNext()
    }
  }

  gotoPrev() {
    const { dispatch, siteId, prevSpaceId } = this.props
    prevSpaceId && dispatch(updateSiteViewParams(siteId, { spaceId: prevSpaceId }))
  }

  gotoNext() {
    const { dispatch, siteId, nextSpaceId } = this.props
    nextSpaceId && dispatch(updateSiteViewParams(siteId, { spaceId: nextSpaceId }))
  }

  handleSpaceCreatePress = () => {
    const { siteId, floorId, floor, spaces, dispatch } = this.props
    const name = spaces.length === 0 ? (floor.name + '01') : resolveNextSpaceName(spaces)
    dispatch(siteSpaceCreate(siteId, { id: uuidv4(), floorId, name }))
  }

  handleSpaceCopyPress = () => {
    const { siteId, floorId, selectedSpaceId, floor, spaces, dispatch } = this.props
    const selectedSpace = spaces.find(({ id }) => id === selectedSpaceId)
    if (selectedSpace != null) {
      const name = spaces.length === 0 ? (floor.name + '01') : resolveNextSpaceName(spaces)
      const spaceBody = {
        id: uuidv4(),
        floorId,
        name,
      }
      dispatch(siteSpaceCreate(siteId, spaceBody, selectedSpace.id))
    }
  }

  handleSpaceNavigate = (space) => {
    const { siteId, dispatch } = this.props
    dispatch(updateSiteViewParams(siteId, { spaceId: space.id }))
  }

  renderSpaceLabel = (space) => {
    const { spaces, indexedSpaces, incompleteObjects, offlineWirepasDevices } = this.props
    const linkedSpaces = spaces.filter(s => s.linkedSpaceId === space.id)
    const isTemplate = Boolean(space.template) || linkedSpaces.length > 0

    const missingResources = incompleteObjects.filter(o => o.spaceId === space.id)
    const missingWp = missingResources.filter(o =>
      o.type === 'light' || o.type === 'motionSensor' || o.type === 'curtain'
    ).length
    const missingBle = missingResources.filter(o =>
      o.type === 'doorSensor' || o.type === 'switch' || o.type === 'environmentalSensor'
    ).length

    const offlineWirepasDevicesCount = offlineWirepasDevices.filter(d => d.assignedObject && d.assignedObject.spaceId === space.id).length
    return (
      <div className={s.spaceLabel}>
        <div className={s.names}>
          <span className={s.spaceName}>{space.name}</span>
          {isTemplate && <span className={cn(s.small, s.underline)}>{spaceTemplateLabel(space)}</span>}
          {!isTemplate && space.linkedSpaceId && <span className={s.small}>{spaceTemplateLabel(indexedSpaces[space.linkedSpaceId])}</span>}
        </div>
        <div className={s.info}>
          <div className={s.icons}>
            {missingWp > 0 ? <span>{missingWp} <img src={wp} /></span> : null}
            {missingBle > 0 ? <span>{missingBle} <img src={ble} /></span> : null}
          </div>
          {offlineWirepasDevicesCount > 0 && <span className={s.offline}>{offlineWirepasDevicesCount} offline</span>}
        </div>

      </div>
    )
  }

  render() {
    const { spaces, selectedSpaceId } = this.props

    return (
      <Section width="271px">
        <EntityList
          actions={[
            { label: 'Add space', onClick: this.handleSpaceCreatePress },
            { label: 'Copy selected', onClick: this.handleSpaceCopyPress },
          ]}
          emptyPlaceholderText="No spaces"
          entities={spaces}
          selectedEntityId={selectedSpaceId}
          onEntityCreatePress={this.handleSpaceCreatePress}
          onNavigate={this.handleSpaceNavigate}
          entityContent={this.renderSpaceLabel}
        />
      </Section>
    )
  }
}

const sortedSpacesSelector = createSelector(
  [spacesSelector],
  (spaces) => spacesSort(spaces)
)

const floorIdSelector = (state, props) => props.floorId

const sortedFloorSpacesSelector = createSelector(
  [floorIdSelector, sortedSpacesSelector],
  (floorId, spaces) => spaces.filter(s => s.floorId === floorId)
)
const floorSelector = createSelector(
  [floorIdSelector, floorsSelector],
  (floorId, floors) => floors.find(f => f.id === floorId)
)

const selectedSpaceIdSelector = (state, props) => props.selectedSpaceId
const prevSpaceIdSelector = createSelector(
  [sortedFloorSpacesSelector, selectedSpaceIdSelector],
  (spaces, spaceId) => {
    if(spaces.length <= 1) return null

    const index = spaces.findIndex(({ id }) => id === spaceId)
    if (index < 0) return null

    return spaces[index > 0 ? index - 1 : spaces.length - 1].id
  }
)

const nextSpaceIdSelector = createSelector(
  [sortedFloorSpacesSelector, selectedSpaceIdSelector],
  (spaces, spaceId) => {
    if(spaces.length <= 1) return null

    const index = spaces.findIndex(({ id }) => id === spaceId)
    if (index < 0) return null

    return spaces[index < spaces.length - 1 ? index + 1 : 0].id
  }
)

const mapStateToProps = createStructuredSelector({
  incompleteObjects: unassignedObjectsSelector,
  offlineWirepasDevices: offlineWirepasDevicesSelector,
  indexedSpaces: indexedSpacesSelector,
  floor: floorSelector,
  spaces: sortedFloorSpacesSelector,
  prevSpaceId: prevSpaceIdSelector,
  nextSpaceId: nextSpaceIdSelector
})

export default connect(mapStateToProps)(FloorSectionContainer)
