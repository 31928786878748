import React from 'react'

import Section from '../components/Section'
import Heading from '../components/Heading'
import TextField from '../components/TextField'
import { siteGatewayUpdatePart } from '../actions/site'
import { connect } from 'react-redux'

const GatewaySectionContainer = ({ siteId, dispatch, gateway  }) => {
  const handleGatewayNameUpdate = (location) => {
    dispatch(siteGatewayUpdatePart(siteId, gateway.id, { name: location }))
  }

  return (
    <Section>
      <Heading label='Make and model'/>
      <TextField
        initialValue={'Balena Fin'}
        allowEmpty
        disabled
      />
      <Heading label='Identifier'/>
      <TextField
        initialValue={gateway.id}
        allowEmpty
        disabled
      />
      <Heading label='Installation location'/>
      <TextField
        initialValue={gateway.name}
        allowEmpty
        onSave={handleGatewayNameUpdate}
      />
    </Section>
  )
}

export default connect()(GatewaySectionContainer)
