import React, { Component } from 'react'
import cn from 'classnames'

import s from './InlineInput.module.scss'

class InlineInput extends Component {
  constructor() {
    super()
    this.input = React.createRef()
  }

  componentDidMount() {
    setImmediate(() => this.input.current.focus())
  }

  handleChange = (ev) => {
    this.props.onValueChange(ev.target.value)
  }

  handleKeyDown = (ev) => {
    if (ev.key === 'Enter') {
      this.props.onEnter()
    }
    if (ev.key === 'Escape') {
      this.input.current.blur()
    }
  }

  render() {
    const { value, onBlur, placeholder, className } = this.props

    return (
      <div className={cn(s.container, className)}>
        <input
          ref={this.input}
          autoFocus={true}
          type="text"
          className={s.input}
          value={value}
          onChange={this.handleChange}
          onBlur={onBlur}
          onKeyDown={this.handleKeyDown}
          placeholder={placeholder}
        />
      </div>
    )
  }
}

export default InlineInput
