import { SITE_SUBSCRIBE_SUCCESS, SET_SITE_VIEW_PARAMS_VALIDATED} from '../constants/actions'

const getStateOrDefault = (state, siteId) => state[siteId] || {
  floorId: null,
  spaceId: null,
  roomId: null,
  objectId: null,
  resourceId: null,
  gatewayId: null,
  sceneId: null
}

const siteViews = (state = {}, { type, payload }) => {
  switch (type) {
    case SITE_SUBSCRIBE_SUCCESS: {
      const { siteId } = payload
      return { ...state, [siteId]: getStateOrDefault(state, siteId) }
    }
    case SET_SITE_VIEW_PARAMS_VALIDATED: {
      const { siteId, siteView } = payload
      return { ...state, [siteId]: { ...getStateOrDefault(state, siteId), ...siteView }}
    }
    default:
      return state
  }
}

export default siteViews
