import * as R from 'ramda'
import { createSelector } from 'reselect'

import { siteConfigurationSelector } from './site'

export const spacesSelector = createSelector(
  [siteConfigurationSelector],
  (siteConfiguration) => siteConfiguration.spaces
)
export const indexedSpacesSelector = createSelector(
  [spacesSelector],
  (spaces) => R.indexBy(R.prop('id'), spaces)
)
