import { createSelector } from 'reselect'
import {
  siteConfigurationSelector,
  objectsSelector,
  roomsSelector,
  spacesSelector,
  floorsSelector,
} from '.'
import { siteStateSelector } from './site'
import { isResourceLastSeenOffline } from '../utils'

export const resourcesSelector = createSelector(
  [siteConfigurationSelector],
  (siteConfiguration) => siteConfiguration.resources
)

const sortBySelector = state => state.resourceOverviewScreen.sortBy
const sortOrderSelector = state => state.resourceOverviewScreen.sortOrder
const proximitiesSelector = state => state.resourceOverviewScreen.proximities

export const resourcesLastSeenSelector = createSelector(
  [siteStateSelector, resourcesSelector],
  (siteState, resources) => Object.fromEntries(resources.map(({ id }) => [id, siteState[id] ? siteState[id].seen : null]))
)

export const sortedResourcesSelector = createSelector(
  [resourcesSelector, sortBySelector, sortOrderSelector, proximitiesSelector, resourcesLastSeenSelector],
  (resources, sortBy, sortOrder, proximities, lastSeen) => {
    const sortOrderMultiplier = sortOrder === 'desc' ? 1 : -1
    switch(sortBy) {
      case 'proximity':
        return [...resources].sort((r1, r2) => {
          const s1 = r1.address in proximities ? proximities[r1.address] : -10000
          const s2 = r2.address in proximities ? proximities[r2.address] : -10000
          return (s2 - s1) * sortOrderMultiplier
        })
      case 'heartbeat':
        return [...resources].sort((r1, r2) => {
          const s1 = r1.id in lastSeen ? lastSeen[r1.id] : 0
          const s2 = r2.id in lastSeen ? lastSeen[r2.id] : 0
          return (s2 - s1) * sortOrderMultiplier * -1
        })
      case 'firmwareVersion':
        return [...resources].sort((r1, r2) => {
          if (r2.firmwareVersion === r1.firmwareVersion) {
            return 0
          }
          if (r2.firmwareVersion == null) {
            return 1
          }
          if (r1.firmwareVersion == null) {
            return -1
          }
          return r2.firmwareVersion.localeCompare(r1.firmwareVersion, undefined, { numeric: true }) * sortOrderMultiplier
        })
      default:
        return resources
    }
  }
)

export const resourcesWithObjectSelector = createSelector(
  [sortedResourcesSelector, objectsSelector, roomsSelector, spacesSelector, floorsSelector],
  (resources, objects, rooms, spaces, floors) => {
    const roomsMap = Object.fromEntries(rooms.map(r => [r.id, r]))
    const objectsMap = Object.fromEntries(objects.map(o => [o.id, o]))
    const spacesMap = Object.fromEntries(spaces.map(s => [s.id, s]))
    const floorsMap = Object.fromEntries(floors.map(f => [f.id, f]))

    return resources.map(resource => {
      const assignedObject = objectsMap[resource.attachedObjectId]
      if (assignedObject) {
        const room = roomsMap[assignedObject.roomId]
        const space = spacesMap[room.spaceId]
        const floor = floorsMap[space.floorId]

        return {
          ...resource,
          assignedObject: {
            ...assignedObject,
            roomName: room.name,
            spaceName: space.name,
            spaceId: space.id,
            floorName: floor.name,
            floorId: floor.id
          }
        }
      }

      return resource
    })
  }
)

const defaultIdSelector = (state, props) => props.resourceId
export const makeResourceSelector = (idSelector) => createSelector(
  [resourcesWithObjectSelector, idSelector],
  (resources, id) => resources.find(resource => resource.id === id)
)
export const resourceSelector = makeResourceSelector(defaultIdSelector)

export const unassignedResourcesSelector = createSelector(
  [resourcesWithObjectSelector],
  (resources) => resources.filter(resource => !resource.assignedObject)
)
export const assignedResourcesSelector = createSelector(
  [resourcesWithObjectSelector],
  (resources) => resources.filter(resource => resource.assignedObject)
)

export const offlineWirepasDevicesSelector = createSelector(
  [resourcesWithObjectSelector, resourcesLastSeenSelector],
  (resources, lastSeen) => resources.filter(r => r.protocol === 'wirepas' && lastSeen[r.id] && isResourceLastSeenOffline(lastSeen[r.id]))
)
