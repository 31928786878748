import React from 'react'

import s from './SplashScreen.module.scss'

const SplashScreen = () => (
  <div className={s.container}>
    <div className={s.indicator} />
    <div className={s.text}>Connecting to Mount Kelvin Cloud...</div>
  </div>
)

export default SplashScreen
