import immer from 'immer'

import { SOCKET_DISCONNECT, SITE_SUBSCRIBE, SITE_SUBSCRIBE_FAILURE } from '../constants/actions'

const initialState = {}

const subscriptions = (state = initialState, { type, payload }) => {
  switch (type) {
    case SOCKET_DISCONNECT:
      return immer(state, draftState => {
        for (const siteId of Object.keys(state)) {
          draftState[siteId] = false
        }
      })
    case SITE_SUBSCRIBE:
      return immer(state, draftState => {
        const { siteId } = payload
        draftState[siteId] = true
      })
    case SITE_SUBSCRIBE_FAILURE:
      return immer(state, draftState => {
        const { siteId } = payload
        draftState[siteId] = false
      })
    default:
      return state
  }
}

export default subscriptions
