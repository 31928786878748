import immer from 'immer'

import {
  INPUT_CAPTURE_START,
  INPUT_CAPTURE_STATUS,
  INPUT_CAPTURE_SUCCESS,
  INPUT_CAPTURE_FAILURE,
  INPUT_CAPTURE_CANCEL,
} from '../constants/actions'

const initialState = {
  progress: 0,
  error: null,
  resource: null,
  existingResourceId: null,
  existingObjectId: null,
}

const inputCapture = (state = initialState, { type, payload }) => {
  switch (type) {
    case INPUT_CAPTURE_START:
      return immer(state, draftState => {
        draftState.progress = 0
        draftState.error = null
        draftState.resource = null
      })
    case INPUT_CAPTURE_STATUS:
      return immer(state, draftState => {
        draftState.progress = payload.progress
      })
    case INPUT_CAPTURE_SUCCESS:
      return immer(state, draftState => {
        draftState.resource = payload.resource
      })
    case INPUT_CAPTURE_FAILURE:
      return immer(state, draftState => {
        draftState.error = payload.error
        draftState.existingResourceId = payload.existingResourceId
        draftState.existingObjectId = payload.existingObjectId
      })
    case INPUT_CAPTURE_CANCEL:
      return initialState
    default:
      return state
  }
}

export default inputCapture
