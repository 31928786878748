import immer from 'immer'

import { SITE_STATE } from '../constants/actions'

const initialState = {}
const siteStates = (state = initialState, { type, payload }) => {
  switch (type) {
    case SITE_STATE:
      return immer(state, draftState => {
        const { siteId, siteStateList } = payload
        draftState[siteId] = draftState[siteId] || {}
        for (const { id, state } of siteStateList) {
          draftState[siteId][id] = draftState[siteId][id] || {}
          Object.assign(draftState[siteId][id], state)
        }
      })
    default:
      return state
  }
}

export default siteStates
