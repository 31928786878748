import immer from 'immer'

import { AUTH_UPDATE, AUTH_REMOVE } from '../constants/actions'

const initialState = {
  username: null,
  accessToken: null,
  refreshToken: null,
  expireDate: null,
}

const auth = (state = initialState, { type, payload }) => {
  switch (type) {
    case AUTH_UPDATE:
      return immer(state, draftState => {
        const { username, accessToken, refreshToken } = payload
        draftState.username = username
        draftState.accessToken = accessToken
        draftState.refreshToken = refreshToken
      })
    case AUTH_REMOVE:
      return immer(state, draftState => {
        draftState.username = null
        draftState.accessToken = null
        draftState.refreshToken = null
      })
    default:
      return state
  }
}

export default auth
