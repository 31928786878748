import React from 'react'
import { createSelector, createStructuredSelector } from 'reselect'
import { connect } from 'react-redux'

import { objectsSelector, isObjectJustCreatedSelector } from '../../selectors'
import Heading from '../../components/Heading'
import TextField from '../../components/TextField'
import Checkbox from '../../components/Checkbox'
import { ReactComponent as BleScannerFramedIcon } from '../../assets/ble-scanner-framed.svg'

import s from './LightConfiguration.module.scss'

const NoiseSensorConfiguration = ({
  object,
  onRename,
  onBleScannerToggle,
  isJustCreated,
  disabled,
}) => {

  return (
    <>
      <Heading label="Location" />
      <TextField
        initialValue={object.name}
        onSave={onRename}
        disabled={disabled}
        activateOnMount={isJustCreated}
        allowEmpty
      />
      <Heading label={<><BleScannerFramedIcon className={s.bleScannerIcon} />BLE scanners</>} />
      <Checkbox
        className={s.bleScannerCheckbox}
        checked={object.bleScanner}
        onChange={onBleScannerToggle}
        label="The first Wirepas device assigned should act as a BLE scanner"
      />
    </>
  )

}

const objectIdSelector = (state, props) => props.objectId
const objectSelector = createSelector(
  [objectIdSelector, objectsSelector],
  (objectId, objects) => objects.find(o => o.id === objectId)
)

const roomIdSelector = createSelector(
  [objectSelector],
  (object) => object.roomId
)

const mapStateToProps = createStructuredSelector({
  isJustCreated: isObjectJustCreatedSelector,
  object: objectSelector,
  roomId: roomIdSelector,
})

export default connect(mapStateToProps, null,  null, { forwardRef: true })(NoiseSensorConfiguration)
