import React from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { withRouter } from "react-router-dom"

import { resourcesSelector, offlineWirepasDevicesSelector } from '../../selectors'
import GroupHeading from '../../components/GroupHeading'
import ListItemPlaceholder from '../../components/ListItemPlaceholder'
import CatchableRoomObject from './CatchableRoomObject'

const RoomSectionContainer = ({
  inputCaptureProgress, siteId, room, objects, selectedObjectId, onObjectSelected
}) => (
    <>
      <GroupHeading label={room.name} />
      {objects.length === 0 && <ListItemPlaceholder label="No BLE devices" />}
      {objects.map(obj => (
        <CatchableRoomObject
          key={obj.id}
          siteId={siteId}
          object={obj}
          inputCaptureProgress={inputCaptureProgress}
          onClick={() => onObjectSelected(obj)}
          selected={selectedObjectId === obj.id}
        />
      ))}
    </>
  )

const mapStateToProps = createStructuredSelector({
  offlineDevices: offlineWirepasDevicesSelector,
  resources: resourcesSelector
})

export default connect(mapStateToProps)(withRouter(RoomSectionContainer))
