import * as R from 'ramda'
import { buffers } from 'redux-saga'
import { call, take, race, put, actionChannel, select } from 'redux-saga/effects'

import { INPUT, INPUT_CAPTURE_START, INPUT_CAPTURE_STOP, INPUT_CAPTURE_CANCEL } from '../constants/actions'
import { inputCaptureStatus, inputCaptureSuccess, inputCaptureFailure } from '../actions'
import { makeSiteConfigurationSelector } from '../selectors/site'

function * selectExistingResources(siteId, type, protocol) {
  const siteConfiguration = yield select(makeSiteConfigurationSelector(R.always(siteId)))

  const matchingResources = R.filter(R.whereEq({ type, protocol }), siteConfiguration.resources)
  const attachedMatchingResources = R.filter(R.prop('attachedObjectId'), matchingResources)

  return attachedMatchingResources
}

function * inputCaptureWorker(action) {
  const { siteId, type, protocol = 'ble' } = action.payload

  const inputChannel = yield actionChannel(
    a => a.type === INPUT && a.payload.siteId === siteId
      && a.payload.resource.protocol === protocol && a.payload.resource.type === type,
    buffers.expanding(32)
  )

  try {
    const collector = {}

    for (;;) {
      const { input } = yield race({
        input: take(inputChannel),
        abort: take(INPUT_CAPTURE_STOP),
      })

      if (input == null) {
        break
      }

      const { resource, data } = input.payload
      const { address } = resource

      collector[address] = collector[address] || { resource, clicks: 0, inputs: [] }
      collector[address].clicks++
      collector[address].inputs.push(data)

      const progress = R.reduce(R.max, 0, R.map(R.prop('clicks'), R.values(collector)))

      yield put(inputCaptureStatus({ progress }))
    }

    const capturedResources = R.pluck('resource', R.filter(R.propSatisfies(R.lte(3), 'clicks'), R.values(collector)))
    const attachedMatchingResources = yield call(selectExistingResources, siteId, type, protocol)
    const attachedCapturedResources = R.innerJoin(R.eqProps('address'), attachedMatchingResources, capturedResources)

    if (capturedResources.length === 1) {
      if (attachedCapturedResources.length === 0) {
        yield put(inputCaptureSuccess(capturedResources[0]))
      } else {
        const existingResourceId = attachedCapturedResources[0].id
        const existingObjectId = attachedCapturedResources[0].attachedObjectId
        yield put(inputCaptureFailure('attached-candidate', { existingResourceId, existingObjectId }))
      }
    } else if (capturedResources.length === 0) {
      yield put(inputCaptureFailure('no-matching-candidates'))
    } else {
      yield put(inputCaptureFailure('multiple-matching-candidates'))
    }
  } finally {
    inputChannel.close()
  }
}

function * inputCaptureProcess() {
  for (;;) {
    const action = yield take(INPUT_CAPTURE_START)
    yield race({
      worker: call(inputCaptureWorker, action),
      cancel: take(INPUT_CAPTURE_CANCEL),
    })
  }
}

export default function * inputCapture() {
  for (;;) {
    try {
      yield call(inputCaptureProcess)
      console.error('Enocean capture process terminated')
    } catch (e) {
      console.error('Enocean capture process crashed', e)
    }
  }
}
