import React, { Fragment, useEffect } from 'react'
import { Switch as SwitchRoute, Route, useRouteMatch, useHistory } from 'react-router-dom'
import { connect } from 'react-redux'
import { createStructuredSelector, createSelector } from 'reselect'
import { Helmet } from 'react-helmet'

import s from './SiteScreen.module.scss'
import { makeSiteConfigurationSelector, areSitesLoadedSelector, siteSelector } from '../selectors'
import SiteSubscribeContainer from '../containers/SiteSubscribeContainer'
import SiteOverviewScreen from '../containers/SiteOverviewScreen'
import ResourceOverviewScreen from './ResourceOverviewScreen'
import KeyOverviewScreen from './KeyOverviewScreen'
import SiteTabContainer from './SiteTabContainer'
import GatewaySectionScreen from './GatewayOverviewScreen'
import { resourceOverviewPaths, gatewayOverviewPaths, siteOverviewPaths } from '../utils/paths'
import { useEventListener } from '../hooks'

const SiteScreen = ({ siteId, siteConfiguration, isInvalidSite }) => {
  const isResourceOverviewScreen = useRouteMatch(resourceOverviewPaths)
  const isSiteOverviewScreen = useRouteMatch(siteOverviewPaths)
  const history = useHistory()

  useEventListener("keydown", (e) => {
    if (e.key === 'Tab') {
      if(isResourceOverviewScreen && isResourceOverviewScreen.isExact) {
        e.preventDefault()
        e.stopPropagation()
        history.push(`/sites/${siteId}`)
      } else if(isSiteOverviewScreen  && isSiteOverviewScreen.isExact) {
        e.preventDefault()
        e.stopPropagation()
        history.push(`/sites/${siteId}/resources`)
      }
    }
  })

  useEffect(() => {
    if(isInvalidSite) {
      history.replace("/")
    }
  }, [history, isInvalidSite])

  return (
    <Fragment>
      <SiteSubscribeContainer siteId={siteId} />
      {siteConfiguration &&
        <div className={s.container}>
          <Helmet>
            <title>{siteConfiguration.name} | Mount Kelvin HC</title>
          </Helmet>
          <div className={s.header}>
            <SiteTabContainer siteId={siteId} />
          </div>
          <div className={s.content}>
            <SwitchRoute>
              <Route path="/sites/:siteId/keys" component={KeyOverviewScreen} />
              <Route path={resourceOverviewPaths} component={ResourceOverviewScreen} />
              <Route path={gatewayOverviewPaths} component={GatewaySectionScreen} />
              <Route path={siteOverviewPaths} component={SiteOverviewScreen} />
            </SwitchRoute>
          </div>
        </div>
      }
    </Fragment>
  )
}

const siteIdSelector = (state, props) => props.match.params.siteId

const siteConfigurationSelector = makeSiteConfigurationSelector(siteIdSelector)

const isInvalidSiteSelector = createSelector(
  [areSitesLoadedSelector, siteSelector],
  (areSitesLoaded, site) => areSitesLoaded && !site
)

const mapStateToProps = createStructuredSelector({
  isInvalidSite: isInvalidSiteSelector,
  siteId: siteIdSelector,
  siteConfiguration: siteConfigurationSelector,
})

export default connect(mapStateToProps)(SiteScreen)
