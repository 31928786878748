import { OBJECT_APPLY, OBJECT_APPLY_SUCCESS, OBJECT_APPLY_FAILURE } from '../constants/actions'

export const objectApply = (siteId, { spaceId, roomId, objectId }, action, state) => ({
  type: OBJECT_APPLY,
  payload: {
    siteId,
    targetSpaceId: spaceId,
    targetRoomId: roomId,
    targetObjectId: objectId,
    action,
    state,
  },
})

export const objectApplySuccess = (siteId) => ({
  type: OBJECT_APPLY_SUCCESS,
  payload: { siteId },
})

export const objectApplyFailure = (siteId, error) => ({
  type: OBJECT_APPLY_FAILURE,
  payload: { siteId, error },
})
