import React from 'react'
import classnames from 'classnames'

import { useScrollIntoView } from '../../hooks'
import s from './ResourceItem.module.scss'
import BluetoothIcon from '../../components/BluetoothIcon'
import LigIcon from '../../assets/lig.png'
import MotIcon from '../../assets/mot.png'
import CurIcon from '../../assets/cur.png'


const getTypeIcon = type => {
  switch (type) {
    case 'actuator':
      return LigIcon
    case 'motionSensor':
      return MotIcon
    case 'curtain':
      return CurIcon
    default:
      return '?'
  }
}

const getAssignmentLabel = (res, obj) => {
  if (obj) {
    const { name: objectName, roomName, spaceName, floorName } = obj
    return `${floorName} . ${spaceName} . ${roomName} . ${objectName}`
  } else {
    return (
      res.model || 'Device'
    )
  }
}

const rounded = x => isValidRssi(x) ? Math.round(x) : x
const isValidRssi = x => typeof x === 'number'

const ResourceItem = ({
  resource,
  active,
  highlight = !resource.assignedObject,
  onClick,
  rssi,
  isNew,
  autoBlinkEnabled,
  toggleAutoBlink,
}) => {
  const ref = useScrollIntoView(active)
  const { type, bleReceiverEnabled, assignedObject } = resource

  return (
    <div
      ref={ref}
      className={classnames({
        [s.resourceItem]: true,
        [s.resourceItemActive]: active,
        [s.resourceItemUnassigned]: highlight
      })}
      onClick={onClick}
    >
      <span className={classnames(s.action, s.blinkingText, { [s.visible]: active })} onClick={toggleAutoBlink}>
        {autoBlinkEnabled ? "Blinking" : "Not blinking"}<br />(B)
      </span>
      {isNew ? <div className={s.new}>new</div> : null}
      <img src={getTypeIcon(type)} className={s.typeIcon} />
      <span className={s.location}>
        {getAssignmentLabel(resource, assignedObject)}
      </span>
      <div className={s.right}>
        {rssi !== undefined && <div className={s.rssi}>{isValidRssi(rssi) ? <>{rounded(rssi)} <span className={s.dbm}>dBm</span></> : rssi}</div>}
        {resource.assignedObject && <BluetoothIcon className={s.bluetoothIcon} active={bleReceiverEnabled} />}
      </div>
    </div>
  )
}

export default ResourceItem
