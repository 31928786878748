import React, { Component } from 'react'
import { connect } from 'react-redux'
import cn from 'classnames'

import s from './SiteCatchObjectModal.module.scss'
import { siteObjectAssignRawResource, siteObjectUnassignResource } from '../actions/site'
import Modal from '../components/Modal'
import SiteResourceCaptureContainer from '../containers/SiteResourceCaptureContainer'
import ObjectPathNameContainer from '../containers/ObjectPathNameContainer'

class SiteCatchObjectModal extends Component {
  constructor(...args) {
    super(...args)
    this.state = {
      progress: 0,
      error: null,
      existingResourceId: null,
      existingObjectId: null,
    }
    this.requestCloseTimeout = null
  }

  componentWillUnmount() {
    clearTimeout(this.requestCloseTimeout)
  }

  handleError = (error, { existingResourceId, existingObjectId }) => {
    this.setState({
      error,
      existingResourceId,
      existingObjectId,
    })
    if (error !== 'attached-candidate') {
      this.requestCloseTimeout = setTimeout(this.props.onComplete, 3000)
    }
  }

  handleProgress = (progress) => {
    this.setState({ progress })
  }

  handleResourceCapture = (resource) => {
    const { dispatch, siteId, object, onComplete } = this.props
    dispatch(siteObjectAssignRawResource(siteId, object.id, resource))
    onComplete(resource)
  }

  handleUnassignExisting = () => {
    const { dispatch, siteId, onComplete } = this.props
    const { existingObjectId, existingResourceId } = this.state
    dispatch(siteObjectUnassignResource(siteId, existingObjectId, existingResourceId))
    onComplete()
  }

  render() {
    const { siteId, object, onComplete } = this.props
    const { progress, error, existingObjectId } = this.state

    const timeout = object.type === 'doorSensor' ? 10000 : 5000

    return (
      <Modal onRequestClose={onComplete}>
        <SiteResourceCaptureContainer
          siteId={siteId}
          timeout={timeout}
          type={object.type}
          onResourceCapture={this.handleResourceCapture}
          onError={this.handleError}
          onProgress={this.handleProgress}
        />
        {error === 'attached-candidate' &&
          <div className={s.modal}>
            <div className={s.warningText}>
              ⚠️
              {' '}
              {object.type === 'switch'
                ? 'This switch is already assigned to'
                : object.type === 'doorSensor'
                ? 'This door sensor is already assigned to'
                : object.type === 'environmentalSensor'
                ? 'This environmental sensor is already assigned to'
                : 'Unknown object type'
              }
              {' '}
              <span className={s.warningTextObjectName}>
                <ObjectPathNameContainer siteId={siteId} objectId={existingObjectId} />
              </span>
            </div>
            <div className={s.modalLinkBlock}>
              <div className={s.modalLink} onClick={this.handleUnassignExisting}>Unassign</div>
              <div className={s.modalLinkSeparator}>{' · '}</div>
              <div className={s.modalLink} onClick={onComplete}>Dismiss</div>
            </div>
          </div>
        }
        {error === 'no-matching-candidates' &&
          <div className={s.errorModal}>
            {object.type === 'switch'
              ? 'The switch was not pressed three times within five seconds. Please retry.'
              : object.type === 'doorSensor'
              ? 'The door was not opened three times within 10 seconds. Please retry.'
              : object.type === 'environmentalSensor'
              ? 'The sensor was not triggered three times within 10 seconds. Please retry.'
            : 'No devices were triggered. Please retry.'}
          </div>
        }
        {error === 'multiple-matching-candidates' &&
          <div className={s.errorModal}>
            {object.type === 'switch'
              ? 'Multiple switches were pressed at the same time. Please retry.'
              : object.type === 'doorSensor'
              ? 'Multiple doors were opened at the same time. Please retry.'
              : object.type === 'environmentalSensor'
              ? 'Multiple sensors were triggered at the same time. Please retry'
            : 'Multiple devices were triggered at the same time. Please retry.'}
          </div>
        }
        {error == null &&
          <div className={s.modal}>
            <div className={s.modalText}>
              {object.type === 'switch'
                ? 'Within five seconds, press the switch three times'
                : object.type === 'doorSensor'
                ? 'Within 10 seconds, open/close the door three times'
                : object.type === 'environmentalSensor'
                ? 'Within 10 seconds, press the antitamper button on the sensor three times'
              : 'Trigger the device three times'}
            </div>
            <div className={s.modalProgress}>
              <div className={cn(s.modalProgressDot, progress >= 1 && s.modalProgressDotFill)} />
              <div className={cn(s.modalProgressDot, progress >= 2 && s.modalProgressDotFill)} />
              <div className={cn(s.modalProgressDot, progress >= 3 && s.modalProgressDotFill)} />
            </div>
            <div className={s.modalTimer} style={{ animationDuration: `${timeout/1000}s` }}/>
          </div>
        }
      </Modal>
    )
  }
}

export default connect()(SiteCatchObjectModal)
