import React, { Component } from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import s from './KeyUpdateModal.module.scss'
import { apiKeyUpdatePart } from '../actions'
import { makeKeySelector } from '../selectors/key'
import Modal from '../components/Modal'
import Input from '../components/Input'

class KeyUpdateModal extends Component {
  constructor(...args) {
    super(...args)
    this.state = {
      name: this.props.keyBody.name,
      description: this.props.keyBody.description,
    }
  }

  handleNameChange = (name) => {
    this.setState({ name })
  }

  handleDescriptionChange = (description) => {
    this.setState({ description })
  }

  handleClose = () => {
    const { siteId, keyId, keyBody, onRequestClose, dispatch } = this.props
    const { name, description } = this.state

    if (name !== keyBody.name || description !== keyBody.description) {
      dispatch(apiKeyUpdatePart(siteId, keyId, { name, description }))
    }

    onRequestClose()
  }

  handleKeyCopy = () => {
    const { keyToken } = this.props
    if (keyToken) {
      navigator.clipboard.writeText(keyToken)
    }
  }

  render() {
    const { keyToken, onRequestClose } = this.props
    const { name, description } = this.state

    return (
      <Modal onRequestClose={onRequestClose}>
        <div className={s.container}>
          <div className={s.fieldLabel}>
            <div className={s.fieldLabelText}>
              API key name
            </div>
            <div className={s.closeLink} onClick={this.handleClose}>
              Close
            </div>
          </div>
          <div className={s.fieldInput}>
            <Input value={name} onValueChange={this.handleNameChange} />
          </div>
          <div className={s.fieldLabel}>
            <div className={s.fieldLabelText}>
              API key description
            </div>
          </div>
          <div className={s.fieldInput}>
            <Input value={description} onValueChange={this.handleDescriptionChange} />
          </div>
          <div className={s.fieldLabel}>
            <div className={s.fieldLabelText}>
              Key
            </div>
          </div>
          <div className={s.fieldKey}>
            <div className={s.fieldKeyText}>
              {keyToken ?? '●●●●●●●●●●●●●●●●'}
            </div>
            {keyToken != null &&
              <div className={s.fieldKeyCopyLink} onClick={this.handleKeyCopy}>
                Copy key
              </div>
            }
          </div>
          <div className={s.warning}>
            <div className={s.warningIcon}>
              ⚠️
            </div>
            <div className={s.warningText}>
              {keyToken == null
                ? 'For security reasons, we do not show the API key after its creation.'
                : 'Make sure you copy the key now, you will not be able to see it after closing this window.'
              }
            </div>
          </div>
        </div>
      </Modal>
    )
  }
}

const siteIdSelector = (state, props) => props.siteId
const keyIdSelector = (state, props) => props.keyId

const keyBodySelector = makeKeySelector(siteIdSelector, keyIdSelector)

const mapStateToProps = createStructuredSelector({
  keyBody: keyBodySelector,
})

export default connect(mapStateToProps)(KeyUpdateModal)
