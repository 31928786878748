import { Component } from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import { inputCaptureStart, inputCaptureStop, inputCaptureCancel } from '../actions'

class SiteResourceCaptureContainer extends Component {
  constructor(...args) {
    super(...args)
    this.stopTimeout = null
  }

  componentDidMount() {
    const { dispatch, siteId, type, timeout } = this.props
    dispatch(inputCaptureStart(siteId, { type }))
    this.stopTimeout = setTimeout(this.handleStopTimeout, timeout)
  }

  componentDidUpdate(prevProps) {
    const { inputCapture, onProgress, onResourceCapture, onError } = this.props
    const { inputCapture: prevInputCapture } = prevProps

    if (inputCapture.progress !== prevInputCapture.progress) {
      onProgress(inputCapture.progress)
    }
    if (inputCapture.error && inputCapture.error !== prevInputCapture.error) {
      onError(
        inputCapture.error,
        { existingResourceId: inputCapture.existingResourceId, existingObjectId: inputCapture.existingObjectId }
      )
    }
    if (inputCapture.resource && inputCapture.resource !== prevInputCapture.resource) {
      onResourceCapture(inputCapture.resource)
    }
  }

  componentWillUnmount() {
    const { dispatch } = this.props
    dispatch(inputCaptureCancel())
    clearTimeout(this.stopTimeout)
  }

  handleStopTimeout = () => {
    const { dispatch } = this.props
    dispatch(inputCaptureStop())
  }

  render() {
    return null
  }
}

const inputCaptureSelector = state => state.inputCapture

const mapStateToProps = createStructuredSelector({
  inputCapture: inputCaptureSelector,
})

export default connect(mapStateToProps)(SiteResourceCaptureContainer)
