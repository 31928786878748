import immer from 'immer'
import * as R from 'ramda'

import {
  SITE_CONFIGURATION,
  SITE_OBJECT_CREATE,
  SITE_OBJECT_DELETE,
  SITE_OBJECT_UPDATE,
  SITE_OBJECT_UPDATE_PART,
  SITE_ROOM_CREATE,
  SITE_ROOM_DELETE,
  SITE_ROOM_UPDATE,
  SITE_FLOOR_CREATE,
  SITE_FLOOR_DELETE,
  SITE_FLOOR_UPDATE,
  SITE_SCENE_CREATE,
  SITE_SPACE_CREATE,
  SITE_SPACE_DELETE,
  SITE_SPACE_UPDATE,
  SITE_OBJECT_ASSIGN_RESOURCE,
  SITE_OBJECT_UNASSIGN_RESOURCE,
  SITE_SPACE_RESOURCE_RESET,
  SITE_OBJECT_UPDATE_MATERIALS,
} from '../constants/actions'

const initialState = {}
const sites = (state = initialState, { type, payload }) => {
  switch (type) {
    case SITE_CONFIGURATION:
      return immer(state, draftState => {
        const { siteId, siteConfiguration } = payload
        draftState[siteId] = siteConfiguration
      })
    case SITE_OBJECT_CREATE:
      return immer(state, draftState => {
        const { siteId, objectBody } = payload
        const OPTIONAL_FIELDS = { attachedResources: [] }
        draftState[siteId].objects.push({ ...OPTIONAL_FIELDS, ...objectBody })
      })
    case SITE_OBJECT_DELETE:
      return immer(state, draftState => {
        const { siteId, objectId } = payload
        const objects = draftState[siteId].objects.filter(({ id }) => id !== objectId)
        const resource = draftState[siteId].resources.find(({ attachedObjectId }) => attachedObjectId === objectId)
        if (resource) {
          delete resource.attachedObjectId
        }
        draftState[siteId].objects = objects
      })
    case SITE_OBJECT_UPDATE:
      return immer(state, draftState => {
        const { siteId, objectId, objectBody } = payload
        const objects = draftState[siteId].objects.map(o => o.id === objectId ? objectBody : o)
        draftState[siteId].objects = objects
      })
    case SITE_OBJECT_UPDATE_PART:
    case SITE_OBJECT_UPDATE_MATERIALS:
      return immer(state, draftState => {
        const { siteId, objectId, updates } = payload
        const objects = draftState[siteId].objects.map(o => o.id === objectId ? {
          ...o,
          ...updates,
        } : o)
        draftState[siteId].objects = objects
      })
    case SITE_ROOM_CREATE:
      return immer(state, draftState => {
        const { siteId, roomBody } = payload
        draftState[siteId].rooms.push(roomBody)
      })
    case SITE_ROOM_DELETE:
      return immer(state, draftState => {
        const { siteId, roomId } = payload
        const rooms = draftState[siteId].rooms.filter(r => r.id !== roomId)
        const roomToDelete = draftState[siteId].rooms.find(r => r.id === roomId)
        const [objects, objectsToDelete] = R.partition(o => o.roomId !== roomToDelete.id, draftState[siteId].objects)
        const resources = draftState[siteId].resources.map(r => 
          objectsToDelete.some(o => o.id === r.attachedObjectId) ? R.omit("attachedObjectId", r) : r
        )
        draftState[siteId].rooms = rooms
        draftState[siteId].objects = objects
        draftState[siteId].resources = resources
      })
    case SITE_ROOM_UPDATE:
      return immer(state, draftState => {
        const { siteId, roomId, roomBody } = payload
        const rooms = draftState[siteId].rooms.map(r => r.id === roomId ? roomBody : r)
        draftState[siteId].rooms = rooms
      })
    case SITE_SCENE_CREATE:
      return immer(state, draftState => {
        const { siteId, sceneBody } = payload
        draftState[siteId].scenes.push(sceneBody)
      })
    case SITE_SPACE_CREATE:
      return immer(state, draftState => {
        const { siteId, spaceBody } = payload
        draftState[siteId].spaces.push(spaceBody)
      })
    case SITE_SPACE_DELETE:
      return immer(state, draftState => {
        const { siteId, spaceId } = payload
        const spaces = draftState[siteId].spaces.filter(s => s.id !== spaceId)
        const spaceToDelete = draftState[siteId].spaces.find(s => s.id === spaceId)
        const [rooms, roomsToDelete] = R.partition(r => r.spaceId !== spaceToDelete.id, draftState[siteId].rooms)
        const [objects, objectsToDelete] = R.partition(o => !roomsToDelete.some(r => r.id === o.roomId), draftState[siteId].objects)
        const resources = draftState[siteId].resources.map(r => 
          objectsToDelete.some(o => o.id === r.attachedObjectId) ? R.omit("attachedObjectId", r) : r
        )
        draftState[siteId].spaces = spaces
        draftState[siteId].rooms = rooms
        draftState[siteId].objects = objects
        draftState[siteId].resources = resources
      })
    case SITE_SPACE_UPDATE:
      return immer(state, draftState => {
        const { siteId, spaceId, spaceBody } = payload
        const spaces = draftState[siteId].spaces.map(s => s.id === spaceId ? spaceBody : s)
        draftState[siteId].spaces = spaces
      })
    case SITE_FLOOR_CREATE:
      return immer(state, draftState => {
        const { siteId, floorBody } = payload
        draftState[siteId].floors.push(floorBody)
      })
    case SITE_FLOOR_DELETE:
      return immer(state, draftState => {
        const { siteId, floorId } = payload
        const floorToDelete = draftState[siteId].floors.find(f => f.id === floorId)
        const hasSpaces = draftState[siteId].spaces.some(s => s.floorId === floorToDelete.id)
        if (!hasSpaces) {
          draftState[siteId].floors = draftState[siteId].floors.filter(f => f.id !== floorId)
        }
      })
    case SITE_FLOOR_UPDATE:
      return immer(state, draftState => {
        const { siteId, floorId, floorBody } = payload
        const floors = draftState[siteId].floors.map(s => s.id === floorId ? floorBody : s)
        draftState[siteId].floors = floors
      })
    case SITE_OBJECT_ASSIGN_RESOURCE:
      return immer(state, draftState => {
        const { siteId, objectId, resourceId } = payload
        const resource = draftState[siteId].resources.find(({ id }) => id === resourceId)
        const object = draftState[siteId].objects.find(({ id }) => id === objectId)
        if(resource && object) {
          resource.attachedObjectId = objectId
          object.attachedResources.push(resourceId)
        }
      })
    case SITE_OBJECT_UNASSIGN_RESOURCE:
      return immer(state, draftState => {
        const { siteId, objectId, resourceId } = payload
        const resource = draftState[siteId].resources.find(({ id }) => id === resourceId)
        const object = draftState[siteId].objects.find(({ id }) => id === objectId)
        if(resource && object) {
          delete resource.attachedObjectId
          object.attachedResources =  object.attachedResources.filter(id => id !== resourceId)
        }
      })
    case SITE_SPACE_RESOURCE_RESET:
      return immer(state, draftState => {
        const { siteId } = payload
        draftState[siteId].resources.forEach(r => delete r.attachedObjectId)
        draftState[siteId].objects.forEach(o => o.attachedResources = [])
      })
    default:
      return state
  }
}

export default sites
