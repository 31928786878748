import { combineReducers } from 'redux'

import ready from './ready'
import socket from './socket'
import auth from './auth'
import login from './login'
import subscriptions from './subscriptions'
import sites from './sites'
import siteConfigurations from './siteConfigurations'
import siteStates from './siteStates'
import siteKeys from './siteKeys'
import inputCapture from './inputCapture'
import resourceOverviewScreen from './resourceOverviewScreen'
import siteViews from './siteViews'

export default combineReducers({
  ready,
  socket,
  login,
  auth,
  subscriptions,
  sites,
  siteConfigurations,
  siteStates,
  siteKeys,
  siteViews,
  inputCapture,
  resourceOverviewScreen
})
