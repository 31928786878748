import React, { Component } from 'react'
import * as R from 'ramda'
import { connect } from 'react-redux'
import { createStructuredSelector, createSelector } from 'reselect'
import cn from 'classnames'

import s from './GatewayOverviewScreen.module.scss'
import GatewaySectionContainer from './GatewaySectionContainer'
import EntityList from '../components/EntityList'
import SectionBlock from '../components/SectionBlock'
import Section from '../components/Section'
import { updateSiteViewParams } from '../actions/site'
import { matchSiteIdSelector } from '../selectors'
import { siteViewSelector } from '../selectors/siteView'
import { gatewaysSelector, gatewaySyncStatesSelector } from '../selectors/gateway'

class GatewaySectionScreen extends Component {
  componentDidMount() {
    if(!this.props.gateway && this.props.gateways.length > 0) {
      this.handleGatewayNavigate(this.props.gateways[0])
    }
  }

  handleGatewayNavigate = (gateway) => {
    const { siteId, dispatch } = this.props
    dispatch(updateSiteViewParams(siteId, { gatewayId: gateway.id }))
  }

  renderGatewayLabel = (gateway) => {
    return (
      <div className={s.item} key={gateway.id}>
        <div className={s.info}>
          Balena Fin <span className={s.id}>{gateway.id}</span>
          <span className={cn(s.name, { [s.gray]: !gateway.name })}>
            {gateway.name || "Installation location not specified"}
          </span>
        </div>
      </div>
    )
  }

  render() {
    const { gateway, gateways, gatewaySyncStates, siteId } = this.props

    return (
      <SectionBlock>
        <Section width="500px">
          <EntityList
            emptyPlaceholderText="No gateway is assigned"
            entities={gateways}
            selectedEntityId={gateway && gateway.id}
            onNavigate={this.handleGatewayNavigate}
            entityContent={this.renderGatewayLabel}
            entityIsLoading={gateway => !gatewaySyncStates[gateway.id]}
          />
        </Section>
        {gateway && <GatewaySectionContainer
          key={gateway.id}
          siteId={siteId}
          gateway={gateway}
        />}
      </SectionBlock>
    )
  }
}

const stateGatewayIdSelector = createSelector([siteViewSelector], siteView => siteView.gatewayId)

const gatewaySelector = createSelector(
  [gatewaysSelector, stateGatewayIdSelector],
  (gateways, gatewayId) => gatewayId && R.find(R.propEq('id', gatewayId), gateways)
)

const mapStateToProps = createStructuredSelector({
  siteId: matchSiteIdSelector,
  gateway: gatewaySelector,
  gateways: gatewaysSelector,
  gatewaySyncStates: gatewaySyncStatesSelector,
})

export default connect(mapStateToProps)(GatewaySectionScreen)
