import React from 'react'
import cn from 'classnames'

import s from './Checkbox.module.scss'

const Checkbox = ({
  checked,
  value,
  onChange,
  label,
  type = 'checkbox',
  className,
  variant,
  bg = "light"
}) => {
  return (
    <label className={cn(s.checkbox, className, s[`bg-${bg}`])}>
      <span
        className={cn(
          s.mark,
          checked && s.markChecked,
          variant === 'danger' && s.markDanger,
        )}
      />
      {label && <span className={s.label}>{label}</span>}
      <input
        type={type}
        value={value}
        onChange={onChange}
        checked={checked || false}
      />
    </label>
  )
}

export default Checkbox
