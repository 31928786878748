import React from 'react'
import cn from 'classnames'

import RadioButton from './RadioButton'

import s from './RadioButtonGroup.module.scss'

const RadioButtonGroup = ({
  selected,
  onChange,
  options,
  radioButtonClassName,
  radioButtonGroupClassName,
}) => {
  return (
    <div className={radioButtonGroupClassName}>
      {options.map(option => (
        <RadioButton
          key={option.value}
          className={cn(s.radioButton, radioButtonClassName)}
          label={option.label}
          checked={option.value === selected}
          value={option.value}
          onChange={onChange}
        />
      ))}
    </div>
  )
}

export default RadioButtonGroup
