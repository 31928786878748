import React from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector, createSelector } from 'reselect'
import cn from 'classnames'

import s from './RoomSectionContainer.module.scss'
import { siteConfigurationSelector, spacesSelector, isRoomJustCreatedSelector } from '../selectors'
import { siteRoomUpdate, siteRoomDelete } from '../actions/site'
import Section from '../components/Section'
import Heading from '../components/Heading'
import TextField from '../components/TextField'

const isLogicalConfigurationEditable = space => !space.linkedSpaceId
const RoomSectionContainer = ({ siteId, room, space, isJustCreated, dispatch }) => {

    const handleRename = (name) => {
      dispatch(siteRoomUpdate(siteId, room.id, { ...room, name }))
    }

    const handleDelete = () => {
      dispatch(siteRoomDelete(siteId, room.id))
    }

    return (
      <Section minWidth="296px" className={cn({ [s.disabled]: !isLogicalConfigurationEditable(space) })}>
        <div className={s.row}>
          <div className={s.delete} onClick={handleDelete}>Delete this room</div>
        </div>
          <Heading label='Name' />
          <TextField
            initialValue={room.name}
            onSave={handleRename}
            activateOnMount={isJustCreated}
          />
      </Section>
    )
}

const roomsSelector = createSelector(
  [siteConfigurationSelector],
  (siteConfiguration) => siteConfiguration.rooms
)

const roomIdSelector = (state, props) => props.roomId

const roomSelector = createSelector(
  [roomIdSelector, roomsSelector],
  (roomId, rooms) => rooms.find(o => o.id === roomId)
)

const spaceIdSelector = (state, props) => props.spaceId
const spaceSelector = createSelector(
  [spacesSelector, spaceIdSelector],
  (spaces, spaceId) => spaces.find(({ id }) => id === spaceId)
)

const mapStateToProps = createStructuredSelector({
  isJustCreated: isRoomJustCreatedSelector,
  room: roomSelector,
  space: spaceSelector
})

export default connect(mapStateToProps)(RoomSectionContainer)
