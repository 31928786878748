export const siteOverviewPaths = [
  "/sites/:siteId"
]

export const resourceOverviewPaths = [
  "/sites/:siteId/resources/:resourceId",
  "/sites/:siteId/resources"
]

export const gatewayOverviewPaths = [
  "/sites/:siteId/gateways"
]
