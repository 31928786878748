import * as R from 'ramda'
import { createSelector } from 'reselect'
import { sitesSort } from '../utils/sort'

const defaultSiteIdSelector = (state, props) => props.siteId || props.match.params.siteId
export const matchSiteIdSelector = (state, props) => props.match.params.siteId

export const indexedSitesSelector = (state) => state.sites
export const sitesSelector = createSelector(
  [
    indexedSitesSelector,
  ],
  (indexedSites) => R.values(indexedSites)
)

export const areSitesLoadedSelector = createSelector(
  [sitesSelector],
  sites => sites.length > 0
)

export const siteSelector = createSelector([sitesSelector, defaultSiteIdSelector], (sites, siteId) => sites.find(({ id }) => id === siteId))

export const sortedSitesSelector = createSelector(
  [sitesSelector],
  (sites) => sitesSort(sites)
)

export const makeSiteConfigurationSelector = (siteIdSelector) => createSelector(
  [
    (state) => state.siteConfigurations,
    siteIdSelector,
  ],
  (indexedSiteConfigurations, siteId) => indexedSiteConfigurations[siteId]
)

export const siteConfigurationSelector = makeSiteConfigurationSelector(defaultSiteIdSelector)

export const makeSiteStateSelector = (siteIdSelector) => createSelector(
  [
    (state) => state.siteStates,
    siteIdSelector,
  ],
  (indexedSiteStates, siteId) => R.propOr({}, siteId, indexedSiteStates)
)
export const siteStateSelector = makeSiteStateSelector(defaultSiteIdSelector)
