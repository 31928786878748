import React from 'react'
import cn from 'classnames'
import * as R from 'ramda'
import { useRouteMatch } from 'react-router-dom'
import { connect } from 'react-redux'
import { createSelector, createStructuredSelector } from 'reselect'

import s from './SiteTabContainer.module.scss'
import { siteConfigurationSelector, unassignedResourcesSelector, offlineWirepasDevicesSelector } from '../selectors'
import Link from '../components/Link'
import BackIcon from '../assets/back.png'
import { siteOverviewPaths, gatewayOverviewPaths, resourceOverviewPaths } from '../utils/paths'
import { isSyncingGatewaysSelector } from '../selectors/gateway'
import { downloadFile } from '../utils'
import AVAILABLE_MATERIALS from '../data/materials'

const formatObjectType = type => {
  switch(type) {
    case 'light': return 'Wirepas light'
    case 'motionSensor': return 'Wirepas motion sensor'
    case 'switch': return 'BLE switch'
    case 'doorSensor': return 'BLE door sensor'
    case 'curtain': return 'Wirepas curtain'
    case 'environmentalSensor': return 'BLE environmental sensor'
    case 'noiseSensor': return 'Wirepas noise sensor'
    default: return `Unknown device type (${type})`
  }
}

const formatObjectLocation = (siteConfiguration, object) => {
  const room = siteConfiguration.rooms.find(({ id }) => id === object.roomId)
  const space = siteConfiguration.spaces.find(({ id }) => id === room.spaceId)
  const floor = siteConfiguration.floors.find(({ id }) => id === space.floorId)

  return `${floor.name} • ${space.name} • ${room.name} • (${formatObjectType(object.type)}) ${object.name}`
}

const handleExportIssuesAsTxt = (siteConfiguration) => {
  const unassignedLogicalDevices = siteConfiguration.objects.filter(o => o.attachedResources.length === 0)
  const formatted = unassignedLogicalDevices.map(o => formatObjectLocation(siteConfiguration, o))
  const groupedBySpace = R.values(R.groupBy(o => R.take(2, o.split("•")), formatted))
  const sortedGroups = R.sortBy(x => x[0], groupedBySpace)
  const text = sortedGroups.map(spaceGroup => [...spaceGroup].sort().join('\n')).join('\n\n')

  const fileName = getExportFileName("Issues", siteConfiguration.name, "txt")
  downloadFile(fileName, "text/plain;charset=UTF-8", text)
}

const handleMaterialsExport = (siteConfiguration) => {
  const materialsAmounts = siteConfiguration.objects
    .flatMap(o => o.materials ? o.materials : [])
    .reduce((result, material) => {
        result[material.id] = (result[material.id] || 0) + material.amount
        return result
    }, {})

  const spaceCount = siteConfiguration.spaces.length
  const rawSoftwareLicenseRow = ["Mount Kelvin room controls software license", "400€", spaceCount]
  const rawMaterialRows = AVAILABLE_MATERIALS
    .filter(material => materialsAmounts[material.id])
    .map(m => [m.name, `${m.price}€`, materialsAmounts[m.id]])

  const rows = R.sortBy(([name]) => name, [rawSoftwareLicenseRow, ...rawMaterialRows])
    .filter(([,, amount]) => amount > 0)
    .map((rawRow, i, arr) => {
      const sheetRow = i + 2
      const lastSheetRow = arr.length + 2
      return [
        ...rawRow,
        `=B${sheetRow}*C${sheetRow}`, // total cost = price * amount
        i === 0 ? `=SUM(D2:D${lastSheetRow})` : ''
      ]
    })

  const headers = ["Device", "Price", "#", "Total cost", "Grand total"]

  rows.unshift(headers)

  const fileName = getExportFileName("Materials", siteConfiguration.name, "csv")
  downloadFile(fileName, "text/csv;charset=UTF-8", converToCsv(rows))
}

const getExportFileName = (prefix, siteName, extension) => {
  const datetimeString = new Date().toISOString().split(".")[0].replace(/:/g, "-") // datetime without milliseconds or tz
  return `${prefix}_${siteName.split(" ").join("_")}_${datetimeString}.${extension}`
}

const converToCsv = (rows) => {
  return rows.map(row => row.map(field => JSON.stringify(field)).join(",")).join("\r\n")
}

const Tab = ({ paths = [], onClick, link, type = "link", children }) => {
  const match = useRouteMatch(paths)

  if(type === 'action') {
    return <div className={cn(s.tab, s.action)} onClick={onClick}>{children}</div>
  } else {
    return (
      <Link className={cn(s.tab, { [s.active]: match !== null && match.isExact } )} to={link}>
        {children}
      </Link>
    )
  }
}

const SiteTabContainer = ({ siteId, siteConfiguration, unassignedWirepasCount, offlineWirepasCount, isSyncingGateways }) => {
  return (
    <div className={s.container}>
      <div className={s.fixedContainer}>
        <Link className={s.siteIndexLink} to="/sites"><img className={s.back} src={BackIcon} alt="back button" /></Link>

        <span className={s.siteName}>{siteConfiguration.name}</span>
        <div className={s.tabs}>
          <Tab paths={siteOverviewPaths} link={`/sites/${siteId}`}>Configuration</Tab>
          <Tab paths={resourceOverviewPaths} link={`/sites/${siteId}/resources`}>
            All Wirepas devices ({unassignedWirepasCount} unassigned{offlineWirepasCount > 0 ? <>,&#160;<span className={s.red}>{offlineWirepasCount} offline</span></> : ''})
          </Tab>
          <Tab paths={gatewayOverviewPaths} link={`/sites/${siteId}/gateways`}>
            Gateways <span className={cn(s.loadingIndicator, { [s.visible]: isSyncingGateways })} />
          </Tab>
          <Tab paths={['/sites/:siteId/keys']} link={`/sites/${siteId}/keys`}>
            API keys
          </Tab>
          <Tab type="action" onClick={() => handleExportIssuesAsTxt(siteConfiguration)}>
            Export all issues as txt
          </Tab>
          <Tab type="action" onClick={() => handleMaterialsExport(siteConfiguration)}>
            Export bill of materials
          </Tab>
        </div>
      </div>
    </div>
  )
}
const unassignedWirepasCountSelector = createSelector(
  [unassignedResourcesSelector],
  (resources) => resources.filter(r => r.protocol === 'wirepas').length
)

const offlineWirepasCountSelector = createSelector(
  [offlineWirepasDevicesSelector],
  (offlineDevices) => offlineDevices.length
)

const mapStateToProps = createStructuredSelector({
  siteConfiguration: siteConfigurationSelector,
  unassignedWirepasCount: unassignedWirepasCountSelector,
  offlineWirepasCount: offlineWirepasCountSelector,
  isSyncingGateways: isSyncingGatewaysSelector
})

export default connect(mapStateToProps)(SiteTabContainer)
