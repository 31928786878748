import React, { Component } from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector, createSelector } from 'reselect'

import s from './Chrome.module.scss'
import { authSelector } from '../selectors'
import { authLogout } from '../actions/auth'

class Chrome extends Component {
  handleLogoutPress = (site) => {
    this.props.dispatch(authLogout())
  }

  render() {
    const { username, children } = this.props

    return (
      <div className={s.container}>
        <div className={s.header}>
          <div className={s.logo}>
            Mount Kelvin Bom
          </div>
          {username != null &&
            <div className={s.session}>
              <div className={s.sessionUsername}>
                {username}
              </div>
              <div className={s.sessionLogout} onClick={this.handleLogoutPress}>
                Logout
              </div>
            </div>
          }
        </div>
        <div className={s.content}>
          {children}
        </div>
      </div>
    )
  }
}

const usernameSelector = createSelector(
  [authSelector],
  (auth) => auth.username
)

const mapStateToProps = createStructuredSelector({
  username: usernameSelector,
})

export default connect(mapStateToProps)(Chrome)
