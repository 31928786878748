import { createSelector } from 'reselect'

export const makeKeyListSelector = (siteIdSelector) => createSelector(
  [
    (state) => state.siteKeys,
    siteIdSelector,
  ],
  (siteKeys, siteId) => siteKeys[siteId]?.index ?? []
)
export const makeKeySelector = (siteIdSelector, keyIdSelector) => createSelector(
  [
    makeKeyListSelector(siteIdSelector),
    keyIdSelector,
  ],
  (keyList, keyId) => keyList.find(k => k.id === keyId)
)

export const makeLastKeySelector = (siteIdSelector) => createSelector(
  [
    (state) => state.siteKeys,
    siteIdSelector,
  ],
  (siteKeys, siteId) => siteKeys[siteId]?.last ?? null
)
