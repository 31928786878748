import React, { Component } from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { Helmet } from 'react-helmet'

import s from './SiteIndexScreen.module.scss'
import { linkSite } from '../utils/links'
import { sortedSitesSelector } from '../selectors'
import { siteCreate, siteUpdatePart, siteDelete } from '../actions/site'
import Heading from '../components/Heading'
import InlineInput from '../components/InlineInput'
import Link from '../components/Link'

const stateEditNameEnable = (name) => ({ editNameValue: name, editName: true })
const stateEditNameUpdateValue = (name) => ({ editNameValue: name })
const stateEditNameCancel = ({ editNameValue: null, editName: false })

class SiteItem extends Component {
  constructor(...args) {
    super(...args)
    this.state = {
      editName: false,
      editNameValue: null,
    }
  }

  handleNameDoubleClick = () => {
    const { site } = this.props
    this.setState(stateEditNameEnable(site.name))
  }

  handleInputValueChange = (name) => {
    this.setState(stateEditNameUpdateValue(name))
  }

  handleInputBlur = () => {
    this.setState(stateEditNameCancel)
  }

  handleInputEnter = () => {
    const { site, onNameChange } = this.props
    const { editNameValue } = this.state
    onNameChange(site, editNameValue)
    this.setState(stateEditNameCancel)
  }

  handleDeleteClick = () => {
    const { onDeleteClick, site } = this.props
    onDeleteClick(site)
  }

  render() {
    const { site } = this.props
    const { editName, editNameValue } = this.state

    return (
      <div className={s.site}>
        {editName
          ?
          <div className={s.siteNameCell}>
            <InlineInput
              value={editNameValue}
              onValueChange={this.handleInputValueChange}
              onBlur={this.handleInputBlur}
              onEnter={this.handleInputEnter}
            />
          </div>
          :
          <div className={s.siteNameCell} onDoubleClick={this.handleNameDoubleClick}>
            <span className={s.siteName}>{site.name}</span>
          </div>
        }
        <div className={s.siteEditConfigurationCell}>
          <Link className={s.siteEditConfigurationLink} to={linkSite(site.id)}>
            Edit configuration
          </Link>
        </div>
        <div className={s.siteDeleteCell}>
          <span className={s.siteDeleteLink} onClick={this.handleDeleteClick}>
            Delete
          </span>
        </div>
      </div>
    )
  }
}

class SiteIndexScreen extends Component {
  handleSiteCreate = () => {
    const { dispatch } = this.props
    const siteBody = { name: 'Unnamed place' }
    dispatch(siteCreate(siteBody))
  }

  handleSiteNameChange = (site, name) => {
    const { dispatch } = this.props
    const siteBodyPart = { name }
    dispatch(siteUpdatePart(site.id, siteBodyPart))
  }

  handleSiteDelete = (site) => {
    const { dispatch } = this.props
    dispatch(siteDelete(site.id))
  }

  renderSiteItem(site) {
    return (
      <SiteItem
        key={site.id}
        site={site}
        onNameChange={this.handleSiteNameChange}
        onDeleteClick={this.handleSiteDelete}
      />
    )
  }

  render() {
    return (
      <div className={s.container}>
         <Helmet>
           <title>Mount Kelvin HC</title>
        </Helmet>
        <Heading label="Hotels" actions={[{ label: 'Create new hotel', onClick: this.handleSiteCreate }]} />
        <div className={s.index}>
          {this.props.sites.map(this.renderSiteItem, this)}
        </div>
      </div>
    )
  }
}

const mapStateToProps = createStructuredSelector({
  sites: sortedSitesSelector,
})

export default connect(mapStateToProps)(SiteIndexScreen)
