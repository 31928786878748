import React from 'react'
import cn from 'classnames'

import s from './Section.module.scss'

const Section = ({ className, children, width, minWidth }) => (
  <div
    className={cn(s.section, className, { [s.grow]: !width })}
    style={{ width, minWidth }}
  >
    <div className={s.content}>
      {children}
    </div>
  </div>
)

export default Section
