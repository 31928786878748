import { createSelector } from 'reselect'
import {
  siteConfigurationSelector,
  roomsSelector,
  spacesSelector,
  floorsSelector,
} from '.'
import { lightsSort } from '../utils/sort'
import { siteSelector } from './site'

export const objectsSelector = createSelector(
  [siteConfigurationSelector],
  (siteConfiguration) => siteConfiguration.objects
)

export const objectsWithLocationSelector = createSelector(
  [objectsSelector, roomsSelector, spacesSelector, floorsSelector],
  (objects, rooms, spaces, floors) => {
    const roomsMap = Object.fromEntries(rooms.map(r => [r.id, r]))
    const spacesMap = Object.fromEntries(spaces.map(s => [s.id, s]))
    const floorsMap = Object.fromEntries(floors.map(f => [f.id, f]))
    return objects.filter(obj => obj.roomId != null).map(object => {
      const room = roomsMap[object.roomId]
      const space = spacesMap[room.spaceId]
      const floor = floorsMap[space.floorId]

      return {
        ...object,
        roomName: room.name,
        spaceName: space.name,
        spaceId: space.id,
        floorName: floor.name,
      }
    })
  }
)

export const unassignedObjectsSelector = createSelector(
  [objectsWithLocationSelector],
  (objects) => objects.filter(obj => obj.attachedResources.length === 0)
)

export const incompleteSpaceIdsSelector = createSelector(
  [unassignedObjectsSelector],
  (objects) => {
    const incomplete = new Set()
    objects.forEach(obj => incomplete.add(obj.spaceId))
    return incomplete
  }
)

export const sortedObjectSelector = createSelector(
  [objectsWithLocationSelector],
  (lights) => lightsSort(lights)
)

const defaultIdSelector = (state, props) => props.objectId
const lastCreatedObjectIdSelector = createSelector([siteSelector], site => site.lastCreatedObjectId)
export const makeIsObjectJustCreatedSelector = (idSelector) => createSelector(
  [idSelector, lastCreatedObjectIdSelector],
  (objectId, lastCreatedObjectId) => objectId === lastCreatedObjectId
)
export const isObjectJustCreatedSelector = makeIsObjectJustCreatedSelector(defaultIdSelector)
