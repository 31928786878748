export const READY = 'READY'

export const SOCKET_CONNECT = 'SOCKET_CONNECT'
export const SOCKET_DISCONNECT = 'SOCKET_DISCONNECT'

export const AUTH_LOGIN = 'AUTH_LOGIN'
export const AUTH_LOGIN_SUCCESS = 'AUTH_LOGIN_SUCCESS'
export const AUTH_LOGIN_FAILURE = 'AUTH_LOGIN_FAILURE'

export const AUTH_REFRESH = 'AUTH_REFRESH'
export const AUTH_REFRESH_SUCCESS = 'AUTH_REFRESH_SUCCESS'
export const AUTH_REFRESH_FAILURE = 'AUTH_REFRESH_FAILURE'

export const AUTH_LOGOUT = 'AUTH_LOGOUT'
export const AUTH_LOGOUT_SUCCESS = 'AUTH_LOGOUT_SUCCESS'
export const AUTH_LOGOUT_FAILURE = 'AUTH_LOGOUT_FAILURE'

export const AUTH_UPDATE = 'AUTH_UPDATE'
export const AUTH_REMOVE = 'AUTH_REMOVE'

export const DISCOVER = 'DISCOVER'
export const DISCOVER_SUCCESS = 'DISCOVER_SUCCESS'
export const DISCOVER_FAILURE = 'DISCOVER_FAILURE'
export const DISCOVER_CLEAR_NEW = 'DISCOVER_CLEAR_NEW'

export const WIREPAS_DIRECT = 'WIREPAS_DIRECT'
export const WIREPAS_DIRECT_SUCCESS = 'WIREPAS_DIRECT_SUCCESS'
export const WIREPAS_DIRECT_FAILURE = 'WIREPAS_DIRECT_FAILURE'

export const BLINK_ONCE = 'BLINK_ONCE'
export const BLINK_ONCE_SUCCESS = 'BLINK_ONCE_SUCCESS'
export const BLINK_ONCE_FAILURE = 'BLINK_ONCE_FAILURE'

export const BLINK_START = 'BLINK_START'
export const BLINK_START_SUCCESS = 'BLINK_START_SUCCESS'
export const BLINK_START_FAILURE = 'BLINK_START_FAILURE'

export const BLINK_STOP = 'BLINK_STOP'
export const BLINK_STOP_SUCCESS = 'BLINK_STOP_SUCCESS'
export const BLINK_STOP_FAILURE = 'BLINK_STOP_FAILURE'

export const SITE_CONFIGURATION = 'SITE_CONFIGURATION'
export const SITE_RAW_CONFIGURATION = 'SITE_RAW_CONFIGURATION'
export const SITE_STATE = 'SITE_STATE'

export const SITE_INDEX = 'SITE_INDEX'

export const SITE_SUBSCRIBE = 'SITE_SUBSCRIBE'
export const SITE_SUBSCRIBE_SUCCESS = 'SITE_SUBSCRIBE_SUCCESS'
export const SITE_SUBSCRIBE_FAILURE = 'SITE_SUBSCRIBE_FAILURE'

export const SITE_CREATE = 'SITE_CREATE'
export const SITE_CREATE_SUCCESS = 'SITE_CREATE_SUCCESS'
export const SITE_CREATE_FAILURE = 'SITE_CREATE_FAILURE'

export const SITE_UPDATE = 'SITE_UPDATE'
export const SITE_UPDATE_SUCCESS = 'SITE_UPDATE_SUCCESS'
export const SITE_UPDATE_FAILURE = 'SITE_UPDATE_FAILURE'

export const SITE_UPDATE_PART = 'SITE_UPDATE_PART'
export const SITE_UPDATE_PART_SUCCESS = 'SITE_UPDATE_PART_SUCCESS'
export const SITE_UPDATE_PART_FAILURE = 'SITE_UPDATE_PART_FAILURE'

export const SITE_DELETE = 'SITE_DELETE'
export const SITE_DELETE_SUCCESS = 'SITE_DELETE_SUCCESS'
export const SITE_DELETE_FAILURE = 'SITE_DELETE_FAILURE'

export const SITE_FLOOR_CREATE = 'SITE_FLOOR_CREATE'
export const SITE_FLOOR_CREATE_SUCCESS = 'SITE_FLOOR_CREATE_SUCCESS'
export const SITE_FLOOR_CREATE_FAILURE = 'SITE_FLOOR_CREATE_FAILURE'

export const SITE_FLOOR_UPDATE = 'SITE_FLOOR_UPDATE'
export const SITE_FLOOR_UPDATE_SUCCESS = 'SITE_FLOOR_UPDATE_SUCCESS'
export const SITE_FLOOR_UPDATE_FAILURE = 'SITE_FLOOR_UPDATE_FAILURE'

export const SITE_FLOOR_DELETE = 'SITE_FLOOR_DELETE'
export const SITE_FLOOR_DELETE_SUCCESS = 'SITE_FLOOR_DELETE_SUCCESS'
export const SITE_FLOOR_DELETE_FAILURE = 'SITE_FLOOR_DELETE_FAILURE'

export const SITE_SPACE_CREATE = 'SITE_SPACE_CREATE'
export const SITE_SPACE_CREATE_SUCCESS = 'SITE_SPACE_CREATE_SUCCESS'
export const SITE_SPACE_CREATE_FAILURE = 'SITE_SPACE_CREATE_FAILURE'

export const SITE_SPACE_UPDATE = 'SITE_SPACE_UPDATE'
export const SITE_SPACE_UPDATE_SUCCESS = 'SITE_SPACE_UPDATE_SUCCESS'
export const SITE_SPACE_UPDATE_FAILURE = 'SITE_SPACE_UPDATE_FAILURE'

export const SITE_SPACE_UPDATE_PART = 'SITE_SPACE_UPDATE_PART'
export const SITE_SPACE_UPDATE_PART_SUCCESS = 'SITE_SPACE_UPDATE_PART_SUCCESS'
export const SITE_SPACE_UPDATE_PART_FAILURE = 'SITE_SPACE_UPDATE_PART_FAILURE'

export const SITE_SPACE_DELETE = 'SITE_SPACE_DELETE'
export const SITE_SPACE_DELETE_SUCCESS = 'SITE_SPACE_DELETE_SUCCESS'
export const SITE_SPACE_DELETE_FAILURE = 'SITE_SPACE_DELETE_FAILURE'

export const SITE_SPACE_RESOURCE_RESET = 'SITE_SPACE_RESOURCE_RESET'
export const SITE_SPACE_RESOURCE_RESET_SUCCESS = 'SITE_SPACE_RESOURCE_RESET_SUCCESS'
export const SITE_SPACE_RESOURCE_RESET_FAILURE = 'SITE_SPACE_RESOURCE_RESET_FAILURE'

export const SITE_ROOM_CREATE = 'SITE_ROOM_CREATE'
export const SITE_ROOM_CREATE_SUCCESS = 'SITE_ROOM_CREATE_SUCCESS'
export const SITE_ROOM_CREATE_FAILURE = 'SITE_ROOM_CREATE_FAILURE'

export const SITE_ROOM_UPDATE = 'SITE_ROOM_UPDATE'
export const SITE_ROOM_UPDATE_SUCCESS = 'SITE_ROOM_UPDATE_SUCCESS'
export const SITE_ROOM_UPDATE_FAILURE = 'SITE_ROOM_UPDATE_FAILURE'

export const SITE_ROOM_DELETE = 'SITE_ROOM_DELETE'
export const SITE_ROOM_DELETE_SUCCESS = 'SITE_ROOM_DELETE_SUCCESS'
export const SITE_ROOM_DELETE_FAILURE = 'SITE_ROOM_DELETE_FAILURE'

export const SITE_SCENE_CREATE = 'SITE_SCENE_CREATE'
export const SITE_SCENE_CREATE_SUCCESS = 'SITE_SCENE_CREATE_SUCCESS'
export const SITE_SCENE_CREATE_FAILURE = 'SITE_SCENE_CREATE_FAILURE'

export const SITE_SCENE_UPDATE = 'SITE_SCENE_UPDATE'
export const SITE_SCENE_UPDATE_SUCCESS = 'SITE_SCENE_UPDATE_SUCCESS'
export const SITE_SCENE_UPDATE_FAILURE = 'SITE_SCENE_UPDATE_FAILURE'

export const SITE_SCENE_DELETE = 'SITE_SCENE_DELETE'
export const SITE_SCENE_DELETE_SUCCESS = 'SITE_SCENE_DELETE_SUCCESS'
export const SITE_SCENE_DELETE_FAILURE = 'SITE_SCENE_DELETE_FAILURE'

export const SITE_RESOURCE_FORCE_RESYNC = 'SITE_RESOURCE_FORCE_RESYNC'
export const SITE_RESOURCE_FORCE_RESYNC_SUCCESS = 'SITE_RESOURCE_FORCE_RESYNC_SUCCESS'
export const SITE_RESOURCE_FORCE_RESYNC_FAILURE = 'SITE_RESOURCE_FORCE_RESYNC_FAILURE'

export const SITE_OBJECT_CREATE = 'SITE_OBJECT_CREATE'
export const SITE_OBJECT_CREATE_SUCCESS = 'SITE_OBJECT_CREATE_SUCCESS'
export const SITE_OBJECT_CREATE_FAILURE = 'SITE_OBJECT_CREATE_FAILURE'

export const SITE_OBJECT_UPDATE = 'SITE_OBJECT_UPDATE'
export const SITE_OBJECT_UPDATE_SUCCESS = 'SITE_OBJECT_UPDATE_SUCCESS'
export const SITE_OBJECT_UPDATE_FAILURE = 'SITE_OBJECT_UPDATE_FAILURE'

export const SITE_OBJECT_UPDATE_PART = 'SITE_OBJECT_UPDATE_PART'
export const SITE_OBJECT_UPDATE_PART_SUCCESS = 'SITE_OBJECT_UPDATE_PART_SUCCESS'
export const SITE_OBJECT_UPDATE_PART_FAILURE = 'SITE_OBJECT_UPDATE_PART_FAILURE'
export const SITE_OBJECT_UPDATE_MATERIALS = 'SITE_OBJECT_UPDATE_MATERIALS'

export const SITE_OBJECT_DELETE = 'SITE_OBJECT_DELETE'
export const SITE_OBJECT_DELETE_SUCCESS = 'SITE_OBJECT_DELETE_SUCCESS'
export const SITE_OBJECT_DELETE_FAILURE = 'SITE_OBJECT_DELETE_FAILURE'

export const SITE_OBJECT_ASSIGN_RESOURCE = 'SITE_OBJECT_ASSIGN_RESOURCE'
export const SITE_OBJECT_ASSIGN_RESOURCE_SUCCESS = 'SITE_OBJECT_ASSIGN_RESOURCE_SUCCESS'
export const SITE_OBJECT_ASSIGN_RESOURCE_FAILURE = 'SITE_OBJECT_ASSIGN_RESOURCE_FAILURE'

export const SITE_OBJECT_ASSIGN_RAW_RESOURCE = 'SITE_OBJECT_ASSIGN_RAW_RESOURCE'
export const SITE_OBJECT_ASSIGN_RAW_RESOURCE_SUCCESS = 'SITE_OBJECT_ASSIGN_RAW_RESOURCE_SUCCESS'
export const SITE_OBJECT_ASSIGN_RAW_RESOURCE_FAILURE = 'SITE_OBJECT_ASSIGN_RAW_RESOURCE_FAILURE'

export const SITE_OBJECT_UNASSIGN_RESOURCE = 'SITE_OBJECT_UNASSIGN_RESOURCE'
export const SITE_OBJECT_UNASSIGN_RESOURCE_SUCCESS = 'SITE_OBJECT_UNASSIGN_RESOURCE_SUCCESS'
export const SITE_OBJECT_UNASSIGN_RESOURCE_FAILURE = 'SITE_OBJECT_UNASSIGN_RESOURCE_FAILURE'

export const SITE_RESOURCE_UPDATE_PART = 'SITE_RESOURCE_UPDATE_PART'
export const SITE_RESOURCE_UPDATE_PART_SUCCESS = 'SITE_RESOURCE_UPDATE_PART_SUCCESS'
export const SITE_RESOURCE_UPDATE_PART_FAILURE = 'SITE_RESOURCE_UPDATE_PART_FAILURE'

export const SITE_RESOURCE_DELETE = 'SITE_RESOURCE_DELETE'
export const SITE_RESOURCE_DELETE_SUCCESS = 'SITE_RESOURCE_DELETE_SUCCESS'
export const SITE_RESOURCE_DELETE_FAILURE = 'SITE_RESOURCE_DELETE_FAILURE'

export const SITE_GATEWAY_UPDATE_PART = 'SITE_GATEWAY_UPDATE_PART'
export const SITE_GATEWAY_UPDATE_PART_SUCCESS = 'SITE_GATEWAY_UPDATE_PART_SUCCESS'
export const SITE_GATEWAY_UPDATE_PART_FAILURE = 'SITE_GATEWAY_UPDATE_PART_FAILURE'

export const SITE_APPLY = 'SITE_APPLY'
export const SITE_APPLY_SUCCESS = 'SITE_APPLY_SUCCESS'
export const SITE_APPLY_FAILURE = 'SITE_APPLY_FAILURE'

export const INPUT = 'INPUT'

export const INPUT_CAPTURE_START = 'INPUT_CAPTURE_START'
export const INPUT_CAPTURE_STOP = 'INPUT_CAPTURE_STOP'
export const INPUT_CAPTURE_STATUS = 'INPUT_CAPTURE_STATUS'
export const INPUT_CAPTURE_SUCCESS = 'INPUT_CAPTURE_SUCCESS'
export const INPUT_CAPTURE_FAILURE = 'INPUT_CAPTURE_FAILURE'
export const INPUT_CAPTURE_CANCEL = 'INPUT_CAPTURE_CANCEL'

export const SET_FILTERED_RESOURCES = 'SET_FILTERED_RESOURCES'
export const SET_AUTO_BLINK_ENABLED = 'SET_AUTO_BLINK_ENABLED'
export const SET_LAST_RESOURCE_ASSIGNMENT = 'SET_LAST_RESOURCE_ASSIGNMENT'
export const SET_PROXIMITY_DATA = 'SET_PROXIMITY_DATA'
export const SET_RESOURCE_SEARCH = 'SET_RESOURCE_SEARCH'
export const SET_RESOURCE_SORT_BY = 'SET_RESOURCE_SORT_BY'
export const SET_RESOURCE_SORT_ORDER = 'SET_RESOURCE_SORT_ORDER'

export const SITE_HARD_RESET = 'SITE_HARD_RESET'
export const SITE_HARD_RESET_SUCCESS = 'SITE_HARD_RESET_SUCCESS'
export const SITE_HARD_RESET_FAILURE = 'SITE_HARD_RESET_FAILURE'

export const OBJECT_APPLY = 'OBJECT_APPLY'
export const OBJECT_APPLY_SUCCESS = 'OBJECT_APPLY_SUCCESS'
export const OBJECT_APPLY_FAILURE = 'OBJECT_APPLY_FAILURE'

export const UPDATE_SITE_VIEW_PARAMS = 'UPDATE_SITE_VIEW_PARAMS'
export const SET_SITE_VIEW_PARAMS_VALIDATED = 'SET_SITE_VIEW_PARAMS_VALIDATED'

export const API_KEY_LIST = 'API_KEY_LIST'
export const API_KEY_LIST_SUCCESS = 'API_KEY_LIST_SUCCESS'
export const API_KEY_LIST_FAILURE = 'API_KEY_LIST_FAILURE'

export const API_KEY_CREATE = 'API_KEY_CREATE'
export const API_KEY_CREATE_SUCCESS = 'API_KEY_CREATE_SUCCESS'
export const API_KEY_CREATE_FAILURE = 'API_KEY_CREATE_FAILURE'

export const API_KEY_UPDATE_PART = 'API_KEY_UPDATE_PART'
export const API_KEY_UPDATE_PART_SUCCESS = 'API_KEY_UPDATE_PART_SUCCESS'
export const API_KEY_UPDATE_PART_FAILURE = 'API_KEY_UPDATE_PART_FAILURE'

export const API_KEY_DELETE = 'API_KEY_DELETE'
export const API_KEY_DELETE_SUCCESS = 'API_KEY_DELETE_SUCCESS'
export const API_KEY_DELETE_FAILURE = 'API_KEY_DELETE_FAILURE'

export const API_KEY_LAST_CLEAR = 'API_KEY_LAST_CLEAR'
