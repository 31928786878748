import { createSelector } from 'reselect'
import { siteConfigurationSelector } from '.'
import { floorsSort } from '../utils/sort'

export const floorsSelector = createSelector(
  [siteConfigurationSelector],
  (siteConfiguration) => siteConfiguration.floors
)

export const sortedFloorsSelector = createSelector(
  [floorsSelector],
  (floors) => floorsSort(floors)
)
