import * as R from 'ramda'
import { connect } from 'react-redux'
import { createSelector, createStructuredSelector } from 'reselect'

import { siteConfigurationSelector } from '../selectors'

const ObjectPathNameContainer = ({ objectId, floor, space, room, object }) => (
  object == null ? objectId : `${floor.name} · ${space.name} · ${room.name} · ${object.name}`
)

const objectIdSelector = (state, props) => props.objectId
const objectSelector = createSelector(
  [siteConfigurationSelector, objectIdSelector],
  (siteConfiguration, objectId) => R.find(R.propEq('id', objectId), siteConfiguration.objects)
)

const objectRoomSelector = createSelector(
  [siteConfigurationSelector, objectSelector],
  (siteConfiguration, object) => object ? R.find(R.propEq('id', object.roomId), siteConfiguration.rooms) : null
)
const objectSpaceSelector = createSelector(
  [siteConfigurationSelector, objectRoomSelector],
  (siteConfiguration, room) => room ? R.find(R.propEq('id', room.spaceId), siteConfiguration.spaces) : null
)
const objectFloorSelector = createSelector(
  [siteConfigurationSelector, objectSpaceSelector],
  (siteConfiguration, space) => space ? R.find(R.propEq('id', space.floorId), siteConfiguration.floors) : null
)

const mapStateToProps = createStructuredSelector({
  floor: objectFloorSelector,
  space: objectSpaceSelector,
  room: objectRoomSelector,
  object: objectSelector,
})

export default connect(mapStateToProps)(ObjectPathNameContainer)
