import {
  SET_FILTERED_RESOURCES,
  SET_AUTO_BLINK_ENABLED,
  SET_LAST_RESOURCE_ASSIGNMENT,
  SET_PROXIMITY_DATA,
  SET_RESOURCE_SEARCH,
  SET_RESOURCE_SORT_BY,
  SET_RESOURCE_SORT_ORDER
} from "../constants/actions"

export const setFilteredResources = (filteredOutResources) => ({
  type: SET_FILTERED_RESOURCES,
  payload: { filteredOutResources },
})

export const setAutoBlinkEnabled = (autoBlinkEnabled) => ({
  type: SET_AUTO_BLINK_ENABLED,
  payload: { autoBlinkEnabled },
})

export const setLastAssignment = (object) => ({
  type: SET_LAST_RESOURCE_ASSIGNMENT,
  payload: { object },
})

export const setProximityData = (proximities) => ({
  type: SET_PROXIMITY_DATA,
  payload: { proximities },
})

export const setResourceSearch = (search) => ({
  type: SET_RESOURCE_SEARCH,
  payload: { search },
})

export const setResourceSortBy = (sortBy) => ({
  type: SET_RESOURCE_SORT_BY,
  payload: { sortBy }
})

export const setResourceSortOrder = (sortOrder) => ({
  type: SET_RESOURCE_SORT_ORDER,
  payload: { sortOrder }
})
