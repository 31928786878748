import React from 'react'
import classnames from 'classnames'

import s from './RadioButton.module.scss'

const RadioButton = ({
  checked,
  value,
  onChange,
  label,
  type = 'radio',
  className,
}) => {
  return (
    <label className={classnames(s.radioButton, className)}>
      <span className={classnames(s.checkmark, s[type], { [s.checkmarkChecked]: checked })} />
      <span>{label}</span>
      <input
        type={type}
        value={value}
        onChange={onChange}
        checked={checked}
      />
    </label>
  )
}

export default RadioButton
