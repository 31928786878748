import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import { authPresentSelector } from '../selectors'
import LoginScreen from '../containers/LoginScreen'

class RootScreen extends Component {
  render() {
    const { authenticated } = this.props

    if (authenticated) {
      return (
        <Redirect to="/sites" />
      )
    }

    return (
      <LoginScreen />
    )
  }
}

const mapStateToProps = createStructuredSelector({
  authenticated: authPresentSelector,
})

export default connect(mapStateToProps)(RootScreen)
