import { call, takeLatest, join, put, select } from 'redux-saga/effects'

import { authSelector } from '../selectors'
import { SERVER_URL } from '../constants/settings'
import { AUTH_LOGIN, AUTH_LOGOUT } from '../constants/actions'
import { authLoginSuccess, authLoginFailure, authLogoutSuccess, authUpdate, authRemove } from '../actions'

const HC_CLIENT_ID = '6a254bf9-0df8-406e-b24f-5d36154644ea'

function * refreshWorker() {
  const { username, refreshToken } = yield select(authSelector)

  if (refreshToken == null) {
    return
  }

  const url = `${SERVER_URL}/v4/oauth2/token`
  const params = new URLSearchParams({ client_id: HC_CLIENT_ID, grant_type: 'refresh_token', refresh_token: refreshToken })

  const response = yield call(fetch, url, {
    method: 'POST',
    body: params,
    headers: {
      'accept': 'application/json',
    },
  })
  const json = yield response.json()

  if (response.status === 200) {
    yield put(authUpdate({ username, accessToken: json.access_token, refreshToken: json.refresh_token }))
  } else {
    yield put(authRemove())
  }
}

function * loginWorker(action) {
  const { username, password } = action.payload

  const url = `${SERVER_URL}/v4/oauth2/token`
  const params = new URLSearchParams({ client_id: HC_CLIENT_ID, grant_type: 'password', username, password })

  const response = yield call(fetch, url, {
    method: 'POST',
    body: params,
    headers: {
      'accept': 'application/json',
    },
  })
  const json = yield response.json()

  if (response.status === 200) {
    yield put(authLoginSuccess())
    yield put(authUpdate({ username, accessToken: json.access_token, refreshToken: json.refresh_token }))
  } else {
    yield put(authLoginFailure({ error: json.error }))
  }
}

function * logoutWorker() {
  yield put(authLogoutSuccess())
  yield put(authRemove())
}

function * authProcess() {
  yield call(refreshWorker)
  yield join([
    yield takeLatest(AUTH_LOGIN, loginWorker),
    yield takeLatest(AUTH_LOGOUT, logoutWorker),
  ])
}

export default function * auth() {
  for (;;) {
    try {
      yield call(authProcess)
      console.error('Auth process terminated')
    } catch (e) {
      console.error('Auth process crashed', e)
    }
  }
}
