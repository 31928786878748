import React from 'react'
import classnames from 'classnames'

import s from './Heading.module.scss'

const renderAction = ({ label, onClick, className }, index) => (
  <div
    key={index}
    className={classnames(s.action, className)}
    onClick={e => {
      e.stopPropagation()
      e.preventDefault()

      onClick && onClick()
    }}
  >
    {label}
  </div>
)

const Heading = React.forwardRef(({ label, info, actions, className, border = false, showActionsOnHover = false, onClick, onMouseMove, onMouseLeave }, ref) => (
  <div
    className={
      classnames(
        s.container, border ? s.border : null,
        className,
        { [s.showActionsOnHover]: showActionsOnHover }
      )
    }
    onClick={onClick}
    onMouseMove={onMouseMove}
    onMouseLeave={onMouseLeave}
    ref={ref}
  >
    <span className={s.label}>
      {label}
    </span>
    {info && (<>
      <span className={s.infoicon}>i</span>
      <span className={s.info}>
        {info}
      </span>
    </>)}
    {actions != null && actions.length > 0 &&
      <div className={s.actionContainer}>
        {actions.map(renderAction)}
      </div>
    }
  </div>
))

export default Heading
