import React, { useEffect, useRef, useState, useLayoutEffect } from 'react'
import ReactDOM from 'react-dom'
import cn from 'classnames'

import s from './PopupMenu.module.scss'
import { useEventListener } from '../hooks'
import { getScrollParent } from '../utils'

const Option = ({ label, danger, disabled, onClick }) => (
  <div className={cn(s.option, { [s.danger]: danger, [s.disabled]: disabled })} onClick={onClick}>
    {label}
  </div>
)

const alignToPositionStyles = (align, element) => {
  const bounds = element.getBoundingClientRect()
  const coords = { x: bounds.left + (bounds.right - bounds.left) / 2, y: bounds.top + (bounds.bottom - bounds.top) / 2 }

  const VERTICAL_MARGIN = 14
  switch(align) {
    case 'topRight': return  { left: `calc(${coords.x}px - 14px)`, transform: 'translateX(-0%)', top: coords.y + VERTICAL_MARGIN }
    case 'topLeft': return { left: `calc(${coords.x}px + 14px)`, transform: 'translate(-100%)', top: coords.y + VERTICAL_MARGIN }
    case 'topCenter': return { left: coords.x, top: coords.y + VERTICAL_MARGIN, transform: 'translate(-50%)' }
    case 'leftTop': return  { left: `calc(${coords.x}px + 32px`, transform: 'translate(0%)', top: coords.y - 16 }
    default: throw Error(`Invalid 'align' prop specified for PopupMenu: ${align}`)
  }
}

const PopupMenu = ({
  className,
  optionGroups,
  align = "topCenter",
  children,
  width = "250px",
  rightClick = false
}) => {
  const [isOpen, setOpen] = useState(false)
  const select = (event, option) => {
    if(!option.disabled) {
      setOpen(false)
      option.onClick()
    }

    event.stopPropagation()
  }

  const containerRef = useRef()
  const popupRef = useRef()

  const [el] = useState(document.createElement('div'))
  useEffect(() =>  {
    const modalRoot  = document.getElementById('modal-root')
    if(isOpen) {
      modalRoot.appendChild(el)
      return () => modalRoot.removeChild(el)
    }
  }, [el, isOpen])

  const renderPopup = () => {
    if(!containerRef.current) return

    const hasOptions = optionGroups.some(group => group.length > 0)

    const popup = (
      <div
        className={cn(s.popup, s[align])}
        ref={popupRef}
        style={{ ...alignToPositionStyles(align, containerRef.current), width }}
        onClick={e => e.stopPropagation()}
        tabIndex={-1}
        onBlur={() => setTimeout(() => setOpen(false), 0)}
      >
        <div className={s.content}>
          {hasOptions
            ? optionGroups.map((options, i) => (
              <div className={s.group} key={i}>
                {options.map((option, i) => (
                  <Option
                    key={`${i} ${option.label}`}
                    danger={Boolean(option.danger)}
                    disabled={Boolean(option.disabled)}
                    label={option.label}
                    onClick={(e) => select(e, option)}
                  />
                ))}
              </div>
            ))
            : <div className={s.group}><span className={cn(s.option, s.empty)}>Empty</span></div>
          }
        </div>
      </div>
    )

    return ReactDOM.createPortal(popup, el)
  }

  // Close popup when parent is scrolled
  useEffect(() => {
    if(isOpen &&  containerRef.current) {
      const scrollParent = getScrollParent(containerRef.current)
      if(scrollParent) {
        const onScroll = () => setOpen(false)
        scrollParent.addEventListener('scroll', onScroll)
        return () => scrollParent.removeEventListener('scroll', onScroll)
      }
    }

  }, [isOpen, containerRef])


  // Close popup when escape is pressed
  useEventListener('keydown', (ev) => {
    if (isOpen && ev.key === 'Escape') {
      ev.preventDefault()
      ev.stopPropagation()
      setOpen(false)
    }
  })

  // Focus on the popup when it is opened
  useLayoutEffect(() => {
    if(popupRef.current) {
      const cancelToken = setTimeout(() => popupRef.current.focus(), 0)
      return () => clearTimeout(cancelToken)
    }
  }, [isOpen, popupRef])

  const onClick = e => {
    if (isOpen) {
      setOpen(false)
    } else if(!rightClick) {
      setOpen(true)
    }
  }

  const onContextMenu = e => {
    if (rightClick) {
      setOpen(!isOpen)

      e.preventDefault()
      e.stopPropagation()
    }
  }

  return (
    <div
      className={cn(s.popupMenu, className, { [s.open]: isOpen }) }
      onClick={onClick}
      onContextMenu={onContextMenu}
      ref={containerRef}
    >
      {children}
      {isOpen && renderPopup()}
    </div>
  )
}

export default PopupMenu
