import immer from 'immer'

import {
  SET_FILTERED_RESOURCES,
  SET_AUTO_BLINK_ENABLED,
  SET_LAST_RESOURCE_ASSIGNMENT,
  SET_PROXIMITY_DATA,
  DISCOVER_SUCCESS,
  DISCOVER_CLEAR_NEW,
  SITE_CONFIGURATION,
  SET_RESOURCE_SEARCH,
  SET_RESOURCE_SORT_BY,
  SET_RESOURCE_SORT_ORDER,
  DISCOVER,
  DISCOVER_FAILURE,
} from '../constants/actions'

const initialState = {
  filteredOutResources: [],
  autoBlinkEnabled: false,
  lastAssignment: null,
  proximities: {},
  search: "",
  newResources: new Set(),
  oldResources: new Set(),
  isDiscovering: false,
  sortBy: "heartbeat",
  sortOrder: "desc"
}
const resourceOverviewScreen = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_FILTERED_RESOURCES:
      return immer(state, draftState => {
        const { filteredOutResources } = payload
        draftState.filteredOutResources = [ ...filteredOutResources ]
      })
    case SET_AUTO_BLINK_ENABLED:
      return immer(state, draftState => {
        const { autoBlinkEnabled } = payload
        draftState.autoBlinkEnabled = autoBlinkEnabled
      })
    case SET_LAST_RESOURCE_ASSIGNMENT:
      return immer(state, draftState => {
        const { object } = payload
        draftState.lastAssignment = object
      })
    case SET_PROXIMITY_DATA:
      return immer(state, draftState => {
        const { proximities } = payload
        draftState.proximities = proximities
      })
      case SET_RESOURCE_SEARCH:
        return immer(state, draftState => {
          const { search } = payload
          draftState.search = search
        })
    case SET_RESOURCE_SORT_BY:
      return immer(state, draftState => {
        const { sortBy } = payload
        draftState.sortBy = sortBy
      })
    case SET_RESOURCE_SORT_ORDER:
      return immer(state, draftState => {
        const { sortOrder } = payload
        draftState.sortOrder = sortOrder
      })
    case SITE_CONFIGURATION:
      return immer(state, draftState => {
        const { siteConfiguration } = payload
        const { resources } = siteConfiguration
        draftState.oldResources = new Set(resources.map(r => r.address))
      })
    case DISCOVER:
      return immer(state, draftState => {
        draftState.isDiscovering = true
      })
    case DISCOVER_SUCCESS:
      return immer(state, draftState => {
        const { resources } = payload
        const addrs = resources.map(r => r.address)
        const newAddrs = addrs.filter(a => !state.oldResources.has(a))
        draftState.newResources = new Set(newAddrs)
        draftState.isDiscovering = false
      })
    case DISCOVER_FAILURE:
      return immer(state, draftState => {
        draftState.isDiscovering = false
      })
    case DISCOVER_CLEAR_NEW:
      return immer(state, draftState => {
        draftState.newResources = new Set()
      })
    default:
      return state
  }
}

export default resourceOverviewScreen
