import * as R from 'ramda'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { v4 as uuidv4 } from 'uuid'

import {
  sortedFloorsSelector, offlineWirepasDevicesSelector,
} from '../selectors'
import { siteFloorCreate, siteFloorUpdate, siteFloorDelete, updateSiteViewParams } from '../actions/site'
import Section from '../components/Section'
import EntityList from '../components/EntityList'

import s from './SiteConfigurationSectionContainer.module.scss'

const resolveNextFloorName = R.compose(
  R.toString,
  R.inc,
  R.reduce(R.max, 0),
  R.map(x => parseInt(x, 10)),
  R.filter(R.match(/^[0-9]+$/)),
  R.map(R.compose(R.trim, R.prop('name')))
)

class SiteConfigurationSectionContainer extends Component {
  state = { currentlyEditingEntity: null }
  handleFloorCreatePress = () => {
    const { siteId, floors, dispatch } = this.props
    const name = resolveNextFloorName(floors)
    dispatch(siteFloorCreate(siteId, { id: uuidv4(), name }))
  }

  handleFloorNameChange = (floor, floorName) => {
    const { siteId, dispatch } = this.props
    if (floorName) {
      dispatch(siteFloorUpdate(siteId, floor.id, { ...floor,  name: floorName }))
    }

    this.setState({ currentlyEditingEntity: null })
  }

  handleFloorDelete = (floor) => {
    const { siteId, dispatch } = this.props
    dispatch(siteFloorDelete(siteId, floor.id))
  }

  handleFloorCopy = (floor) => {
    const { siteId, floors, dispatch } = this.props
    const name = resolveNextFloorName(floors)
    dispatch(siteFloorCreate(siteId, { id: uuidv4(), name }, floor.id))
  }

  handleFloorNavigate = (floor) => {
    const { siteId, dispatch } = this.props
    dispatch(updateSiteViewParams(siteId, { floorId: floor.id }))
  }

  handleFloorStartRenaming = (floor) => {
    this.setState({ currentlyEditingEntity: floor })
  }

  handleFloorCancelEditing = () => {
    this.setState({ currentlyEditingEntity: null })
  }

  renderFloorLabel = (floor) => {
    const { offlineDevices } = this.props
    const offlineDevicesInFloor = offlineDevices.filter(d => d.assignedObject && d.assignedObject.floorId === floor.id).length
    return (
      <div className={s.floorLabel}>
        <span>{floor.name}</span>
        {offlineDevicesInFloor > 0 && <span className={s.offline}>{offlineDevicesInFloor}</span>}
      </div>
    )
  }

  createFloorContextMenu = (floor) => {
    return [
      [
        { label: "Copy floor", onClick: () => this.handleFloorCopy(floor) },
        { label: "Rename floor", onClick: () => this.handleFloorStartRenaming(floor) },
        { label: "Delete floor", onClick: () => this.handleFloorDelete(floor), danger: true }
      ]
    ]
  }

  render() {
    return (
      <Section width="120px">
        <EntityList
          actions={[{ label: 'Add floor', onClick: this.handleFloorCreatePress }]}
          entities={this.props.floors}
          selectedEntityId={this.props.selectedFloorId}
          onEntityNameChange={this.handleFloorNameChange}
          onEntityCancelEditing={this.handleFloorCancelEditing}
          onNavigate={this.handleFloorNavigate}
          entityContent={this.renderFloorLabel}
          entityContextMenu={this.createFloorContextMenu}
          currentlyEditingEntity={this.state.currentlyEditingEntity}
        />
      </Section>
    )
  }
}

const mapStateToProps = createStructuredSelector({
  floors: sortedFloorsSelector,
  offlineDevices: offlineWirepasDevicesSelector,
})


export default connect(mapStateToProps)(SiteConfigurationSectionContainer)
