import React, { Component, Fragment } from 'react'

import s from './EntityList.module.scss'
import ListItem from '../components/ListItem'
import ListItemInput from '../components/ListItemInput'
import ListItemPlaceholder from '../components/ListItemPlaceholder'
import PopupMenu from './PopupMenu'

const stateEditNameUpdateValue = (name) => ({ editNameValue: name })
const stateEditNameCancel = ({ editNameValue: null })

class EntityListItem extends Component {
  constructor() {
    super()
    this.state = {
      editNameValue: null,
    }
  }

  handleInputValueChange = (name) => {
    this.setState(stateEditNameUpdateValue(name))
  }

  handleInputCancel = () => {
    const { entity, onEntityCancelEditing } = this.props
    this.setState(stateEditNameCancel)
    onEntityCancelEditing &&  onEntityCancelEditing(entity)
  }

  handleInputConfirm = () => {
    const { entity, onEntityNameChange } = this.props
    const { editNameValue } = this.state
    onEntityNameChange(entity, editNameValue)
    this.setState(stateEditNameCancel)
  }

  render() {
    const { entity, isEditing, label, content, selected, incomplete, loading, onClick } = this.props
    const { editNameValue } = this.state

    if (isEditing) {
      return (
        <ListItemInput
          value={editNameValue !== null ? editNameValue : entity.name}
          onValueChange={this.handleInputValueChange}
          onConfirm={this.handleInputConfirm}
          onCancel={this.handleInputCancel}
        />
      )
    }

    return (
      <ListItem
        onClick={onClick}
        label={label}
        selected={selected}
        loading={loading}
        incomplete={incomplete}
        children={content}
      />
    )
  }
}

class EntityList extends Component {
  static defaultProps = {
    emptyPlaceholderText: 'No items to list',
  }

  renderEntityItem(entity) {
    const {
      onEntityNameChange, selectedEntityId,
      incompleteEntityIds, onNavigate, entityLabel, entityIsLoading,
      entityContent, entityContextMenu, currentlyEditingEntity, onEntityCancelEditing
    } = this.props

    const selected = selectedEntityId === entity.id
    const incomplete = incompleteEntityIds && incompleteEntityIds.has(entity.id)
    const label = entityContent ? null : (entityLabel ? entityLabel(entity) : entity.name)
    const content = entityContent ? entityContent(entity) : null
    const contextMenu = entityContextMenu ? entityContextMenu(entity) : null
    const loading = entityIsLoading ? entityIsLoading(entity) : false

    const ListItem =
      <EntityListItem
        key={entity.id}
        entity={entity}
        selected={selected}
        incomplete={incomplete}
        onClick={() => onNavigate(entity)}
        isEditing={entity === currentlyEditingEntity}
        label={label}
        loading={loading}
        content={content}
        onEntityNameChange={onEntityNameChange}
        onEntityStopEditing={onEntityCancelEditing}
      />

    if(contextMenu) {
      return (
        <PopupMenu key={entity.id} optionGroups={contextMenu} align="leftTop" width="175px" rightClick>
          {ListItem}
        </PopupMenu>
      )
    }

    return ListItem
  }

  renderAction({ label, onClick }, index) {
    return (
      <div key={index} className={s.action} onClick={onClick}>
        {label}
      </div>
    )
  }

  render() {
    const { actions, entities, emptyPlaceholderText } = this.props

    return (
      <Fragment>
        {actions && (
          <div className={s.actions}>
            {actions.map(this.renderAction, this)}
          </div>
        )}
        {entities.length === 0 &&
          <ListItemPlaceholder label={emptyPlaceholderText} />
        }
        {entities.map(this.renderEntityItem, this)}
      </Fragment>
    )
  }
}

export default EntityList
