import React, { Component } from 'react'
import classnames from 'classnames'

import s from './TextField.module.scss'

class TextField extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isActive: props.activateOnMount || false,
      value: props.initialValue || props.value || "",
      initialValue: props.initialValue || props.value || ""
    }
  }

  componentDidUpdate(prevProps) {
    if(prevProps.value !== this.props.value) {
      if(this.props.value !== undefined) {
        this.setState({ initialValue: this.props.value, value: this.props.value })
      }
    }
  }

  setActive = () => this.setState({ isActive: true })

  handleSave = () => {
    const { allowEmpty } = this.props
    const newValue = this.state.value
    if (!allowEmpty && !newValue) {
      this.setState({
        isActive: false,
        value: this.state.initialValue
      })
      return
    }

    if (this.props.onSave) this.props.onSave(newValue)

    this.setState({
      isActive: false,
      initialValue: newValue
    })
  }

  handleKeyDown = event => {
    switch (event.key) {
      case 'Enter':
        this.handleSave(event)
        return
      case 'Escape':
        this.setState({ value: this.state.initialValue, isActive: false })
        return
      default:
    }
  }

  handleOnChange = event => {
    this.setState({ value: event.target.value })
  }

  handleOnFocus = event => event.target.select()

  render() {
    const { type, suffix, disabled, className } = this.props
    const { value, isActive } = this.state

    if (!isActive) {
      return (
        <div
          className={classnames(s.label, className, { [s.disabled]: disabled })}
          tabIndex={disabled ? -1 : 0}
          onClick={this.setActive}
          onFocus={this.setActive}
        >
          {value} {suffix}
        </div>
      )
    }

    return (
      <input
        type={type || 'text'}
        value={value}
        onChange={this.handleOnChange}
        className={classnames(s.input, className, { [s.disabled]: disabled })}
        disabled={disabled}
        tabIndex={disabled ? -1 : undefined}
        onKeyDown={this.handleKeyDown}
        onBlur={this.handleSave}
        onFocus={this.handleOnFocus}
        autoFocus
      />
    )
  }
}

export default TextField
