import React, { Component } from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector, createSelector } from 'reselect'
import cn from 'classnames'
import * as R from 'ramda'

import s from './ObjectSectionContainer.module.scss'
import { siteConfigurationSelector, roomsSelector, spacesSelector, resourcesLastSeenSelector } from '../../selectors'
import { siteObjectUpdate, siteObjectDelete, siteObjectUnassignResource, siteResourceUpdatePart, siteResourceForceResync, siteObjectUpdateMaterials } from '../../actions/site'
import Section from '../../components/Section'
import Heading from '../../components/Heading'
import ListItem from '../../components/ListItem'
import ListItemPlaceholder from '../../components/ListItemPlaceholder'
import SiteCatchObjectModal from '../SiteCatchObjectModal'
import AvailableMaterialsModal from './AvailableMaterialsModal'
import LightConfiguration from './LightConfiguration'
import SwitchConfiguration from './SwitchConfiguration'
import MotionSensorConfiguration from './MotionSensorConfiguration'
import DoorSensorConfiguration from './DoorSensorConfiguration'
import EnvironmentalSensorConfiguration from './EnvironmentalSensorConfiguration'
import NoiseSensorConfiguration from './NoiseSensorConfiguration'
import CurtainConfiguration from './CurtainConfiguration'
import ResourceOverviewModal from '../ResourceOverviewModal'
import { blinkOnce, objectApply } from '../../actions'
import { canModifyResourceBleReceiver, resourceLastSeenFormatted, isResourceLastSeenOffline } from '../../utils'
import Checkbox from '../../components/Checkbox'
import AVAILABLE_MATERIALS from '../../data/materials'
import MaterialsList from './MaterialsList'
import { materialsSort } from '../../utils/sort'
import { siteStateSelector } from '../../selectors/site'

const objectAssignedDevicesLabel = object => {
  switch(object.type) {
    case 'light':
    case 'motionSensor':
    case 'curtain':
    case 'noiseSensor':
      return "Assigned wirepas devices"
    default:
      return "Assigned BLE devices"
  }
}

const isLogicalConfigurationEditable = space => !space.linkedSpaceId

class ObjectSectionContainer extends Component {
  constructor(...args) {
    super(...args)
    this.state = {
      showMaterialsModal: false,
      showCaptureModal: false,
      showAssignModal: false,
      hasChanges: false
    }

    this.editorRef = React.createRef()
  }

  renderResourceItems(resources) {
    const { object, resourcesLastSeen, state } = this.props

    return resources.map(resource => {
      const isBlinkable = resource.type === 'actuator' || resource.type === 'motionSensor' || resource.type === 'curtain'
      return (
        <ListItem key={resource.id}>
            <div className={s.resourceRow}>
              <div className={s.column}>
                <span className={s.attachedResourceName}>
                  {resource.model ? `Model: ${resource.model}` : 'Unknown make and model'} {` / ${resource.firmwareVersion}`}
                </span>
                <span className={s.attachedResourceId}>
                  {resource.id} {resource.address ? ` ${resource.address}` : ' No address'}
                </span>
                {resource.protocol === 'wirepas' && (
                  <>
                    {resourcesLastSeen[resource.id] !== undefined &&
                      <span className={s.heartbeat}>
                        Heartbeat:&nbsp;
                        <span className={cn({ [s.offline]: isResourceLastSeenOffline(resourcesLastSeen[resource.id])})}>
                          {resourceLastSeenFormatted(resourcesLastSeen[resource.id])} ago
                        </span>
                      </span>
                    }
                    <Checkbox
                      className={cn(s.bleReceiverCheckbox, { [s.disabled]: !canModifyResourceBleReceiver(object, resources, resource) })}
                      onChange={() => this.handleResourceBleReceiverToggle(resource)}
                      checked={resource.bleReceiverEnabled}
                      label="Act as a BLE scanner"
                    />
                  </>
                )}
              </div>
              { resource.type === 'environmentalSensor' && (
                <>
                  <div>
                    <span>
                      {state[object.id] && (Math.round(state[object.id].temperature * 100) / 100 + " °C • " + Math.round(state[object.id].humidity * 100) / 100 + " %") /*Using object state as resource state is not provided*/}
                    </span>
                  </div>
                </>
              )}
              { resource.type === 'noiseSensor' && (
                <>
                  <div>
                    <span>
                      {state[object.id] && (Math.round(state[object.id].average * 100) / 100 + " dB  • Min: " +
                          Math.round(state[object.id].min * 100) / 100 + " dB  • Max: " +
                          Math.round(state[object.id].max * 100) / 100 + " dB") /*Using object state as resource state is not provided*/}
                    </span>
                  </div>
                </>
              )}
              <div className={s.column}>
                {isBlinkable && <div className={s.action} onClick={() => this.handleBlinkResource(resource.id)}>Blink</div>}
                <div className={s.action} onClick={() => this.handleForceSync(resource)}>Force sync</div>
                <div className={cn(s.action, s.danger)} onClick={() => this.handleUnassignResource(resource.id)}>
                  Unassign
                </div>
            </div>
          </div>
        </ListItem>
      )
    })
  }

  handleUnassignResource = (resourceId) => {
    const { dispatch, siteId, objectId } = this.props
    dispatch(siteObjectUnassignResource(siteId, objectId, resourceId))
  }

  handleBlinkResource = (resourceId) => {
    const { dispatch, siteId } = this.props
    dispatch(blinkOnce(siteId, resourceId))
  }

  handleResourceBleReceiverToggle = (resource) => {
    const { siteId, dispatch } = this.props
    dispatch(siteResourceUpdatePart(siteId, resource.id, { bleReceiverEnabled: !resource.bleReceiverEnabled }))
  }

  handleObjectBleScannerToggle = () => {
    const { siteId, object, dispatch } = this.props
    dispatch(siteObjectUpdate(siteId, object.id, { ...object, bleScanner: !object.bleScanner}))
  }

  handleBrightnessLimitsToggle = () => {
    const { siteId, object, dispatch } = this.props
    if ( object.brightnessLimits ) {
      const {brightnessLimits, ...fileredObject} = object
      dispatch(siteObjectUpdate(siteId, object.id, { ...fileredObject}))
    } else {
      dispatch(siteObjectUpdate(siteId, object.id, { ...object, brightnessLimits: {min: 0, max: 65535 } }))
    }
  }

  handleBrightnessLimitChanged = (min, max) => {
    const { siteId, object, dispatch } = this.props
    if (min > max) return
    if ( object.brightnessLimits ) {
      dispatch(siteObjectUpdate(siteId, object.id, { ...object, brightnessLimits: {min, max} }))
    }
  }

  handleLightLevelChanged = (brightness) => {
    const { siteId, object, dispatch } = this.props
    dispatch(objectApply(siteId, { objectId: object.id }, 'lightSet', { on: (brightness > 0), ...brightness > 0 && {bri: brightness} } ))
  }

  handleObjectMaterialsUpdate = (materials) => {
    const { siteId, object, dispatch } = this.props
    const materialsBill = materials.map(m => ({ id: m.id, amount: m.amount }))
    dispatch(siteObjectUpdateMaterials(siteId, object.id, { materials: materialsBill }))
  }

  handleRename = (name) => {
    const { siteId, object, dispatch } = this.props
    dispatch(siteObjectUpdate(siteId, object.id, { ...object, name }))
  }

  handleDelete = () => {
    const { siteId, object, dispatch } = this.props
    dispatch(siteObjectDelete(siteId, object.id))
  }

  handleForceSync = (resource) => {
    const { siteId, dispatch } = this.props
    dispatch(siteResourceForceResync(siteId, resource.attachedObjectId, resource.id))
  }

  openCaptureModal = () => {
    this.setState({ showCaptureModal: true })
  }

  closeCaptureModal = () => {
    this.setState({ showCaptureModal: false })
  }

  openMaterialsModal = () => {
    this.setState({ showMaterialsModal: true })
  }

  closeMaterialsModal = () => {
    this.setState({ showMaterialsModal: false })
  }

  openAssignModal = () => {
    this.setState({ showAssignModal: true })
  }

  closeAssignModal = () => {
    this.setState({ showAssignModal: false })
  }

  handleHasChangesUpdated = hasChanges => {
    this.setState({ hasChanges })
  }

  render() {
    const { object, resources, siteId, room, space, linkedSpacesCount } = this.props
    const { showCaptureModal, showAssignModal, showMaterialsModal, hasChanges } = this.state

    const { type, attachedResources: attachedResourceIds, materials: billOfMaterials = [] } = object
    const attachedResources = attachedResourceIds
      .map(resourceId => resources.find(({ id }) => id === resourceId))
      .filter(Boolean)

    const materials = materialsSort(
      billOfMaterials
        .map(record => {
          const material = AVAILABLE_MATERIALS.find(m => m.id === record.id)
          return material && R.merge(material, record)
        })
        .filter(Boolean)
     )

    const disabled = !isLogicalConfigurationEditable(space)
    const configurationProps = {
      disabled,
      siteId,
      objectId: object.id,
      onRename: this.handleRename,
      onBleScannerToggle: this.handleObjectBleScannerToggle,
      roomName: room.name,
      onHasChangesUpdated: this.handleHasChangesUpdated,
      onBrightnessLimitsToggle: this.handleBrightnessLimitsToggle,
      onBrightnessLimitChanged: this.handleBrightnessLimitChanged,
      onLightLevelChanged: this.handleLightLevelChanged,
      state: this.props.state[object.id]
    }
    return (
      <Section minWidth="296px">
        <div className={cn(s.configuration, {[s.disabled]: disabled })}>
          <div className={s.row}>
            <div className={s.delete} onClick={this.handleDelete}>Delete this device</div>
          </div>
          {type === 'light' && <LightConfiguration {...configurationProps} />}
          {type === 'switch' && <SwitchConfiguration ref={this.editorRef} {...configurationProps} />}
          {type === 'motionSensor' && <MotionSensorConfiguration {...configurationProps} />}
          {type === 'doorSensor' && <DoorSensorConfiguration {...configurationProps} />}
          {type === 'environmentalSensor' && <EnvironmentalSensorConfiguration {...configurationProps} />}
          {type === 'noiseSensor' && <NoiseSensorConfiguration {...configurationProps} />}
          {type === 'curtain' && <CurtainConfiguration {...configurationProps} />}
        </div>

        <Heading
          label={"Bill of materials"}
          actions={[{ label: 'Add', onClick: this.openMaterialsModal }]}
        />

        <MaterialsList
          object={object}
          materials={materials}
          onUpdate={this.handleObjectMaterialsUpdate}
        />

        <Heading
          label={objectAssignedDevicesLabel(object)}
          actions={
            (type === 'switch' || type === 'doorSensor' || type === 'environmentalSensor')
              ? [{ label: 'Assign', onClick: this.openCaptureModal }]
              : [{ label: 'Assign', onClick: this.openAssignModal }]
          }
        />

        {attachedResources.length === 0 &&
          <ListItemPlaceholder label="No assignments" />
        }
        {this.renderResourceItems(attachedResources)}
        <div className={s.footer}>
          {hasChanges && this.editorRef.current && (
            <div className={s.synchronizeContainer}>
              <span>
                You are modifying the object. Once you are finished editing the configuration,
                press Sync{linkedSpacesCount > 0 ? `, and the adjustments are synced to all ${linkedSpacesCount} linked spaces.` : "."}
              </span>
              <div className={s.actions}>
                <span className={s.action} onClick={this.editorRef.current.discard}>Discard changes</span>
                <span className={s.action} onClick={this.editorRef.current.synchronize}>Sync</span>
              </div>
            </div>
          )}
        </div>

        {showMaterialsModal &&
          <AvailableMaterialsModal
            materials={AVAILABLE_MATERIALS}
            addedMaterialIds={billOfMaterials.map(m => m.id)}
            space={space}
            room={room}
            object={object}
            onAdd={materialId => {
              if (!billOfMaterials.find(record => record.id === materialId)) {
                this.handleObjectMaterialsUpdate(billOfMaterials.concat([{id: materialId, amount: 1}]))
              }
              this.closeMaterialsModal()
            }}
            onComplete={this.closeMaterialsModal}
          />
        }
        {showCaptureModal &&
          <SiteCatchObjectModal
            siteId={siteId}
            object={object}
            timeout={object.type === 'switch' ? 5000 : 10000}
            onComplete={this.closeCaptureModal}
          />
        }
        {showAssignModal &&
          <ResourceOverviewModal
            siteId={siteId}
            spaceId={room.spaceId}
            object={object}
            onRequestClose={this.closeAssignModal}
          />
        }
      </Section>
    )
  }
}

const objectsSelector = createSelector(
  [siteConfigurationSelector],
  (siteConfiguration) => siteConfiguration.objects
)

const objectIdSelector = (state, props) => props.objectId

const objectSelector = createSelector(
  [objectIdSelector, objectsSelector],
  (objectId, objects) => objects.find(o => o.id === objectId)
)

const resourcesSelector = createSelector(
  [siteConfigurationSelector],
  (siteConfiguration) => siteConfiguration.resources
)

const roomSelector = createSelector(
  [objectSelector, roomsSelector],
  (object, rooms) => rooms.find(r => r.id === object.roomId)
)

const spaceIdSelector = (state, props) => props.spaceId
const spaceSelector = createSelector(
  [spacesSelector, spaceIdSelector],
  (spaces, spaceId) => spaces.find(({ id }) => id === spaceId)
)

const linkedSpacesCountSelector = createSelector(
  [spaceSelector, spacesSelector],
  (space, spaces) => spaces.filter(s => s.linkedSpaceId === space.id).length
)

const mapStateToProps = createStructuredSelector({
  linkedSpacesCount: linkedSpacesCountSelector,
  resourcesLastSeen: resourcesLastSeenSelector,
  object: objectSelector,
  resources: resourcesSelector,
  room: roomSelector,
  space: spaceSelector,
  state: siteStateSelector,
})

export default connect(mapStateToProps)(ObjectSectionContainer)
