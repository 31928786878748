import React, { Component } from 'react'
import classnames from 'classnames'
import { connect } from 'react-redux'
import { createStructuredSelector, createSelector } from 'reselect'
import immer from 'immer'
import * as R from 'ramda'

import { siteConfigurationSelector, isObjectJustCreatedSelector, scenesSelector } from '../../selectors'
import { siteObjectUpdate } from '../../actions/site'
import Heading from '../../components/Heading'
import TextField from '../../components/TextField'
import Select from '../../components/Select'
import RadioButton from '../../components/RadioButton'
import RadioButtonGroup from '../../components/RadioButtonGroup'
import { ReactComponent as FourSceneIconTopLeft } from '../../assets/4-scene-top-left.svg'
import { ReactComponent as FourSceneIconTopRight } from '../../assets/4-scene-top-right.svg'
import { ReactComponent as FourSceneIconBottomLeft } from '../../assets/4-scene-bottom-left.svg'
import { ReactComponent as FourSceneIconBottomRight } from '../../assets/4-scene-bottom-right.svg'
import { ReactComponent as TwoSceneIconTop } from '../../assets/2-scene-top.svg'
import { ReactComponent as TwoSceneIconBottom } from '../../assets/2-scene-bottom.svg'

import s from './SwitchConfiguration.module.scss'

const getDevicesValue = (sideConfiguration) => {
  if (sideConfiguration.spaceId) return { type: 'space-devices' }
  if (sideConfiguration.roomId) return { type: 'room-devices' }
  return { type: 'selected-devices', objects: sideConfiguration.objectIds || [] }
}

const getActionType = (configuration, side) => {
  const is4Scene = Boolean(configuration.left.top && configuration.right.top)
  const is2Scene = !is4Scene && Boolean(configuration.right.top)

  return is2Scene ? "lightTwoScenes" : (configuration[side].action || "lightOnOff")
}

const configToScene = (conf) => {
  if(!conf) return undefined
  const target = conf.spaceId ? 'space' : (conf.roomId ? 'room' : 'scene')

  if(target === 'space' || target === 'room') {
    let perc
    if      (!conf.state.on)         perc = 0
    else if (conf.state.bri === 38)  perc = 15
    else if (conf.state.bri === 178) perc = 70
    else if (conf.state.bri === 255) perc = 100

    return `${target}-${perc}`
  } else {
    return `scene-${conf.sceneId}`
  }
}

const getContext = (variant, configuration) => {
  const sanitizedVariant = configuration.left.top
    ? '4-scenes'
    : variant
  const devices = {
    left: getDevicesValue(configuration.left),
    right: getDevicesValue(configuration.right)
  }
  const actionType = {
    left: getActionType(configuration, 'left'),
    right: getActionType(configuration, 'right')
  }

  const scenes = {
    left: {
      top: configToScene(configuration.left.top) || 'room-100',
      bottom: configToScene(configuration.left.bottom) || 'room-70',
    },
    right: {
      top: configToScene(configuration.right.top) || 'room-15',
      bottom: configToScene(configuration.right.bottom) || 'room-0',
    }
  }

  return {
    variant: sanitizedVariant,
    devices,
    actionType,
    scenes
  }
}

const getDevicesConfiguration = (devices, space, room) => {
  if(devices.type === 'space-devices') {
    return { spaceId: space.id }
  } else  if(devices.type === 'room-devices') {
    return { roomId: room.id }
  }

  return { objectIds: devices.objects }
}

const sceneToConfig = (scene, room, space) => {
  const [target, ...rest] = scene.split("-")
  const value = rest.join("-")
  if(target === 'room' || target === 'space')  {
    const perc = value
    let state
    if (perc === '0')   state = { on: false }
    if (perc === '15')  state = { on: true, bri: 38 }
    if (perc === '70')  state = { on: true, bri: 178 }
    if (perc === '100') state = { on: true, bri: 255 }

    const targetProps  = target === 'room' ? { roomId: room.id } : { spaceId: space.id }

    return {
      action: 'lightSet',
      ...targetProps,
      state

    }
  } else if(target === 'scene') {
    return {
      action: 'sceneOn',
      sceneId: value
    }
  }

  throw new Error(`Invalid scene value: '${scene}'`)
}


const getSideConfiguration = (space, room, variant, actionType, devices, scenes) => {
  if(variant === '4-scenes' || actionType === 'lightTwoScenes') {
    return {
      top: sceneToConfig(scenes.top, room, space),
      bottom: sceneToConfig(scenes.bottom, room, space)
    }
  }

  return {
    action: actionType,
    ...getDevicesConfiguration(devices, space, room)
  }
}

const getConfiguration = (space, room, { variant, actionType, devices, scenes }) => ({
  double: {},
  left: variant === '1-key'
    ? {}
    : getSideConfiguration(space, room, variant, actionType.left, devices.left, scenes.left),
  right: getSideConfiguration(space, room, variant, actionType.right, devices.right, scenes.right)
})


const changeActionType = (context, side, actionType) => immer(context, draft => { draft.actionType[side] = actionType })
const stateChangeActionType = (actionType) => state => ({ context: changeActionType(state.context, state.side, actionType )})

const changeScene = (context, side, verticalSide, value) => immer(context, draft => { draft.scenes[side][verticalSide] = value })
const stateChangeScene = (side, verticalSide, value) => state => ({ context: changeScene(state.context, side, verticalSide, value) })

const changeDevices = (context, side, value) => immer(context, draft => {
  if(value === 'selected-devices') {
    draft.devices[side] = { type: 'selected-devices', objects: [] }
  } else  {
    draft.devices[side] = { type: value }
  }
})
const stateChangeDevices = (value) => state => ({ context: changeDevices(state.context, state.side, value) })

const changeVariant = (context, variant) => immer(context, draft => {
  const prevVariant = draft.variant
  draft.variant = variant

  // make sure that actionType is not left in invalid state ("lightTwoScenes")
  if(prevVariant !== variant && prevVariant === '1-key') {
    if(draft.actionType.left === 'lightTwoScenes') {
      draft.actionType.left = "lightOnOff"
    }
    if(draft.actionType.right === 'lightTwoScenes') {
      draft.actionType.right = "lightOnOff"
    }
  }
})
const stateChangeVariant = (value) => state => ({ context: changeVariant(state.context, value) })

const toggleLight = (context, side, lightId) =>  immer(context, draft =>  {
  const devices = draft.devices[side]
  if(devices.type === 'selected-devices') {
    if(devices.objects.includes(lightId)) {
      devices.objects = devices.objects.filter(id => id !== lightId)
    } else {
      devices.objects = [...devices.objects, lightId].sort()
    }
  }
})
const stateToggleLight = (lightId) => state => ({ context: toggleLight(state.context, state.side, lightId) })

const isLightType = (device) => device.type === 'light'
const isCurtainType = (device) => device.type === 'curtain'
const isNoneType = () => false

const ACTION_TYPE_MAP = {
  'lightOnOff': isLightType,
  'lightPushDim': isLightType,
  'curtainOpenClose': isCurtainType,
}

class SwitchConfiguration extends Component {
  constructor(props) {
    super(props)

    const initialContext = getContext(this.props.object.variant, this.props.object.configuration)
    this.state = {
      context: initialContext,
      side: 'right'
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if(prevState.context !== this.state.context) {
      this.props.onHasChangesUpdated(this.hasChanges())
    }
  }

  hasChanges() {
    const { object, space, room } = this.props
    const { context } = this.state

    // transform from context to configuration to context
    // to clean up any non-functional differences from data
    const sanitize = (space, room, context) =>
      getContext(context.variant, getConfiguration(space, room, context))

    const hasChanges = !R.equals(
      sanitize(space, room, getContext(object.variant, object.configuration)),
      sanitize(space, room, context)
    )
    return hasChanges
  }

  handleChangeActionType = (event) => {
    const newActionType = event.target.value
    this.setState(stateChangeActionType(newActionType))
  }

  handleChangeTopLeftScene = (newSceneValue) => {
    this.setState(stateChangeScene('left', 'top', newSceneValue))
  }

  handleChangeTopRightScene = (newSceneValue) => {
    this.setState(stateChangeScene('right', 'top', newSceneValue))
  }

  handleChangeBottomRightScene = (newSceneValue) => {
    this.setState(stateChangeScene('right', 'bottom', newSceneValue))
  }

  handleChangeBottomLeftScene = (newSceneValue) => {
    this.setState(stateChangeScene('left', 'bottom', newSceneValue))
  }

  handleChangeDevices = (event) => {
    const newDevicesValue = event.target.value
    this.setState(stateChangeDevices(newDevicesValue))
  }

  toggleLight = (event) => {
    const lightId = event.target.value
    this.setState(stateToggleLight(lightId))
  }

  handleToggleVariant = (event) => {
    const newValue = event.target.value
    this.setState(stateChangeVariant(newValue))
  }

  handleChangeSide = side => this.setState({ side })

  discard = () => {
    const { object: { variant, configuration } } = this.props
    this.setState({ context: getContext(variant, configuration) })
  }

  synchronize = () => {
    const { space, room, object, siteId, dispatch, onHasChangesUpdated } = this.props
    const { context } = this.state

    const configuration = getConfiguration(space, room, context)
    const sanitizedVariant = context.variant === '4-scenes' ? '2-key' : context.variant
    dispatch(siteObjectUpdate(siteId, object.id, { ...object, variant: sanitizedVariant, configuration }))
    onHasChangesUpdated(false)
  }

  getActionHeadingActions = () => {
    const { side, context: { variant } } = this.state

    if (variant !== '2-key') return []
    return [
      {
        label: 'Left',
        onClick: () => this.handleChangeSide('left'),
        className: classnames(s.actionButton, { [s.actionButtonActive]: side === 'left' })
      },
      {
        label: 'Right',
        onClick: () => this.handleChangeSide('right'),
        className: classnames(s.actionButton, { [s.actionButtonActive]: side === 'right' })
      }
    ]
  }

  renderActionConfiguration() {
    const { roomObjects } = this.props
    const { side, context } = this.state
    const { variant, devices: { [side]: devices }, actionType: { [side]: actionType  } } =  context

    const actionTypeOptions = [
      { label: 'On-off', value: 'lightOnOff' },
      { label: 'Pushdim', value: 'lightPushDim' },
      { label: 'Curtain', value: 'curtainOpenClose' },
    ].concat(variant === '1-key' ? [{ label: 'Two scenes', value: 'lightTwoScenes' }] : [])

    const isOneKeyTwoLightsEnabled = variant === "1-key" && actionType === "lightTwoScenes"

    const isCorrectType = ACTION_TYPE_MAP[actionType] || isNoneType

    return (
      <>
        <Heading
          label='Configuration'
          className={s.actionHeader}
          actions={this.getActionHeadingActions()}
        />
        <RadioButtonGroup
          radioButtonClassName={s.radioButton}
          selected={actionType}
          onChange={this.handleChangeActionType}
          options={actionTypeOptions}
        />

        {isOneKeyTwoLightsEnabled
          ? this.renderSceneConfiguration()
          : (
            <>
              <Heading label='Devices' />
              <RadioButtonGroup
                radioButtonClassName={s.radioButton}
                selected={devices.type}
                onChange={this.handleChangeDevices}
                options={[
                  { label: 'All in this space', value: 'space-devices' },
                  { label: 'All in this room', value: 'room-devices' },
                  { label: 'Selected devices', value: 'selected-devices' },
                ]}
              />
              {devices.type === 'selected-devices' &&
                <>
                  <Heading label='Selected' />
                  {roomObjects.filter(isCorrectType).map(object => (
                    <RadioButton
                      key={object.id}
                      className={s.radioButton}
                      label={object.name}
                      type='checkbox'
                      onChange={this.toggleLight}
                      value={object.id}
                      checked={devices.objects.includes(object.id)}
                    />
                  ))}
                </>
              }
            </>
          )
        }
      </>
    )
  }

  renderSceneConfiguration() {
    const { roomName, scenes } = this.props
    const { context: { variant, scenes: sceneValues } } = this.state
    const renderFourScenes = variant === "4-scenes"

    const options = [
      { label: 'All rooms: 100%', value: 'space-100' },
      { label: 'All rooms: 70%', value: 'space-70' },
      { label: 'All rooms: 15%', value: 'space-15' },
      { label: 'All rooms: Off', value: 'space-0' },
      { label: `${roomName}: 100%`, value: 'room-100' },
      { label: `${roomName}: 70%`, value: 'room-70' },
      { label: `${roomName}: 15%`, value: 'room-15' },
      { label: `${roomName}: Off`, value: 'room-0' },
      ...scenes.map(scene => ({ label: scene.name, value: `scene-${scene.id}` }))
    ]

    return (
      <>
        <Heading label='Scenes' />
        {renderFourScenes && (
          <Select
            onSelection={this.handleChangeTopLeftScene}
            value={sceneValues.left.top}
            options={options}
            border={true}
            icon={<FourSceneIconTopLeft />}
          />
        )}
        {renderFourScenes && (
          <Select
            onSelection={this.handleChangeBottomLeftScene}
            value={sceneValues.left.bottom}
            options={options}
            border={true}
            icon={<FourSceneIconBottomLeft />}
          />
        )}
        <Select
          onSelection={this.handleChangeTopRightScene}
          value={sceneValues.right.top}
          options={options}
          border={true}
          icon={renderFourScenes ? <FourSceneIconTopRight /> : <TwoSceneIconTop />}
        />
        <Select
          onSelection={this.handleChangeBottomRightScene}
          value={sceneValues.right.bottom}
          options={options}
          border={true}
          icon={renderFourScenes ? <FourSceneIconBottomRight /> : <TwoSceneIconBottom />}
        />
      </>
    )
  }

  render() {
    const { object, disabled, isJustCreated, onRename } = this.props
    const { context: { variant } } = this.state
    return (
      <>
        <Heading label='Location' />
        <TextField
          initialValue={object.name}
          onSave={onRename}
          disabled={disabled}
          activateOnMount={isJustCreated}
          allowEmpty
        />

        <Heading label='Type' />
        <RadioButtonGroup
          radioButtonClassName={s.radioButton}
          selected={variant}
          onChange={this.handleToggleVariant}
          options={[
            { label: '1-key', value: '1-key' },
            { label: '2-key', value: '2-key' },
            { label: '2-key, four scenes', value: '4-scenes' },
          ]}
        />

        {variant === '4-scenes'
          ? this.renderSceneConfiguration()
          : this.renderActionConfiguration()}
      </>
    )
  }
}

const objectsSelector = createSelector(
  [siteConfigurationSelector],
  (siteConfiguration) => siteConfiguration.objects
)

const roomsSelector = createSelector(
  [siteConfigurationSelector],
  (siteConfiguration) => siteConfiguration.rooms
)

const spacesSelector = createSelector(
  [siteConfigurationSelector],
  (siteConfiguration) => siteConfiguration.spaces
)

// Object selectors

const objectIdSelector = (state, props) => props.objectId

const objectSelector = createSelector(
  [objectIdSelector, objectsSelector],
  (objectId, objects) => objects.find(o => o.id === objectId)
)

// Current room light selectors

const roomIdSelector = createSelector(
  [objectSelector],
  (object) => object.roomId
)

const roomObjectSelector = createSelector(
  [objectsSelector, roomIdSelector],
  (objects, roomId) => objects.filter(object => (object.type === 'curtain' || object.type === 'light') && object.roomId === roomId)
)

// Current space light selectors

const roomSelector = createSelector(
  [roomIdSelector, roomsSelector],
  (roomId, rooms) => rooms.find(room => room.id === roomId)
)

const spaceIdSelector = createSelector(
  [roomSelector],
  (room) => room.spaceId
)

const spaceRoomsSelector = createSelector(
  [roomsSelector, spaceIdSelector],
  (rooms, spaceId) => rooms.filter(room => room.spaceId === spaceId)
)

const spaceLightsSelector = createSelector(
  [objectsSelector, spaceRoomsSelector],
  (objects, spaceRooms) => {
    const roomIds = spaceRooms.map(room => room.id)
    return objects.filter(object => object.type === 'light' && roomIds.includes(object.roomId))
  }
)

const spaceSelector = createSelector(
  [spaceIdSelector, spacesSelector],
  (spaceId, spaces) => spaces.find(space => space.id === spaceId)
)

const spaceScenesSelector = createSelector(
  [scenesSelector, spaceIdSelector],
  (scenes, spaceId) => scenes.filter(scene => scene.spaceId === spaceId)
)

const mapStateToProps = createStructuredSelector({
  isJustCreated: isObjectJustCreatedSelector,
  object: objectSelector,
  spaceLights: spaceLightsSelector,
  roomObjects: roomObjectSelector,
  room: roomSelector,
  space: spaceSelector,
  scenes: spaceScenesSelector
})

export default connect(mapStateToProps, null,  null, { forwardRef: true })(SwitchConfiguration)
