import { createSelector } from 'reselect'
import { siteConfigurationSelector } from '.'
import { siteSelector } from './site'

export const roomsSelector = createSelector(
  [siteConfigurationSelector],
  (siteConfiguration) => siteConfiguration.rooms
)

const defaultIdSelector = (state, props) => props.roomId
const lastCreatedRoomIdSelector = createSelector([siteSelector], site => site.lastCreatedRoomId)
export const makeIsRoomJustCreatedSelector = (idSelector) => createSelector(
  [idSelector, lastCreatedRoomIdSelector],
  (roomId, lastCreatedRoomId) => roomId === lastCreatedRoomId
)
export const isRoomJustCreatedSelector = makeIsRoomJustCreatedSelector(defaultIdSelector)
