import { call, takeLatest, join, put, select, delay } from 'redux-saga/effects'

import { SOCKET_CONNECT } from '../constants/actions'
import { siteSubscribe } from '../actions'

const subscriptionsSelector = (state) => state.subscriptions

function * supervisorConnectWorker() {
  yield delay(100)

  const subscriptions = yield select(subscriptionsSelector)

  for (const [siteId, subscribed] of Object.entries(subscriptions)) {
    if (!subscribed) {
      yield put(siteSubscribe(siteId))
    }
  }
}

function * supervisorProcess() {
  yield join([
    yield takeLatest(SOCKET_CONNECT, supervisorConnectWorker),
  ])
}

export default function * supervisor() {
  for (;;) {
    try {
      yield call(supervisorProcess)
      console.error('Supervisor process terminated')
    } catch (e) {
      console.error('Supervisor process crashed', e)
    }
  }
}
