import { select, take, put } from 'redux-saga/effects'

import { socketOnlineSelector } from '../selectors'
import { SOCKET_CONNECT } from '../constants/actions'
import { ready } from '../actions/ready'

export default function * readyProcess() {
  const online = yield select(socketOnlineSelector)
  if (!online) {
    yield take(SOCKET_CONNECT)
  }
  yield put(ready())
}
