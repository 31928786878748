import { eventChannel, buffers } from 'redux-saga'
import { all, call, fork, takeEvery, put, take, select, delay, cancel, actionChannel, race, debounce, flush } from 'redux-saga/effects'
import SocketClient from '@mountkelvin/websocket-client'

import { SERVER_URL } from '../constants/settings'
import { authPresentSelector, authTokenSelector } from '../selectors'

import {
  AUTH_UPDATE,
  AUTH_REMOVE,
  DISCOVER,
  WIREPAS_DIRECT,
  SITE_SUBSCRIBE,
  SITE_CREATE,
  SITE_UPDATE,
  SITE_UPDATE_PART,
  SITE_DELETE,
  SITE_FLOOR_CREATE,
  SITE_FLOOR_UPDATE,
  SITE_FLOOR_DELETE,
  SITE_SPACE_CREATE,
  SITE_SPACE_UPDATE,
  SITE_SPACE_UPDATE_PART,
  SITE_OBJECT_UPDATE_MATERIALS,
  SITE_SPACE_DELETE,
  SITE_SPACE_RESOURCE_RESET,
  SITE_ROOM_CREATE,
  SITE_ROOM_UPDATE,
  SITE_ROOM_DELETE,
  SITE_OBJECT_CREATE,
  SITE_OBJECT_UPDATE,
  SITE_OBJECT_UPDATE_PART,
  SITE_OBJECT_DELETE,
  SITE_OBJECT_ASSIGN_RESOURCE,
  SITE_OBJECT_ASSIGN_RAW_RESOURCE,
  SITE_OBJECT_UNASSIGN_RESOURCE,
  BLINK_ONCE,
  BLINK_START,
  BLINK_STOP,
  SITE_RESOURCE_UPDATE_PART,
  SITE_GATEWAY_UPDATE_PART,
  SITE_HARD_RESET,
  OBJECT_APPLY,
  SITE_RESOURCE_DELETE,
  SITE_SCENE_CREATE,
  SITE_SCENE_UPDATE,
  SITE_SCENE_DELETE,
  SITE_RESOURCE_FORCE_RESYNC,
  SITE_APPLY,
  SITE_RAW_CONFIGURATION,
  API_KEY_LIST,
  API_KEY_CREATE,
  API_KEY_UPDATE_PART,
  API_KEY_DELETE,
} from '../constants/actions'
import {
  socketConnect,
  socketDisconnect,
} from '../actions/socket'
import {
  discoverSuccess,
  discoverFailure,
  wirepasDirectCommandSuccess,
  wirepasDirectCommandFailure,
  blinkOnceFailure,
  blinkOnceSuccess,
  blinkStartFailure,
  blinkStartSuccess,
  blinkStopFailure,
  blinkStopSuccess,
  siteConfiguration,
  siteRawConfiguration,
  siteState,
  siteSubscribeSuccess,
  siteSubscribeFailure,
  siteIndex,
  siteCreateSuccess,
  siteCreateFailure,
  siteUpdateSuccess,
  siteUpdateFailure,
  siteUpdatePartSuccess,
  siteUpdatePartFailure,
  siteDeleteSuccess,
  siteDeleteFailure,
  siteFloorCreateSuccess,
  siteFloorCreateFailure,
  siteFloorUpdateSuccess,
  siteFloorUpdateFailure,
  siteFloorDeleteSuccess,
  siteFloorDeleteFailure,
  siteSpaceCreateSuccess,
  siteSpaceCreateFailure,
  siteSpaceUpdateSuccess,
  siteSpaceUpdateFailure,
  siteSpaceUpdatePartSuccess,
  siteSpaceUpdatePartFailure,
  siteSpaceDeleteSuccess,
  siteSpaceDeleteFailure,
  siteSpaceResourceResetSuccess,
  siteSpaceResourceResetFailure,
  siteRoomCreateSuccess,
  siteRoomCreateFailure,
  siteRoomUpdateSuccess,
  siteRoomUpdateFailure,
  siteRoomDeleteSuccess,
  siteRoomDeleteFailure,
  siteObjectCreateSuccess,
  siteObjectCreateFailure,
  siteObjectUpdateSuccess,
  siteObjectUpdateFailure,
  siteObjectUpdatePartSuccess,
  siteObjectUpdatePartFailure,
  siteObjectDeleteSuccess,
  siteObjectDeleteFailure,
  siteObjectAssignResourceSuccess,
  siteObjectAssignResourceFailure,
  siteObjectAssignRawResourceSuccess,
  siteObjectAssignRawResourceFailure,
  siteObjectUnassignResourceSuccess,
  siteObjectUnassignResourceFailure,
  siteResourceUpdatePartSuccess,
  siteResourceUpdatePartFailure,
  siteGatewayUpdatePartSuccess,
  siteGatewayUpdatePartFailure,
  resourceInput,
  siteHardResetSuccess,
  siteHardResetFailure,
  objectApplySuccess,
  objectApplyFailure,
  siteResourceDeleteSuccess,
  siteResourceDeleteFailure,
  siteSceneUpdateSuccess,
  siteSceneUpdateFailure,
  siteSceneDeleteSuccess,
  siteSceneDeleteFailure,
  siteResourceForceResyncFailure,
  siteResourceForceResyncSuccess,
  siteApplySuccess,
  siteApplyFailure,
  siteSceneCreateSuccess,
  siteSceneCreateFailure,
  apiKeyListSuccess,
  apiKeyListFailure,
  apiKeyCreateSuccess,
  apiKeyCreateFailure,
  apiKeyUpdatePartSuccess,
  apiKeyUpdatePartFailure,
  apiKeyDeleteSuccess,
  apiKeyDeleteFailure,
} from '../actions'

function * authTokenProvider() {
  for (;;) {
    const authPresent = yield select(authPresentSelector)
    const authToken = yield select(authTokenSelector)
    if (!authPresent) {
      return null
    }
    if (authToken) {
      return authToken
    }
    yield take([AUTH_UPDATE, AUTH_REMOVE])
  }
}

function createSocket() {
  const wsUrl = 'ws' + SERVER_URL.replace(/^http(s?)/, '$1') + '/v4'
  const ws = new SocketClient(wsUrl)

  ws.on('connect', () => {
  })

  return ws
}

const socketOnlineChannelSource = (ws) => (emitter) => {
  const handleConnect = () => {
    emitter(true)
  }
  const handleDisconnect = () => {
    emitter(false)
  }

  ws.on('connect', handleConnect)
  ws.on('disconnect', handleDisconnect)

  return () => {
    ws.off('connect', handleConnect)
    ws.off('disconnect', handleDisconnect)
  }
}

function * socketStatusManager(ws) {
  const authUpdateChannel = yield actionChannel(AUTH_UPDATE, buffers.sliding(1))
  const onlineStatusChannel = yield call(eventChannel, socketOnlineChannelSource(ws), buffers.expanding(4))

  try {
    for (;;) {
      const { onlineStatusChange } = yield race({
        authUpdate: take(authUpdateChannel),
        onlineStatusChange: take(onlineStatusChannel),
      })

      // authorize socket on both connect and credentials update
      if (onlineStatusChange !== false) {
        const authToken = yield call(authTokenProvider)
        yield flush(authUpdateChannel)
        if (authToken) {
          try {
            yield call([ws, ws.request], 'auth', { token: authToken })
          } catch (err) {
            console.error('Socket authentication failed', err)
            throw err
          }
        }
      }

      if (onlineStatusChange != null) {
        const action = onlineStatusChange ? socketConnect() : socketDisconnect()
        yield put(action)
      }
    }
  } finally {
    onlineStatusChannel.close()
    authUpdateChannel.close()
  }
}

const CLIENT_METHODS = {
  'site.configuration': function * configurationMethod(result) {
    yield put(siteRawConfiguration(result.siteId, result.siteConfiguration))
  },
  'site.state': function * configurationMethod(params) {
    yield put(siteState(params.siteId, params.siteStateList))
  },
  'site.index': function * siteIndexMethod(result) {
    yield put(siteIndex(result.siteList))
  },
  'input': function * inputWorker(params) {
    yield put(resourceInput(params.siteId, params.input))
  },
}

function * siteRawConfigurationManager() {
  const rawDataChannel = yield actionChannel(
    SITE_RAW_CONFIGURATION,
    buffers.expanding(16)
  )
  const siteChangesChannel = yield actionChannel(
    SITE_OBJECT_UPDATE_MATERIALS,
    buffers.expanding(32)
  )

  const lastSiteChanges = {}
  const delayedConfigurationUpdates = {}

  for (;;) {
    const { rawData, siteChange } = yield race({
      rawData: take(rawDataChannel),
      siteChange: take(siteChangesChannel),
    })
    const { payload } = rawData || siteChange
    const { siteId } = payload

    if (delayedConfigurationUpdates[siteId]) {
      yield cancel(delayedConfigurationUpdates[siteId])
      delayedConfigurationUpdates[siteId] = null
    }

    if (siteChange) {
      lastSiteChanges[siteId] = Date.now()
    } else if (rawData) {
      const lastChangeTimestamp = lastSiteChanges[siteId]
      const { siteConfiguration: configuration } = payload
      if (lastChangeTimestamp) {
        if (Date.now() - lastChangeTimestamp >= 1000) {
          yield put(siteConfiguration(siteId, configuration))
          lastSiteChanges[siteId] = 0
        } else {
          delayedConfigurationUpdates[siteId] = yield fork(delayedSiteConfigurationWorker, siteId, configuration)
        }
      } else {
        yield put(siteConfiguration(siteId, configuration))
      }
    }
  }
}

function * delayedSiteConfigurationWorker(siteId, configuration) {
  yield delay(1000)
  yield put(siteConfiguration(siteId, configuration))
}

function createSocketChannel(ws) {
  return eventChannel(emitter => {
    for (const method of Object.keys(CLIENT_METHODS)) {
      ws.method(method, (params) => {
        return new Promise(function (resolve, reject) {
          emitter({ method, params, promise: { resolve, reject } })
        })
      })
    }
    return () => {}
  })
}

function * socketMethodHandler({ method, params, promise }) {
  const methodWorker = CLIENT_METHODS[method]
  try {
    const result = yield call(methodWorker, params)
    promise.resolve(result)
  } catch (e) {
    promise.reject(new Error('Internal error'))
    throw e
  }
}

function * socketIncomingManager(ws) {
  const socketChannel = yield call(createSocketChannel, ws)
  try {
    for (;;) {
      const message = yield take(socketChannel)
      yield fork(socketMethodHandler, message)
    }
  } finally {
    try {
      socketChannel.close()
    } catch (e) {
      console.warn('Error closing socket channel', e)
    }
  }
}

function * socketSiteSubscribeWorker(ws, action) {
  const { siteId } = action.payload
  const res = yield call([ws, ws.request], 'site.subscribe', { siteId })
  if (res.error) {
    yield put(siteSubscribeFailure(siteId, res.error))
  } else {
    yield put(siteSubscribeSuccess(siteId))
  }
}

function * socketDiscoverWorker(ws, action) {
  const { siteId } = action.payload
  const response = yield call([ws, ws.request], 'resource.scan', { siteId })
  if (response.error) {
    yield put(discoverFailure(siteId, response.error))
  } else {
    const { resources } = response
    yield put(discoverSuccess(siteId, resources))
  }
}

function * socketWirepasDirectWorker(ws, { payload: { siteId, action } }) {
  const response = yield call([ws, ws.request], 'wirepas.direct', { siteId, action })
  if (response.error) {
    yield put(wirepasDirectCommandFailure(siteId, response.error))
  } else {
    yield put(wirepasDirectCommandSuccess(siteId))
  }
}

function * socketBlinkOnceWorker(ws, action) {
  const { siteId, resourceId } = action.payload
  try {
    yield call([ws, ws.request], 'resource.identify.once', { siteId, resourceId })
    yield put(blinkOnceSuccess(siteId, resourceId))
  } catch (error) {
    yield put(blinkOnceFailure(siteId, resourceId, error))
  }
}

function * socketBlinkStartWorker(ws, action) {
  const { siteId, resourceId } = action.payload
  try {
    yield call([ws, ws.request], 'resource.identify.start', { siteId, resourceId })
    yield put(blinkStartSuccess(siteId, resourceId))
  } catch (error) {
    yield put(blinkStartFailure(siteId, resourceId, error))
  }
}

function * socketBlinkStopWorker(ws, action) {
  const { siteId, resourceId } = action.payload
  try {
    yield call([ws, ws.request], 'resource.identify.stop', { siteId, resourceId })
    yield put(blinkStopSuccess(siteId, resourceId))
  } catch (error) {
    yield put(blinkStopFailure(siteId, resourceId, error))
  }
}

function * objectApplyWorker(ws, { payload }) {
  const { siteId, targetSpaceId, targetRoomId, targetObjectId, action, state } = payload
  try {
    yield call([ws, ws.request], 'apply', { siteId, targetSpaceId, targetRoomId, targetObjectId, action, state })
    yield put(objectApplySuccess(siteId))
  } catch (error) {
    yield put(objectApplyFailure(siteId, error))
  }
}

function * socketOutgoingManager(ws) {
  yield all([
    takeEvery(SITE_SUBSCRIBE, socketSiteSubscribeWorker, ws),
    takeEvery(DISCOVER, socketDiscoverWorker, ws),
    takeEvery(WIREPAS_DIRECT, socketWirepasDirectWorker, ws),
    takeEvery(BLINK_ONCE, socketBlinkOnceWorker, ws),
    takeEvery(BLINK_START, socketBlinkStartWorker, ws),
    takeEvery(BLINK_STOP, socketBlinkStopWorker, ws),
    takeEvery(OBJECT_APPLY, objectApplyWorker, ws),
  ])
}

function * socketProcess() {
  const ws = yield call(createSocket)
  try {
    yield all([
      socketStatusManager(ws),
      socketIncomingManager(ws),
      socketOutgoingManager(ws),
    ])
  } finally {
    try {
      // FIXME expose destroy
      // ws.destroy()
    } catch (e) {
      console.warn('Error closing socket', e)
    }
    // try {
    //   yield put(socketDisconnected());
    // } catch (e) {
    //   console.error('Error dispatching disconnection action', e);
    // }
  }
}

function * socketProcessMonitor() {
  for (;;) {
    try {
      yield call(socketProcess)
      console.error('Socket process terminated')
    } catch (e) {
      console.error('Socket process crashed', e)
    }
  }
}

function * request(url, { method, body }) {
  const authToken = yield call(authTokenProvider)

  return yield call(fetch, `${SERVER_URL}${url}`, {
    method,
    body,
    headers: {
      'content-type': 'application/json; charset=utf-8',
      'accept': 'application/json',
      'authorization': `Bearer ${authToken}`,
    },
  })
}

function * siteCreateWorker(action) {
  const { siteBody } = action.payload

  const body = JSON.stringify(siteBody)
  const res = yield call(request, `/v4/sites`, { method: 'post', body })

  if (res.status === 201) {
    const { id: siteId, ...siteBody } = yield res.json()
    yield put(siteCreateSuccess(siteId, siteBody))
  } else {
    yield put(siteCreateFailure())
  }
}

function * siteUpdateWorker(action) {
  const { siteId, siteBody } = action.payload

  const body = JSON.stringify(siteBody)
  const res = yield call(request, `/v4/sites/${siteId}`, { method: 'put', body })

  if (res.status === 204) {
    yield put(siteUpdateSuccess(siteId, siteBody))
  } else {
    yield put(siteUpdateFailure(siteId))
  }
}

function * siteUpdatePartWorker(action) {
  const { siteId, siteBodyPart } = action.payload

  const body = JSON.stringify(siteBodyPart)
  const res = yield call(request, `/v4/sites/${siteId}`, { method: 'PATCH', body })

  if (res.status === 204) {
    yield put(siteUpdatePartSuccess(siteId, siteBodyPart))
  } else {
    yield put(siteUpdatePartFailure(siteId))
  }
}

function * siteDeleteWorker(action) {
  const { siteId } = action.payload

  const res = yield call(request, `/v4/sites/${siteId}`, { method: 'delete' })

  if (res.status === 204) {
    yield put(siteDeleteSuccess(siteId))
  } else {
    yield put(siteDeleteFailure(siteId))
  }
}

function * siteFloorCreateWorker(action) {
  const { siteId, floorBody, sourceFloorId } = action.payload

  const body = JSON.stringify(floorBody)
  const res = yield call(
    request,
    `/v4/sites/${siteId}/floors${sourceFloorId ? `?sourceFloorId=${sourceFloorId}` : ''}`,
    { method: 'post', body }
  )

  if (res.status === 201) {
    const { id: floorId, ...floorBody } = yield res.json()
    yield put(siteFloorCreateSuccess(siteId, floorId, floorBody))
  } else {
    yield put(siteFloorCreateFailure(siteId))
  }
}

function * siteFloorUpdateWorker(action) {
  const { siteId, floorId, floorBody } = action.payload

  const body = JSON.stringify(floorBody)
  const res = yield call(request, `/v4/sites/${siteId}/floors/${floorId}`, { method: 'put', body })

  if (res.status === 204) {
    yield put(siteFloorUpdateSuccess(siteId, floorId))
  } else {
    yield put(siteFloorUpdateFailure(siteId))
  }
}

function * siteFloorDeleteWorker(action) {
  const { siteId, floorId } = action.payload

  const res = yield call(request, `/v4/sites/${siteId}/floors/${floorId}`, { method: 'delete' })

  if (res.status === 204) {
    yield put(siteFloorDeleteSuccess(siteId, floorId))
  } else {
    yield put(siteFloorDeleteFailure(siteId, floorId))
  }
}

function * siteSpaceCreateWorker(action) {
  const { siteId, spaceBody, sourceSpaceId } = action.payload

  const body = JSON.stringify(spaceBody)
  const res = yield call(
    request,
    `/v4/sites/${siteId}/spaces${sourceSpaceId ? `?sourceSpaceId=${sourceSpaceId}` : ''}`,
    { method: 'post', body }
  )

  if (res.status === 201) {
    const { id: spaceId, ...spaceBody } = yield res.json()
    yield put(siteSpaceCreateSuccess(siteId, spaceId, spaceBody))
  } else {
    yield put(siteSpaceCreateFailure(siteId))
  }
}

function * siteSpaceUpdateWorker(action) {
  const { siteId, spaceId, spaceBody } = action.payload

  const body = JSON.stringify(spaceBody)
  const res = yield call(request, `/v4/sites/${siteId}/spaces/${spaceId}`, { method: 'put', body })

  if (res.status === 204) {
    yield put(siteSpaceUpdateSuccess(siteId, spaceId))
  } else {
    yield put(siteSpaceUpdateFailure(siteId))
  }
}

function * siteSpaceUpdatePartWorker(action) {
  const { siteId, spaceId, spaceBodyPart } = action.payload

  const body = JSON.stringify(spaceBodyPart)
  const res = yield call(request, `/v4/sites/${siteId}/spaces/${spaceId}`, { method: 'PATCH', body })

  if (res.status === 204) {
    yield put(siteSpaceUpdatePartSuccess(siteId, spaceId))
  } else {
    yield put(siteSpaceUpdatePartFailure(siteId))
  }
}

function * siteSpaceDeleteWorker(action) {
  const { siteId, spaceId } = action.payload

  const res = yield call(request, `/v4/sites/${siteId}/spaces/${spaceId}`, { method: 'delete' })

  if (res.status === 204) {
    yield put(siteSpaceDeleteSuccess(siteId, spaceId))
  } else {
    yield put(siteSpaceDeleteFailure(siteId, spaceId))
  }
}

function * siteSpaceResourceResetWorker(action) {
  const { siteId, spaceId } = action.payload

  const res = yield call(request, `/v4/sites/${siteId}/spaces/${spaceId}/resources`, { method: 'delete' })

  if (res.status === 204) {
    yield put(siteSpaceResourceResetSuccess(siteId, spaceId))
  } else {
    yield put(siteSpaceResourceResetFailure(siteId, spaceId))
  }
}

function * siteRoomCreateWorker(action) {
  const { siteId, roomBody } = action.payload

  const body = JSON.stringify(roomBody)
  const res = yield call(request, `/v4/sites/${siteId}/rooms`, { method: 'post', body })

  if (res.status === 201) {
    const { id: roomId, ...roomBody } = yield res.json()
    yield put(siteRoomCreateSuccess(siteId, roomId, roomBody))
  } else {
    yield put(siteRoomCreateFailure(siteId))
  }
}

function * siteRoomUpdateWorker(action) {
  const { siteId, roomId, roomBody } = action.payload

  const body = JSON.stringify(roomBody)
  const res = yield call(request, `/v4/sites/${siteId}/rooms/${roomId}`, { method: 'put', body })

  if (res.status === 204) {
    yield put(siteRoomUpdateSuccess(siteId, roomId))
  } else {
    yield put(siteRoomUpdateFailure(siteId))
  }
}

function * siteRoomDeleteWorker(action) {
  const { siteId, roomId } = action.payload

  const res = yield call(request, `/v4/sites/${siteId}/rooms/${roomId}`, { method: 'delete' })

  if (res.status === 204) {
    yield put(siteRoomDeleteSuccess(siteId, roomId))
  } else {
    yield put(siteRoomDeleteFailure(siteId, roomId))
  }
}

function * siteSceneCreateWorker(action) {
  const { siteId, sceneBody } = action.payload

  const body = JSON.stringify(sceneBody)
  const res = yield call(request, `/v4/sites/${siteId}/scenes`, { method: 'post', body })

  if (res.status === 201) {
    const { id: roomId, ...sceneBody } = yield res.json()
    yield put(siteSceneCreateSuccess(siteId, roomId, sceneBody))
  } else {
    yield put(siteSceneCreateFailure(siteId))
  }
}

function * siteSceneUpdateWorker(action) {
  const { siteId, sceneId, sceneBody } = action.payload

  const body = JSON.stringify(sceneBody)
  const res = yield call(request, `/v4/sites/${siteId}/scenes/${sceneId}`, { method: 'put', body })

  if (res.status === 204) {
    yield put(siteSceneUpdateSuccess(siteId, sceneId))
  } else {
    yield put(siteSceneUpdateFailure(siteId))
  }
}

function * siteSceneDeleteWorker(action) {
  const { siteId, sceneId } = action.payload

  const res = yield call(request, `/v4/sites/${siteId}/scenes/${sceneId}`, { method: 'delete' })

  if (res.status === 204) {
    yield put(siteSceneDeleteSuccess(siteId, sceneId))
  } else {
    yield put(siteSceneDeleteFailure(siteId, sceneId))
  }
}

function * siteObjectCreateWorker(action) {
  const { siteId, objectBody } = action.payload

  const body = JSON.stringify(objectBody)
  const res = yield call(request, `/v4/sites/${siteId}/objects`, { method: 'post', body })

  if (res.status === 201) {
    const { id: objectId, ...objectBody } = yield res.json()
    yield put(siteObjectCreateSuccess(siteId, objectId, objectBody))
  } else {
    yield put(siteObjectCreateFailure(siteId))
  }
}

function * siteObjectUpdateWorker(action) {
  const { siteId, objectId, objectBody } = action.payload

  const body = JSON.stringify(objectBody)
  const res = yield call(request, `/v4/sites/${siteId}/objects/${objectId}`, { method: 'put', body })

  if (res.status === 204) {
    yield put(siteObjectUpdateSuccess(siteId, objectId))
  } else {
    yield put(siteObjectUpdateFailure(siteId))
  }
}

function * siteObjectDeleteWorker(action) {
  const { siteId, objectId } = action.payload

  const res = yield call(request, `/v4/sites/${siteId}/objects/${objectId}`, { method: 'delete' })

  if (res.status === 204) {
    yield put(siteObjectDeleteSuccess(siteId, objectId))
  } else {
    yield put(siteObjectDeleteFailure(siteId, objectId))
  }
}

function * siteObjectUpdatePart(action) {
  const { siteId, objectId, updates } = action.payload

  const body = JSON.stringify(updates)
  const res = yield call(request, `/v4/sites/${siteId}/objects/${objectId}`, { method: 'PATCH', body })

  if (res.status === 204) {
    yield put(siteObjectUpdatePartSuccess(siteId, objectId, updates))
  } else {
    yield put(siteObjectUpdatePartFailure(siteId, objectId, updates))
  }
}

function * siteObjectAssignResource(action) {
  const { siteId, objectId, resourceId } = action.payload

  const res = yield call(request, `/v4/sites/${siteId}/objects/${objectId}/resources/${resourceId}`, { method: 'put' })

  if (res.status === 204) {
    yield put(siteObjectAssignResourceSuccess(siteId, objectId, resourceId))
  } else {
    yield put(siteObjectAssignResourceFailure(siteId, objectId, resourceId))
  }
}

function * siteObjectAssignRawResource(action) {
  const { siteId, objectId, resourceBody } = action.payload

  const body = JSON.stringify(resourceBody)
  const res = yield call(request, `/v4/sites/${siteId}/objects/${objectId}/resources`, { method: 'post', body })

  if (res.status === 204) {
    yield put(siteObjectAssignRawResourceSuccess(siteId, objectId, resourceBody))
  } else {
    yield put(siteObjectAssignRawResourceFailure(siteId, objectId, resourceBody))
  }
}

function * siteObjectUnassignResource(action) {
  const { siteId, objectId, resourceId } = action.payload

  const res = yield call(request, `/v4/sites/${siteId}/objects/${objectId}/resources/${resourceId}`, { method: 'delete' })

  if (res.status === 204) {
    yield put(siteObjectUnassignResourceSuccess(siteId, objectId, resourceId))
  } else {
    yield put(siteObjectUnassignResourceFailure(siteId, objectId, resourceId))
  }
}

function * siteResourceUpdatePart(action) {
  const { siteId, resourceId, updates } = action.payload

  const body = JSON.stringify(updates)
  const res = yield call(request, `/v4/sites/${siteId}/resources/${resourceId}`, { method: 'PATCH', body })

  if (res.status === 204) {
    yield put(siteResourceUpdatePartSuccess(siteId, resourceId, updates))
  } else {
    yield put(siteResourceUpdatePartFailure(siteId, resourceId, updates))
  }
}

function * siteGatewayUpdatePart(action) {
  const { siteId, gatewayId, updates } = action.payload

  const body = JSON.stringify(updates)
  const res = yield call(request, `/v4/sites/${siteId}/gateways/${gatewayId}`, { method: 'PATCH', body })

  if (res.status === 204) {
    yield put(siteGatewayUpdatePartSuccess(siteId, gatewayId, updates))
  } else {
    yield put(siteGatewayUpdatePartFailure(siteId, gatewayId, updates))
  }
}

function * siteResourceDelete(action) {
  const { siteId, resourceId } = action.payload

  const res = yield call(request, `/v4/sites/${siteId}/resources/${resourceId}`, { method: 'DELETE' })

  if (res.status === 204) {
    yield put(siteResourceDeleteSuccess(siteId, resourceId))
  } else {
    yield put(siteResourceDeleteFailure(siteId, resourceId))
  }
}

function * siteResourceForceResync(action) {
  const { siteId, objectId, resourceId } = action.payload

  const unassignRes = yield call(request, `/v4/sites/${siteId}/objects/${objectId}/resources/${resourceId}`, { method: 'delete' })
  if(unassignRes.status !== 204) {
    yield put(siteResourceForceResyncFailure(siteId, objectId, resourceId))
    return
  }

  yield delay(3000)

  const assignRes = yield call(request, `/v4/sites/${siteId}/objects/${objectId}/resources/${resourceId}`, { method: 'put' })
  if (assignRes.status === 204) {
    yield put(siteResourceForceResyncSuccess(siteId, resourceId))
  } else {
    yield put(siteResourceForceResyncFailure(siteId, resourceId))
  }
}

function * siteHardResetWorker(action) {
  const { siteId } = action.payload

  const res = yield call(request, `/v4/sites/${siteId}/resources`, { method: 'delete' })

  if (res.status === 204) {
    yield put(siteHardResetSuccess(siteId))
  } else {
    yield put(siteHardResetFailure(siteId))
  }
}

function * siteApplyWorker(action) {
  const { siteId, params } = action.payload

  const body = JSON.stringify(params)
  const res = yield call(request, `/v4/sites/${siteId}/apply`, { method: 'post', body })

  if (res.status === 204) {
    yield put(siteApplySuccess(siteId, params))
  } else {
    yield put(siteApplyFailure(siteId, params))
  }
}

function * apiKeyListWorker(action) {
  const { siteId } = action.payload

  const res = yield call(request, `/v4/sites/${siteId}/keys`, { method: 'GET' })

  if (res.status === 200) {
    const keyList = yield res.json()
    yield put(apiKeyListSuccess(siteId, keyList))
  } else {
    yield put(apiKeyListFailure(siteId))
  }
}

function * apiKeyCreateWorker(action) {
  const { siteId, keyBody: initialKeyBody } = action.payload

  const body = JSON.stringify(initialKeyBody)
  const res = yield call(request, `/v4/sites/${siteId}/keys`, { method: 'POST', body })

  if (res.status === 201) {
    const { id: keyId, token, ...keyBody } = yield res.json()
    yield put(apiKeyCreateSuccess(siteId, keyId, keyBody, token))
  } else {
    yield put(apiKeyCreateFailure(siteId))
  }
}

function * apiKeyUpdatePartWorker(action) {
  const { siteId, keyId, keyBodyPart } = action.payload

  const body = JSON.stringify(keyBodyPart)
  const res = yield call(request, `/v4/sites/${siteId}/keys/${keyId}`, { method: 'PATCH', body })

  if (res.status === 204) {
    yield put(apiKeyUpdatePartSuccess(siteId, keyId))
  } else {
    yield put(apiKeyUpdatePartFailure(siteId, keyId))
  }
}

function * apiKeyDeleteWorker(action) {
  const { siteId, keyId } = action.payload

  const res = yield call(request, `/v4/sites/${siteId}/keys/${keyId}`, { method: 'DELETE' })

  if (res.status === 204) {
    yield put(apiKeyDeleteSuccess(siteId, keyId))
  } else {
    yield put(apiKeyDeleteFailure(siteId, keyId))
  }
}

function * apiProcess() {
  yield all([
    socketProcessMonitor(),
    siteRawConfigurationManager(),
    takeEvery(SITE_CREATE, siteCreateWorker),
    takeEvery(SITE_UPDATE, siteUpdateWorker),
    takeEvery(SITE_UPDATE_PART, siteUpdatePartWorker),
    takeEvery(SITE_DELETE, siteDeleteWorker),
    takeEvery(SITE_FLOOR_CREATE, siteFloorCreateWorker),
    takeEvery(SITE_FLOOR_UPDATE, siteFloorUpdateWorker),
    takeEvery(SITE_FLOOR_DELETE, siteFloorDeleteWorker),
    takeEvery(SITE_SPACE_CREATE, siteSpaceCreateWorker),
    takeEvery(SITE_SPACE_UPDATE, siteSpaceUpdateWorker),
    takeEvery(SITE_SPACE_UPDATE_PART, siteSpaceUpdatePartWorker),
    takeEvery(SITE_SPACE_DELETE, siteSpaceDeleteWorker),
    takeEvery(SITE_SPACE_RESOURCE_RESET, siteSpaceResourceResetWorker),
    takeEvery(SITE_ROOM_CREATE, siteRoomCreateWorker),
    takeEvery(SITE_ROOM_UPDATE, siteRoomUpdateWorker),
    takeEvery(SITE_ROOM_DELETE, siteRoomDeleteWorker),
    takeEvery(SITE_SCENE_CREATE, siteSceneCreateWorker),
    takeEvery(SITE_SCENE_UPDATE, siteSceneUpdateWorker),
    takeEvery(SITE_SCENE_DELETE, siteSceneDeleteWorker),
    takeEvery(SITE_OBJECT_CREATE, siteObjectCreateWorker),
    takeEvery(SITE_OBJECT_UPDATE, siteObjectUpdateWorker),
    takeEvery(SITE_OBJECT_DELETE, siteObjectDeleteWorker),
    takeEvery(SITE_OBJECT_ASSIGN_RESOURCE, siteObjectAssignResource),
    takeEvery(SITE_OBJECT_ASSIGN_RAW_RESOURCE, siteObjectAssignRawResource),
    takeEvery(SITE_OBJECT_UNASSIGN_RESOURCE, siteObjectUnassignResource),
    takeEvery(SITE_RESOURCE_UPDATE_PART, siteResourceUpdatePart),
    takeEvery(SITE_OBJECT_UPDATE_PART, siteObjectUpdatePart),
    debounce(300, SITE_OBJECT_UPDATE_MATERIALS, siteObjectUpdatePart),
    takeEvery(SITE_GATEWAY_UPDATE_PART, siteGatewayUpdatePart),
    takeEvery(SITE_RESOURCE_DELETE, siteResourceDelete),
    takeEvery(SITE_RESOURCE_FORCE_RESYNC, siteResourceForceResync),
    takeEvery(SITE_HARD_RESET, siteHardResetWorker),
    takeEvery(SITE_APPLY, siteApplyWorker),
    takeEvery(API_KEY_LIST, apiKeyListWorker),
    takeEvery(API_KEY_CREATE, apiKeyCreateWorker),
    takeEvery(API_KEY_UPDATE_PART, apiKeyUpdatePartWorker),
    takeEvery(API_KEY_DELETE, apiKeyDeleteWorker),
  ])
}

export default function * api() {
  for (;;) {
    try {
      yield call(apiProcess)
      console.error('API process terminated')
    } catch (e) {
      console.error('API process crashed', e)
    }
  }
}
