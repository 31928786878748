import { createStore, applyMiddleware, compose } from 'redux'
import * as R from 'ramda'
import createSagaMiddleware from 'redux-saga'
import persistState, {mergePersistedState} from 'redux-localstorage'
import adapter from 'redux-localstorage/lib/adapters/localStorage'
import filter from 'redux-localstorage-filter'

import rootReducer from './reducers'
import rootSaga from './sagas'

const persistPaths = [
  'auth.username',
  'auth.refreshToken',
  'resourceOverviewScreen.autoBlinkEnabled',
  'siteViews'
]

export const configureStore = () => {
  const sagaMiddleware = createSagaMiddleware()
  const middlewares = [sagaMiddleware]

  if (process.env.NODE_ENV !== 'production' || (sessionStorage && sessionStorage.getItem('__REDUX_LOGGER'))) {
    const { createLogger } = require('redux-logger')
    const logger = createLogger({ duration: true, collapsed: true })
    middlewares.push(logger)
  }

  const composeEnhancers = (typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose
  const reducer = compose(
    mergePersistedState(R.mergeDeepRight)
  )(rootReducer)

  const storage = compose(
    filter(persistPaths)
  )(adapter(window.localStorage))

  const enhancer = composeEnhancers(
    applyMiddleware(...middlewares),
    persistState(storage, "redux")
  )

  const store = createStore(reducer, {}, enhancer)
  sagaMiddleware.run(rootSaga)

  return store
}
