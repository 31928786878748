import React from 'react'
import Heading from "./Heading"
import classnames from 'classnames'

import s from './GroupHeading.module.scss'

const GroupHeading = React.forwardRef(({ selected, label, ...props }, ref) => {
  return (
    <Heading
      {...props}
      ref={ref}
      label={<span className={s.label}>{label}</span>}
      className={classnames(s.heading, {
        [s.clickable]: !!props.onClick, [s.selected]: selected
      })}
    />
  )
})

export default GroupHeading
