import * as R from 'ramda'
import { createSelector } from "reselect"
import { siteConfigurationSelector, siteStateSelector } from "./site"

const siteConfigurationRevisionSelector = createSelector(
  [siteConfigurationSelector],
  (siteConfiguration) => siteConfiguration.revision
)

export const gatewaysSelector = createSelector(
  [siteConfigurationSelector],
  (siteConfiguration) => siteConfiguration.gateways
)
const gatewayStatesSelector = createSelector(
  [siteStateSelector, gatewaysSelector],
  (siteState, gateways) => R.pick(R.pluck('id', gateways), siteState)
)
export const gatewaySyncStatesSelector = createSelector(
  [siteConfigurationRevisionSelector, gatewayStatesSelector],
  (revision, gatewayStates) => R.map(R.propEq('revision', revision), gatewayStates),
)
export const isSyncingGatewaysSelector = createSelector(
  [gatewaySyncStatesSelector],
  (syncStates) => R.values(syncStates).some(s => !s)
)
