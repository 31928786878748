import React from "react"
import { CSSTransitionGroup } from "react-transition-group"
import s from "./TransitionGroup.module.scss"

const TransitionGroup = ({ children }) => (
  <CSSTransitionGroup
    transitionName={{
      enter: s.enter,
      enterActive: s.enterActive,
      leave: s.leave,
      leaveActive: s.leaveActive
    }}
    transitionEnterTimeout={100}
    transitionLeaveTimeout={100}
  >
    {children}
  </CSSTransitionGroup>
)

export default TransitionGroup
