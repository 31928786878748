import React, { useState } from 'react'
import classnames from 'classnames'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import s from './RoomObject.module.scss'

import SiteCatchOverviewModal from '../SiteCatchOverviewModal/SiteCatchOverviewModal'
import ResourceOverviewModal from '../ResourceOverviewModal'
import { ReactComponent as BleScannerFilledIcon } from '../../assets/ble-scanner-filled.svg'
import { ReactComponent as BleScannerFramedIcon } from '../../assets/ble-scanner-framed.svg'
import { getIconSrcForObject, getObjectDisplayText } from '../../utils'
import { useScrollIntoView } from '../../hooks'
import { siteStateSelector } from '../../selectors/site'

const RoomObject = ({ siteId, room, object, selected, onClick, bleReceiverAssigned, isOffline, state}) => {
  const [showCaptureModal, setShowCaptureModal] = useState(false)
  const [showAssignModal, setShowAssignModal] = useState(false)

  const icon = getIconSrcForObject(object)
  const assignments = object.attachedResources.length

  const ref = useScrollIntoView(selected)

  const renderAssignment = () => {
    if (assignments > 0) {
      if (object.type === 'environmentalSensor') {
        return( <span> {state[object.id] && (Math.round(state[object.id].temperature * 100) / 100 + " °C • " + Math.round(state[object.id].humidity * 100) / 100 + " %")} </span> )
      } else if (object.type === 'noiseSensor') {
        return (
          <span>
            {state[object.id] && (
              Math.round(state[object.id].average * 100) / 100 + " dB  • Min: " +
              Math.round(state[object.id].min * 100) / 100 + " dB  • Max: " +
              Math.round(state[object.id].max * 100) / 100 + " dB")
            }
          </span>
        )
      }
      return isOffline ? <span className={s.offline}>Offline</span> : null
    } else if (object.type === 'switch' || object.type === 'doorSensor' || object.type === 'environmentalSensor') {
      return (
        <div className={s.assign} onClick={()=> setShowCaptureModal(true)}>Assign</div>
      )
    } else if ( ['light', 'motionSensor', 'curtain', 'noiseSensor'].includes(object.type)) {
      return (
        <div className={s.assign} onClick={()=> setShowAssignModal(true)}>Assign</div>
      )
    } else {
      return isOffline ? <span className={s.offline}>Offline</span> : null
    }
  }

  const attachedResourcesCount = object.attachedResources.length
  const targetResourcesCount = 1

  const ScannerIcon =
    object.bleScanner
    ? BleScannerFramedIcon
    : (bleReceiverAssigned ? BleScannerFilledIcon : null)
  return (
    <div className={classnames(s.container, { [s.selected]: selected, [s.invalid]: attachedResourcesCount < targetResourcesCount })} onClick={onClick} ref={ref}>
      {ScannerIcon && <ScannerIcon className={s.ble} />}
      <img src={icon} className={s.icon} />
      {getObjectDisplayText(object)}
      <div className={s.assignments}>
        {renderAssignment()}
      </div>
      {showCaptureModal &&
        <SiteCatchOverviewModal
          siteId={siteId}
          initiallySelectedObjectId={object.id}
          spaceId={room.spaceId}
          onComplete={() => setShowCaptureModal(false)}
        />
      }
      {showAssignModal &&
        <ResourceOverviewModal
          siteId={siteId}
          spaceId={room.spaceId}
          object={object}
          onRequestClose={() => setShowAssignModal(false)}
        />
      }
    </div>
  )
}

const mapStateToProps = createStructuredSelector({
  state: siteStateSelector,
})

export default connect(mapStateToProps)(RoomObject)
