import React, { useState } from 'react'
import { createSelector, createStructuredSelector } from 'reselect'
import { connect } from 'react-redux'

import { siteObjectUpdate } from '../../actions'
import { objectsSelector, roomsSelector, isObjectJustCreatedSelector } from '../../selectors'
import Heading from '../../components/Heading'
import TextField from '../../components/TextField'
import Select from '../../components/Select'

const DoorSensorConfiguration = ({
  siteId, spaceId, objectId, object,
  disabled, onRename, dispatch, isJustCreated
}) => {

  const getSensorConfig = (config) => {
    if (config.open.spaceId)
      return 'normal'
    else
      return 'none'
  }

  const [configurationValue, setConfiguration] = useState(getSensorConfig(object.configuration))

  const handleConfigurationChange = (newValue) => {
    setConfiguration(newValue)

    const openConfiguration = newValue === 'normal' ? { action: "lightOn", spaceId } : { }
    const configuration = {
      open: openConfiguration,
      close: {
        action: "lightOff",
        objectIds: []
      }
    }

    dispatch(siteObjectUpdate(siteId, objectId, { ...object, configuration }))
  }

  return (
    <>
      <Heading label="Location" />
      <TextField
        initialValue={object.name}
        activateOnMount={isJustCreated}
        onSave={onRename}
        disabled={disabled}
        allowEmpty
      />
      <Heading label="Configuration" />
      <Select
        onSelection={handleConfigurationChange}
        value={configurationValue}
        options={[
          { label: <span><b>On open</b> All rooms: 100%</span>, value: 'normal' },
          { label: 'Do nothing', value: 'none' },
        ]}
      />
    </>
  )

}

const objectIdSelector = (state, props) => props.objectId
const objectSelector = createSelector(
  [objectIdSelector, objectsSelector],
  (objectId, objects) => objects.find(o => o.id === objectId)
)

const roomIdSelector = createSelector(
  [objectSelector],
  (object) => object.roomId
)

const roomSelector = createSelector(
  [roomIdSelector, roomsSelector],
  (roomId, rooms) => rooms.find(room => room.id === roomId)
)

const spaceIdSelector = createSelector(
  [roomSelector],
  (room) => room.spaceId
)

const mapStateToProps = createStructuredSelector({
  isJustCreated: isObjectJustCreatedSelector,
  object: objectSelector,
  spaceId: spaceIdSelector,
})

export default connect(mapStateToProps, null,  null, { forwardRef: true })(DoorSensorConfiguration)
