import immer from 'immer'

import {
  API_KEY_LIST,
  API_KEY_LIST_SUCCESS,
  API_KEY_LIST_FAILURE,
  API_KEY_CREATE_SUCCESS,
  API_KEY_UPDATE_PART,
  API_KEY_DELETE,
  API_KEY_LAST_CLEAR,
} from '../constants/actions'

const initialState = {}

const siteKeys = (state = initialState, { type, payload }) => {
  switch (type) {
    case API_KEY_LIST:
      return immer(state, draftState => {
        const { siteId } = payload
        draftState[siteId] = draftState[siteId] || { fetching: null, last: null, index: [] }
        draftState[siteId].fetching = true
      })
    case API_KEY_LIST_SUCCESS:
      return immer(state, draftState => {
        const { siteId, keyList } = payload
        draftState[siteId].fetching = false
        draftState[siteId].index = keyList
      })
    case API_KEY_LIST_FAILURE:
      return immer(state, draftState => {
        const { siteId } = payload
        draftState[siteId].fetching = false
      })
    case API_KEY_CREATE_SUCCESS:
      return immer(state, draftState => {
        const { siteId, keyId, keyBody, token } = payload
        const keyEntity = { id: keyId, ...keyBody }
        const lastKeyEntity = { ...keyEntity, token }
        draftState[siteId].index.push(keyEntity)
        draftState[siteId].last = lastKeyEntity
      })
    case API_KEY_UPDATE_PART:
      return immer(state, draftState => {
        const { siteId, keyId, keyBodyPart } = payload
        const keyEntity = draftState[siteId].index.find(k => k.id === keyId)
        Object.assign(keyEntity, keyBodyPart)
      })
    case API_KEY_DELETE:
      return immer(state, draftState => {
        const { siteId, keyId } = payload
        draftState[siteId].index = draftState[siteId].index.filter(k => k.id !== keyId)
      })
    case API_KEY_LAST_CLEAR:
      return immer(state, draftState => {
        const { siteId } = payload
        draftState[siteId].last = null
      })
    default:
      return state
  }
}

export default siteKeys
