import React, { useState } from 'react'
import { createSelector, createStructuredSelector } from 'reselect'
import { connect } from 'react-redux'
import s from './MotionSensorConfiguration.module.scss'

import { siteObjectUpdate } from '../../actions'
import { objectsSelector, isObjectJustCreatedSelector, scenesSelector, roomsSelector } from '../../selectors'
import Heading from '../../components/Heading'
import Select from '../../components/Select'
import TextField from '../../components/TextField'
import Checkbox from '../../components/Checkbox'
import { ReactComponent as BleScannerFramedIcon } from '../../assets/ble-scanner-framed.svg'

const MotionSensorConfiguration = ({
  siteId, roomId, objectId, object, scenes,
  roomName, onRename, onBleScannerToggle,
  disabled, dispatch, isJustCreated
 }) => {
  const configToValue = (config) => {
    if (config.objectIds != null && config.objectIds.length === 0)
      return 'none'
    if (config.action === 'lightOn')
      return 'room-100'
    else if (config.action === 'lightOff')
      return 'off'
    else if (config.action === 'sceneOn')
      return `scene-${config.sceneId}`
    else
      return 'none'
  }

  const getSensorQuietPeriod = (config) => {
    return config.absence.offDelay / 60 / 1000
  }

  const valueToConfig = (value) => {
    if(value === 'room-100') {
      return { roomId, action: "lightOn" }
    } else if(value === 'off') {
      return { roomId, action: "lightOff" }
    } else if(value === 'none') {
      return { objectIds: [], action: "lightOff" }
    } else if(value.startsWith("scene-")) {
      return { action: "sceneOn", sceneId: value.replace("scene-", "") }
    }
  }

  const [onMotionValue, setOnMotionValue] = useState(configToValue(object.configuration.motion))
  const [onAbsenceValue, setOnAbsenceValue] = useState(configToValue(object.configuration.absence))
  const [quietPeriodValue, setQuietPeriodValue] = useState(getSensorQuietPeriod(object.configuration))

  const syncSensorConfiguration = (newMotionValue, newAbsenceValue, newQuietPeriodValue) => {
    const configuration = {
      motion: valueToConfig(newMotionValue),
      absence: {
        ...valueToConfig(newAbsenceValue),
        offDelay: newQuietPeriodValue * 60 * 1000
      }
    }

    dispatch(siteObjectUpdate(siteId, objectId, { ...object, configuration }))
  }

  const handleOnMotionChange = (newValue) => {
    setOnMotionValue(newValue)
    syncSensorConfiguration(newValue, onAbsenceValue, quietPeriodValue)
  }

  const handleOnAbsenceChange = (newValue) => {
    setOnAbsenceValue(newValue)
    syncSensorConfiguration(onMotionValue, newValue, quietPeriodValue)
  }

  const handleQuietPeriodSave = (newValue) => {
    setQuietPeriodValue(newValue)
    syncSensorConfiguration(onMotionValue, onAbsenceValue, newValue)
  }

  return (
    <>
      <Heading label="Location" />
      <TextField
        initialValue={object.name}
        onSave={onRename}
        disabled={disabled}
        activateOnMount={isJustCreated}
        allowEmpty
      />
      <Heading label="On motion" />
      <Select
        onSelection={handleOnMotionChange}
        value={onMotionValue}
        options={[
          { label: `${roomName}: 100%`, value: 'room-100' },
          ...scenes.map(s => ({ label: s.name, value: `scene-${s.id}` })),
          { label: 'Do nothing', value: 'none' },
        ]}
      />
      <Heading label="On absence" />
      <Select
        onSelection={handleOnAbsenceChange}
        value={onAbsenceValue}
        options={[
          { label: `${roomName}: Off`, value: 'off' },
          ...scenes.map(s => ({ label: s.name, value: `scene-${s.id}` })),
          { label: 'Do nothing', value: 'none' },
        ]}
      />
      <Heading label="Quiet period" />
      <TextField
        onSave={handleQuietPeriodSave}
        initialValue={quietPeriodValue}
        disabled={disabled}
        type='number'
        suffix='min'
      />
      <Heading label={<><BleScannerFramedIcon className={s.bleScannerIcon} />BLE scanners</>} />
      <Checkbox
        className={s.bleScannerCheckbox}
        checked={object.bleScanner}
        onChange={onBleScannerToggle}
        label="The first Wirepas device assigned should act as a BLE scanner"
      />
    </>
  )

}

const objectIdSelector = (state, props) => props.objectId
const objectSelector = createSelector(
  [objectIdSelector, objectsSelector],
  (objectId, objects) => objects.find(o => o.id === objectId)
)
const roomIdSelector = createSelector([objectSelector], (object) => object.roomId)
const roomSelector = createSelector(
  [roomIdSelector, roomsSelector],
  (roomId, rooms) => rooms.find(r => r.id === roomId)
)

const spaceIdSelector = createSelector([roomSelector], room => room.spaceId)

const spaceScenesSelector = createSelector(
  [scenesSelector, spaceIdSelector],
  (scenes, spaceId) => scenes.filter(scene => scene.spaceId === spaceId)
)

const mapStateToProps = createStructuredSelector({
  isJustCreated: isObjectJustCreatedSelector,
  object: objectSelector,
  roomId: roomIdSelector,
  scenes: spaceScenesSelector
})

export default connect(mapStateToProps, null,  null, { forwardRef: true })(MotionSensorConfiguration)
