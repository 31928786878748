import immer from 'immer'

import { SOCKET_CONNECT, SOCKET_DISCONNECT } from '../constants/actions'

const initialState = {
  online: false,
}

const socket = (state = initialState, { type, payload }) => {
  switch (type) {
    case SOCKET_CONNECT:
      return immer(state, draftState => {
        draftState.online = true
      })
    case SOCKET_DISCONNECT:
      return immer(state, draftState => {
        draftState.online = false
        draftState.ready = false
      })
    default:
      return state
  }
}

export default socket
