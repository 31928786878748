import React, { useState } from 'react'
import cn from 'classnames'

import Modal from '../../components/Modal'
import s from './AvailableMaterialsModal.module.scss'
import InlineInput from '../../components/InlineInput'
import { materialsSort } from '../../utils/sort'
import { useEventListener } from '../../hooks'

const handleMaterialDataLinkClick = (e, url) => {
  e.preventDefault()
  e.stopPropagation()

  url && window.open(url)
}

const isMatch = (source, search) => {
  const sanitizedSource = source.toLowerCase().trim()
  const sanitizedSearch = search.toLowerCase().trim()

  // try simple match first
  if(sanitizedSource.includes(sanitizedSearch)) return true

  const sourceWords = sanitizedSource.replace(/-/g, "").split(" ")
  const searchWords = sanitizedSearch.replace(/-/g, "").split(" ")
  return searchWords
    .every(searchWord => sourceWords.some(sourceWord => sourceWord.includes(searchWord)))
}

const filterMaterials = (materials, search) => {
  if(search.trim().length === 0) return materials
  return materials.filter(({ name }) => isMatch(name, search))
}

const AvailableMaterialsModal = ({
  materials,
  addedMaterialIds,
  space,
  room,
  object,
  onComplete,
  onAdd
}) => {
  const [search, setSearch] = useState("")
  const filteredMaterials = filterMaterials(materials, search)
  const sortedMaterials = materialsSort(filteredMaterials)

  useEventListener('keydown', e => {
    if(e.key === 'Escape') {
      onComplete && onComplete()
    }
  })

  return (
    <Modal onRequestClose={onComplete}>
      <div className={s.content}>
        <div className={s.titleRow}>
          <div className={s.title}>
            Add physical device
          </div>
          <div className={s.controls}>
            {[space.name, room.name, object.name].join(' · ')}
          </div>
        </div>
        <div className={s.materialsList}>
          <InlineInput
            className={s.search}
            placeholder="Search by device name"
            value={search}
            onValueChange={setSearch}
          />
          {sortedMaterials.map(material => {
            const isAdded = addedMaterialIds.includes(material.id)
            return (
              <div
                className={cn(s.item, { [s.disabled]: isAdded })}
                key={material.id}
                onClick={isAdded ? null : (() => onAdd(material.id))}
              >
                <div className={s.column}>
                  <div>
                    <span className={s.attachedResourceName}>{material.name}</span>
                    <br />
                    <span
                      className={cn(s.materialDataLink, { [s.disabled]: !material.url })}
                      onClick={(e) => handleMaterialDataLinkClick(e, material.url)}
                    >
                      Datasheet
                    </span>
                  </div>
                </div>
                <div className={s.column}>
                  <div className={cn(s.actions, { [s.disabled]: isAdded })}>
                    <span className={s.action}>
                      {isAdded ? 'Added' : 'Add'}
                    </span>
                  </div>
                </div>
              </div >
            )
          })}
        </div>
      </div>
    </Modal>
  )
}

export default AvailableMaterialsModal
