import React from 'react'
import SocketClient from '@mountkelvin/websocket-client'

const sum = xs => xs.reduce((total, x) => total + x, 0)
const avg = xs => sum(xs) / Math.max(xs.length, 1)

const groupBy = (arr, key) => {
  const res = {}
  arr.forEach(elem => {
    if (!(elem[key] in res)) {
      res[elem[key]] = []
    }
    res[elem[key]].push(elem)
  })
  return res
}

const mapObj = (obj, fn) => {
  const res = {}
  Object.entries(obj).forEach(([key, value]) => {
    res[key] = fn(value)
  })
  return res
}

const formatAddress = (addr) =>
  typeof addr === "number"
    ? addr.toString(16).padStart(8, '0')
    : addr

export const createProximityScanner = (url, timeout = 30000) => {

  let signals = []
  let lastSignalTime = Date.now()
  const addSignal = (address, rssi) => {
    const signalTime = Date.now()
    signals.push({ address, rssi, time: signalTime })
    lastSignalTime = signalTime
  }

  const removeExpiredSignals = () => {
    const expireTime = Date.now() - timeout
    signals = signals.filter(s => s.time > expireTime)
  }

  const getProximities = () => {
    removeExpiredSignals()
    const sigByAddr = groupBy(signals, 'address')
    return mapObj(sigByAddr, sigs => avg(sigs.map(s => s.rssi)))
  }

  const isDead = () => Date.now() - lastSignalTime > 3000

  const ws = new SocketClient(url)
  ws.method('rssi', data => {
    const addr = formatAddress(data.resourceAddress)
    addSignal(addr, data.rssi)
  })

  const expirationIntervalHandle = setInterval(removeExpiredSignals, timeout)
  const destroy = () => {
    ws.destroy()
    clearInterval(expirationIntervalHandle)
  }

  return { getProximities, isDead, destroy }
}

export const ProximityScannerContext = React.createContext()
