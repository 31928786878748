import * as R from 'ramda'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector, createSelector } from 'reselect'

import { siteConfigurationSelector, matchSiteIdSelector } from '../selectors'
import SectionBlock from '../components/SectionBlock'
import SiteConfigurationSectionContainer from '../containers/SiteConfigurationSectionContainer'
import FloorSectionContainer from '../containers/FloorSectionContainer'
import SpaceSectionContainer from '../containers/SpaceSectionContainer'
import RoomSectionContainer from '../containers/RoomSectionContainer'
import ObjectSectionContainer from '../containers/ObjectSectionContainer'
import { siteViewSelector } from '../selectors/siteView'
import SceneSectionContainer from './SceneSectionContainer'

class SiteOverviewScreen extends Component {
  componentDidMount() {
    const { history, siteId } = this.props

    // TODO: at some point in the future, make SiteOverviewScreen
    // render only when url is precisely `/sites/${siteId}`.
    // But for now, this helps if people open up the site
    // from for example old urls that are autocompleted by browser
    history.replace(`/sites/${siteId}`)
  }

  static getDerivedStateFromProps(props) {
    // update parents if anything is selected
    if (props.floor) {
      return {
        parentSpaceId: props.room ? props.room.spaceId : null,
        parentFloorId: props.space ? props.space.floorId : null,
      }
    }
    // clear parents if no match
    if (props.spaceId && props.space == null) {
      return { parentSpaceId: null }
    }
    if (props.floorId && props.floor == null) {
      return { parentFloorId: null }
    }
    return null
  }

  state = {}

  render() {
    const { siteId, floor, space, room, object, scene } = this.props

    const floorId = floor ? floor.id : null
    const spaceId = space ? space.id : null
    const roomId = room ? room.id : null
    const objectId = object ? object.id : null
    const sceneId = scene ? scene.id : null

    return (
      <SectionBlock key={siteId}>
        <SiteConfigurationSectionContainer
          key={siteId}
          siteId={siteId}
          selectedFloorId={floorId}
        />
        {floorId &&
          <FloorSectionContainer
            key={floorId}
            siteId={siteId}
            floorId={floorId}
            selectedSpaceId={spaceId}
          />
        }
        {spaceId &&
          <SpaceSectionContainer
            key={spaceId}
            siteId={siteId}
            spaceId={spaceId}
            selectedRoomId={roomId}
            selectedObjectId={objectId}
            selectedSceneId={sceneId}
          />
        }
        {roomId &&
          <RoomSectionContainer
            key={roomId}
            siteId={siteId}
            roomId={roomId}
            spaceId={spaceId}
          />
        }
        {objectId &&
          <ObjectSectionContainer
            key={objectId}
            siteId={siteId}
            spaceId={spaceId}
            objectId={objectId}
          />
        }
         {sceneId &&
          <SceneSectionContainer
            key={sceneId}
            siteId={siteId}
            spaceId={spaceId}
            sceneId={sceneId}
          />
        }
      </SectionBlock>
    )
  }
}

const stateFloorIdSelector = createSelector([siteViewSelector], siteView => siteView.floorId)
const stateSpaceIdSelector = createSelector([siteViewSelector], siteView => siteView.spaceId)
const stateRoomIdSelector = createSelector([siteViewSelector], siteView => siteView.roomId)
const stateObjectIdSelector = createSelector([siteViewSelector], siteView => siteView.objectId)
const stateSceneIdSelector = createSelector([siteViewSelector], siteView => siteView.sceneId)

const stateFloorSelector = createSelector(
  [siteConfigurationSelector, stateFloorIdSelector],
  (siteConfiguration, floorId) => floorId && R.find(R.propEq('id', floorId), siteConfiguration.floors)
)
const stateSpaceSelector = createSelector(
  [siteConfigurationSelector, stateSpaceIdSelector],
  (siteConfiguration, spaceId) => spaceId && R.find(R.propEq('id', spaceId), siteConfiguration.spaces)
)
const stateRoomSelector = createSelector(
  [siteConfigurationSelector, stateRoomIdSelector],
  (siteConfiguration, roomId) => roomId && R.find(R.propEq('id', roomId), siteConfiguration.rooms)
)
const stateObjectSelector = createSelector(
  [siteConfigurationSelector, stateObjectIdSelector],
  (siteConfiguration, objectId) => objectId && R.find(R.propEq('id', objectId), siteConfiguration.objects)
)
const stateSceneSelector = createSelector(
  [siteConfigurationSelector, stateSceneIdSelector],
  (siteConfiguration, sceneId) => sceneId && R.find(R.propEq('id', sceneId), siteConfiguration.scenes)
)


const mapStateToProps = createStructuredSelector({
  siteId: matchSiteIdSelector,
  siteConfiguration: siteConfigurationSelector,
  floor: stateFloorSelector,
  space: stateSpaceSelector,
  room: stateRoomSelector,
  object: stateObjectSelector,
  scene: stateSceneSelector
})

export default connect(mapStateToProps)(SiteOverviewScreen)
