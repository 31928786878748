import React from 'react'
import { useHistory } from 'react-router-dom'
import cn from 'classnames'

import s from './Link.module.scss'

const Link = ({ to, replace, className, ...props }) => {
  const history = useHistory()

  const handleClick = () => {
    if (replace) {
      history.replace(to)
    } else {
      history.push(to)
    }
  }

  return (
    <div className={cn(s.link, className)} {...props} onClick={handleClick} />
  )
}

export default Link
