import * as R from 'ramda'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector, createSelector } from 'reselect'
import { v4 as uuidv4 } from 'uuid'


import s from './SpaceSectionContainer.module.scss'
import { siteConfigurationSelector, objectsWithLocationSelector, siteStateSelector } from '../../selectors'
import {
  siteSpaceUpdate,
  siteSpaceDelete,
  siteRoomCreate,
  siteSpaceResourceReset,
  siteSpaceUpdatePart,
  updateSiteViewParams,
  siteSceneCreate
} from '../../actions/site'
import Section from '../../components/Section'
import TextField from '../../components/TextField'

import ListItemPlaceholder from '../../components/ListItemPlaceholder'
import Room from './Room'
import PopupMenu from '../../components/PopupMenu'
import InlineInput from '../../components/InlineInput'
import { ReactComponent as ThreeDotIcon } from '../../assets/three-dot.svg'
import { roomsSort } from '../../utils/sort'
import { objectApply } from '../../actions'
import Heading from '../../components/Heading'
import Scene from './Scene'

const spaceTemplateLabel = space => space.template || space.name
const isLogicalConfigurationEditable = space => !space.linkedSpaceId

const siteStateToSceneState = siteState => {
  if(!siteState) return { on: true, bri: 255 }
  if(!siteState.on || siteState.bri === 0) return { on: false }

  return { on: true, bri: siteState.bri }
}

class SpaceSectionContainer extends Component {
  state = { editTemplateValue: null, activeToolbarRoomId: null }

  getThreeDotMenuOptionGroups() {
    const { space, spacesLinked } = this.props
    const spaceActionsDisabled = !isLogicalConfigurationEditable(space)
    return [
      [
        { label: "+ Room", onClick: this.handleRoomCreatePress, disabled: spaceActionsDisabled}
      ],
      [
        { label: "Turn all space devices on", onClick: this.handleTurnWirepasDevicesOn },
        { label: "Turn all space devices off", onClick: this.handleTurnWirepasDevicesOff },
      ],
      [
        { label: "Delete this space", danger: true, disabled: spacesLinked.length > 0, onClick: this.deleteSpace },
        { label: "Unassign all physical devices from this space", danger: true, onClick: this.handleSpaceUnassign }
      ]
    ]
  }

  handleTurnWirepasDevicesOn = () => {
    const { dispatch, siteId, spaceId } = this.props
    dispatch(objectApply(siteId, { spaceId }, 'lightSet', {on: true, bri: 255}))
  }

  handleTurnWirepasDevicesOff = () => {
    const { dispatch, siteId, spaceId } = this.props
    dispatch(objectApply(siteId, { spaceId }, 'lightSet', {on: false}))
  }

  handleRoomCreatePress = () => {
    const { siteId, spaceId, rooms, dispatch } = this.props
    const name = `Room #${rooms.length + 1}`
    const id = uuidv4()
    dispatch(siteRoomCreate(siteId, { id, spaceId, name }))
    dispatch(updateSiteViewParams(siteId, { roomId: id }))
  }

  handleSceneCreatePress = () => {
    const { siteId, spaceId, scenes, objects, siteState, dispatch } = this.props
    const name = `Scene #${scenes.length + 1}`
    const id = uuidv4()
    const applyList = objects
      .filter(o => o.type === 'light')
      .map(o => ({
        objectId: o.id,
        state: siteStateToSceneState(siteState[o.id])
      }))
    dispatch(siteSceneCreate(siteId, { id, spaceId, name, applyList }))
    dispatch(updateSiteViewParams(siteId, { sceneId: id }))
  }

  handleSpaceUnassign = () => {
    const { siteId, spaceId, dispatch } = this.props
    dispatch(siteSpaceResourceReset(siteId, spaceId))
  }

  handleSpaceUnlink = () => {
    const { siteId, spaceId, dispatch } = this.props
    dispatch(siteSpaceUpdatePart(siteId, spaceId, { linkedSpaceId: null }))
  }

  handleSpaceLink = (templateSpaceId) => {
    const { siteId, spaceId, dispatch } = this.props
    dispatch(siteSpaceUpdatePart(siteId, spaceId, { linkedSpaceId: templateSpaceId }))
  }

  handleCreateOrRenameTemplate = () => {
    const { space } = this.props
    this.setState({ editTemplateValue: space.template || "" })
  }

  handleEditTemplateValueChange = value => {
    this.setState({ editTemplateValue: value })
  }

  handleEditTemplateBlur = () => {
    this.setState({ editTemplateValue: null })
  }

  handleEditTemplateEnter = () => {
    const { siteId, space, dispatch } = this.props
    const { editTemplateValue } = this.state

    if(editTemplateValue.trim().length !== 0) {
      dispatch(siteSpaceUpdate(siteId, space.id, { ...space, template: editTemplateValue }))
    }

    this.setState({ editTemplateValue: null })
  }

  handleSceneClick = (scene) => {
    const { siteId, dispatch } = this.props
    dispatch(updateSiteViewParams(siteId, { sceneId: scene.id }))
  }

  handleTemplateClick = () => {
    const { dispatch, siteId, linkedSpace } = this.props
    dispatch(updateSiteViewParams(siteId, { floorId: linkedSpace.floorId, spaceId: linkedSpace.id }))
  }

  renderLinkedStatus() {
    const { space, spacesLinked, linkedSpace, templates } = this.props

    if(space.template || spacesLinked.length > 0) {
      return <span className={s.templateName} onClick={this.handleCreateOrRenameTemplate}>{spaceTemplateLabel(space)}</span>
    } else if (linkedSpace == null) {
      return (
        <span className={s.templateUnlinked}>
          This space is not linked to any template
          <div className={s.templateLinks}>
            <PopupMenu optionGroups={[templates.map(space => ({ label: spaceTemplateLabel(space), onClick: () => this.handleSpaceLink(space.id) }))]}>
              <span className={`${s.action} ${s.templateLink}`}>
                Link
              </span>
            </PopupMenu>
            <span className={`${s.action} ${s.templateLink}`} onClick={this.handleCreateOrRenameTemplate}>
              Create a template
            </span>
          </div>
        </span>
      )
    }

    return (
      <span>
        →{" "}
        <span className={s.linkedTemplateName} onClick={this.handleTemplateClick}>
          {spaceTemplateLabel(linkedSpace)}
        </span>
        <span className={s.templateLinks}>
          <span className={`${s.action} ${s.templateLink}`} onClick={this.handleSpaceUnlink}>
            Unlink
          </span>
        </span>
      </span>
    )
  }

  renameSpace = (name) => {
    const { siteId, space, dispatch } = this.props
    dispatch(siteSpaceUpdate(siteId, space.id, { ...space, name }))
  }

  deleteSpace = () => {
    const { siteId, space, dispatch } = this.props
    dispatch(siteSpaceDelete(siteId, space.id))
  }

  render() {
    const { siteId, rooms, scenes, space, selectedRoomId, selectedObjectId, selectedSceneId } = this.props
    const { editTemplateValue, activeToolbarRoomId } = this.state
    const spaceActionsDisabled = !isLogicalConfigurationEditable(space)

    return (
      <Section width="415px">
        <div className={s.sticky}>
          {editTemplateValue !== null
            ? <InlineInput
                className={s.editTemplate}
                value={editTemplateValue}
                onValueChange={this.handleEditTemplateValueChange}
                onBlur={this.handleEditTemplateBlur}
                onEnter={this.handleEditTemplateEnter}
                placeholder="Template name"
              />
            : (
              <div className={s.row}>
                {this.renderLinkedStatus()}
                <PopupMenu className={s.threeDotPopup} align="topRight" optionGroups={this.getThreeDotMenuOptionGroups()}>
                  <ThreeDotIcon className={s.threeDotIcon} />
                </PopupMenu>
              </div>
            )}
          <TextField
            initialValue={space.name}
            onSave={this.renameSpace}
            className={s.spaceTitle}
          />
        </div>
        {rooms.map(room => {
          return (
            <Room
              key={room.id}
              siteId={siteId}
              room={room}
              selected={selectedRoomId === room.id}
              selectedObjectId={selectedObjectId}
              isToolbarActive={room.id === activeToolbarRoomId}
              disabled={spaceActionsDisabled}
              onOpenToolbar={() => this.setState({ activeToolbarRoomId: room.id })}
              onCloseToolbar={() => this.setState({ activeToolbarRoomId: null }) }
            />
          )
        })}
        {rooms.length === 0 &&
          <ListItemPlaceholder label={"No rooms"} />
        }
       <Heading label="Scenes" actions={spaceActionsDisabled ? [] : [{ label: "Add", onClick: this.handleSceneCreatePress }]} />
       {scenes.map(scene =>  (
          <Scene
            key={scene.id}
            scene={scene}
            selected={scene.id === selectedSceneId}
            onSelect={() => this.handleSceneClick(scene)}
          />
       ))}
        {scenes.length === 0 &&
          <ListItemPlaceholder label={"No scenes"} />
        }
      </Section>
    )
  }
}

const roomsSelector = createSelector(
  [siteConfigurationSelector],
  (siteConfiguration) => siteConfiguration.rooms || []
)
const sortedRoomsSelector = createSelector(
  [roomsSelector],
  (rooms) => roomsSort(rooms)
)
const spacesSelector = createSelector(
  [siteConfigurationSelector],
  (siteConfiguration) => siteConfiguration.spaces
)
const templateSpacesSelector = createSelector(
  [spacesSelector],
  (spaces) => spaces.filter(({ id, template }) => Boolean(template) || spaces.some(s => s.linkedSpaceId === id))
)

const spaceIdSelector = (state, props) => props.spaceId
const sortedSpaceRoomsSelector = createSelector(
  [spaceIdSelector, sortedRoomsSelector],
  (spaceId, rooms) => rooms.filter(r => r.spaceId === spaceId)
)

const spaceSelector = createSelector(
  [spaceIdSelector, spacesSelector],
  (spaceId, spaces) => R.find(R.propEq('id', spaceId), spaces)
)
const linkedSpaceSelector = createSelector(
  [spaceSelector, spacesSelector],
  (space, spaces) => space.linkedSpaceId == null ? null : spaces.find(s => s.id === space.linkedSpaceId)
)

const spacesLinkedSelector = createSelector(
  [spaceSelector, spacesSelector],
  (space, spaces) => spaces.filter(s => s.linkedSpaceId === space.id)
)

const scenesSelector = createSelector(
  [siteConfigurationSelector, spaceIdSelector],
  (siteConfiguration, spaceId) => siteConfiguration.scenes.filter(s => s.spaceId ===  spaceId)
)

const spaceObjectsSelector = createSelector(
  [objectsWithLocationSelector, spaceIdSelector],
  (objects, spaceId) => objects.filter(o => o.spaceId === spaceId)
)

const mapStateToProps = createStructuredSelector({
  objects: spaceObjectsSelector,
  siteState: siteStateSelector,
  rooms: sortedSpaceRoomsSelector,
  space: spaceSelector,
  scenes: scenesSelector,
  templates: templateSpacesSelector,
  linkedSpace: linkedSpaceSelector,
  spacesLinked: spacesLinkedSelector
})

export default connect(mapStateToProps)(SpaceSectionContainer)
