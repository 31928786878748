import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import cn from 'classnames'
import { createSelector, createStructuredSelector } from 'reselect'

import s from './SiteCatchOverviewModal.module.scss'
import {
  siteObjectAssignRawResource,
  siteObjectUnassignResource,
  inputCaptureCancel,
  inputCaptureStart,
  inputCaptureStop
} from '../../actions/site'
import ObjectPathNameContainer from '../ObjectPathNameContainer'
import Modal from '../../components/Modal'
import Heading from '../../components/Heading'
import { indexedSpacesSelector, roomsSelector, objectsSelector } from '../../selectors'
import Room from './Room'
import { useEventListener } from '../../hooks'
import { objectsSort, roomsSort } from '../../utils/sort'

const SiteCatchOverviewModal = ({
  inputCapture, initiallySelectedObjectId, objects,
  dispatch, siteId, space, rooms, onComplete
}) => {
  const [selectedObjectId, setSelectedObjectId] = useState(initiallySelectedObjectId)
  const { error, existingObjectId, existingResourceId, progress = 0, resource } = inputCapture || {}

  const selectedObject = objects.find(({ id }) => id === selectedObjectId)

  useEffect(() => {
    if(resource && selectedObjectId) {
      dispatch(siteObjectAssignRawResource(siteId, selectedObjectId, resource))

      const unassignedObjects = objects.filter(o => o.attachedResources.length === 0)
      const selectedIndex = unassignedObjects.indexOf(selectedObject)
      if(selectedIndex === -1 || unassignedObjects.length === 1) {
        setSelectedObjectId(null)
      } else {
        setSelectedObjectId(unassignedObjects[(selectedIndex + 1) % unassignedObjects.length].id)
      }
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resource])

  useEffect(() => {
    if(selectedObject) {
      dispatch(inputCaptureStart(siteId, { type: selectedObject.type }))
      return () => dispatch(inputCaptureCancel())
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedObjectId])

  useEffect(() => {
    if(progress === 3) {
      dispatch(inputCaptureStop())
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [progress])


  useEventListener('keydown', (ev) => {
    const key = ev.key.toLowerCase()
    if(ev.ctrlKey || ev.metaKey) return

    const unassignedObjects = objects.filter(o => o.attachedResources.length === 0)
    const selectedIndex = unassignedObjects.indexOf(selectedObject)
    if(selectedIndex === -1) return

    switch(key) {
      case 'arrowup':
        setSelectedObjectId(unassignedObjects[(selectedIndex - 1 + unassignedObjects.length) % unassignedObjects.length].id)
        break
      case 'arrowdown':
        setSelectedObjectId(unassignedObjects[(selectedIndex + 1) % unassignedObjects.length].id)
        break

      default:
        return
    }

    ev.preventDefault()
    ev.stopPropagation()
  })

  const handleUnassignExisting = () => {
    dispatch(siteObjectUnassignResource(siteId, existingObjectId, existingResourceId))
    handleReset()
  }

  const handleObjectSelect = object => {
    if(object.attachedResources.length === 0) {
      setSelectedObjectId(object.id)
    }
  }

  const handleReset = () => {
    dispatch(inputCaptureCancel())
    dispatch(inputCaptureStart(siteId, { type: selectedObject.type }))
  }

  return (
    <Modal onRequestClose={onComplete}>
      <div className={s.content}>
        <Heading label={`Switches, door sensors, and environmental sensors in ${space.name}`} border />
        <div className={s.rooms}>
          {rooms.map(r => (
            <Room
              key={r.id}
              siteId={siteId}
              room={r}
              objects={objects.filter(o => o.roomId === r.id)}
              inputCaptureProgress={progress}
              selectedObjectId={selectedObjectId}
              onObjectSelected={handleObjectSelect}
            />
          ))}
        </div>
      </div>
      <div className={s.contentPadding} />
      {error === 'attached-candidate' &&
        <div className={cn(s.notification, s.attachedCandidate)}>
          <div className={s.warningText}>
            ⚠️
            {' '}
            {selectedObject.type === 'switch'
              ? 'This switch is already assigned to'
              : selectedObject.type === 'doorSensor'
              ? 'This door sensor is already assigned to'
              : selectedObject.type === 'environmentalSensor'
              ? 'This envirnomental sensor is already assigned to'
              : 'Unknown object type'
            }
            {' '}
            <span className={s.warningTextObjectName}>
              <ObjectPathNameContainer siteId={siteId} objectId={existingObjectId} />
            </span>
          </div>
          <div className={s.modalLinkBlock}>
            <div className={s.modalLink} onClick={handleUnassignExisting}>Unassign</div>
            <div className={s.modalLinkSeparator}>{' · '}</div>
            <div className={s.modalLink} onClick={handleReset}>Dismiss</div>
          </div>
        </div>
      }
      {error === 'multiple-matching-candidates' &&
        <div className={cn(s.notification, s.multipleMatchingCandidates)}>
          {selectedObject.type === 'switch'
            ? 'Multiple switches were pressed at the same time. Please retry.'
            : selectedObject.type === 'doorSensor'
            ? 'Multiple doors were opened at the same time. Please retry.'
            : selectedObject.type === 'environmentalSensor'
            ? 'Multiple sensors were activated at the same time. Please retry.'
          : 'Multiple devices were triggered at the same time. Please retry.'}
        </div>
      }
    </Modal>
  )
}

const spaceIdSelector = (state, props) => props.spaceId
const spaceSelector = createSelector(
  [indexedSpacesSelector, spaceIdSelector],
  (spaces, spaceId) => spaces[spaceId]
)

const spaceRoomsSelector = createSelector(
  [roomsSelector, spaceIdSelector],
  (rooms, spaceId) => roomsSort(rooms.filter(r => r.spaceId === spaceId))
)

const sortedObjectsSelector = createSelector(
  [objectsSelector, spaceRoomsSelector],
  (objects, rooms) => rooms.flatMap(r => objectsSort(objects.filter(o => o.roomId === r.id)))
)

const inputCaptureSelector = state => state.inputCapture

const isBleDevice = object => object.type === 'switch' || object.type === 'doorSensor' || object.type === 'environmentalSensor'
const spaceBleObjectsSelector = createSelector(
  [sortedObjectsSelector],
  (objects) => objects.filter(isBleDevice)
)

const mapStateToProps = createStructuredSelector({
  inputCapture: inputCaptureSelector,
  objects: spaceBleObjectsSelector,
  rooms: spaceRoomsSelector,
  space: spaceSelector,
})

export default connect(mapStateToProps)(SiteCatchOverviewModal)
