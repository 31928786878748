import React from 'react'
import { createSelector, createStructuredSelector } from 'reselect'
import { connect } from 'react-redux'

import { objectsSelector, roomsSelector, isObjectJustCreatedSelector } from '../../selectors'
import Heading from '../../components/Heading'
import TextField from '../../components/TextField'

const EnvironmentalSensorConfiguration = ({ object, disabled, onRename, isJustCreated }) => {

  return (
    <>
      <Heading label="Location" />
      <TextField
        initialValue={object.name}
        activateOnMount={isJustCreated}
        onSave={onRename}
        disabled={disabled}
        allowEmpty
      />
    </>
  )
}

const objectIdSelector = (state, props) => props.objectId
const objectSelector = createSelector(
  [objectIdSelector, objectsSelector],
  (objectId, objects) => objects.find(o => o.id === objectId)
)

const roomIdSelector = createSelector(
  [objectSelector],
  (object) => object.roomId
)

const roomSelector = createSelector(
  [roomIdSelector, roomsSelector],
  (roomId, rooms) => rooms.find(room => room.id === roomId)
)

const spaceIdSelector = createSelector(
  [roomSelector],
  (room) => room.spaceId
)

const mapStateToProps = createStructuredSelector({
  isJustCreated: isObjectJustCreatedSelector,
  object: objectSelector,
  spaceId: spaceIdSelector,
})

export default connect(mapStateToProps, null,  null, { forwardRef: true })(EnvironmentalSensorConfiguration)
