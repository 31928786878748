import React, { useState, useEffect } from 'react'
import classnames from 'classnames'
import { connect } from 'react-redux'
import Tooltip from 'react-tooltip-lite'
import * as R from 'ramda'
import { createSelector, createStructuredSelector } from 'reselect'
import { siteConfigurationSelector } from '../../selectors'

import { siteObjectUnassignResource } from '../../actions/site'
import { blinkOnce } from '../../actions'

import s from './ObjectItem.module.scss'

import LigIcon from '../../assets/lig.png'
import Sw1Icon from '../../assets/sw1.png'
import Sw2Icon from '../../assets/sw2.png'
import MotIcon from '../../assets/mot.png'
import DooIcon from '../../assets/doo.png'
import CurIcon from '../../assets/cur.png'
import EnvIcon from '../../assets/env.png'
import NoiIcon from '../../assets/noi.png'
import { getObjectDisplayText } from '../../utils'

// const renderSwitchLabel = switchObject => {
//   const getSideLightText = side => {
//     const { objects } = this.props;
//
//     if (switchObject.configuration[side].roomId) {
//       return ", All in room";
//     } else if (switchObject.configuration[side].spaceId) {
//       return ", All in space";
//     } else {
//       const objectIds = switchObject.configuration[side].objectIds;
//       if (!objectIds || objectIds.length === 0) {
//         return "";
//       }
//
//       return (
//         ", " +
//         objects
//           .filter(light => objectIds.includes(light.id))
//           .map(light => light.name)
//           .join(", ")
//       );
//     }
//   };
//
//   const getSideActionText = side => {
//     switch (switchObject.configuration[side].action) {
//       case "lightPushDim":
//         return "Pushdim";
//       case "lightOnOff":
//         return "Onoff";
//       default:
//         return "";
//     }
//   };
//
//   const getSceneText = (hside, vside) => {
//     const action = switchObject.configuration[hside][vside];
//
//     let name;
//     if (!action.state.on) name = "All off";
//     else if (action.state.bri === 38) name = "15%";
//     else if (action.state.bri === 178) name = "70%";
//     else if (action.state.bri === 255) name = "All on";
//
//     if (action.spaceId) return `${name} (All rooms)`;
//     else return name;
//   };
//
//   const variantText = switchObject.configuration.right.top
//     ? "4 scenes"
//     : switchObject.variant;
//
//   return (
//     <>
//       <span>
//         {switchObject.name}, {variantText}
//       </span>
//       {switchObject.variant === "1-key" && (
//         <span>
//           {getSideActionText("right")}
//           {getSideLightText("right")}
//         </span>
//       )}
//       {switchObject.variant === "2-key" &&
//         !switchObject.configuration.right.top && (
//           <>
//             <span>
//               Left: {getSideActionText("left")}
//               {getSideLightText("left")}
//             </span>
//             <span>
//               Right: {getSideActionText("right")}
//               {getSideLightText("right")}
//             </span>
//           </>
//         )}
//       {switchObject.variant === "2-key" &&
//         switchObject.configuration.right.top && (
//           <span>
//             {getSceneText("left", "top")}, {getSceneText("right", "top")},{" "}
//             {getSceneText("right", "bottom")}, {getSceneText("left", "bottom")}
//           </span>
//         )}
//     </>
//   );
// };

const getIcon = obj =>
  obj.type === "light"
    ? LigIcon
    : obj.type === "switch"
    ? obj.variant==="1-key"
      ? Sw1Icon
      : Sw2Icon
    : obj.type === "motionSensor"
    ? MotIcon
    : obj.type === "doorSensor"
    ? DooIcon
    : obj.type === "curtain"
    ? CurIcon
    : obj.type === "environmentalSensor"
    ? EnvIcon
    : obj.type === "noiseSensor"
    ? NoiIcon
    : "???"


const ObjectItem = ({ siteId, object, selected, onClick, attachedResources, dispatch, disabled, lastAssignedResourceId }) => {
  const [showAllResources, setShowAllResources] = useState(false)
  const [blinkingResourceIds, setBlinkingResourceIds] = useState([])
  const icon = getIcon(object)
  const missingAssignments = 1 - object.attachedResources.length

  const handleRemoveClick = (e, resourceId) => {
    dispatch(siteObjectUnassignResource(siteId, object.id, resourceId))

    e.preventDefault()
    e.stopPropagation()
  }

  const handleResourceClick = (e, resourceId) => {
    dispatch(blinkOnce(siteId, resourceId))
    setBlinkingResourceIds([ ...blinkingResourceIds, { resourceId, time: Date.now() }])

    e.preventDefault()
    e.stopPropagation()
  }

  useEffect(() => {
    const BLINKING_TIME = 10 * 1000
    if(blinkingResourceIds.length === 0) {
      return
    }

    const timeUntilNextExpire = R.reduce(
      R.min,
      BLINKING_TIME,
      blinkingResourceIds.map(({ time }) => BLINKING_TIME - (Date.now() - time))
    )
    const handle = setTimeout(
      () => setBlinkingResourceIds(blinkingResourceIds.filter(({ time }) => Date.now() - time <= BLINKING_TIME)),
      timeUntilNextExpire
    )

    return () => clearTimeout(handle)
  }, [blinkingResourceIds])

  useEffect(() => {
    if(!showAllResources) {
      const lastAssignedIndex = attachedResources.findIndex(({ id }) => id === lastAssignedResourceId)
      if (lastAssignedIndex >= 2) {
        setShowAllResources(true)
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [attachedResources, lastAssignedResourceId])

  return (
    <div onClick={disabled ? undefined : onClick} className={classnames(s.container, {
      [s.selected]: selected,
      [s.disabled]: disabled
    })}>
      <img src={icon} className={s.icon} />
      {getObjectDisplayText(object)}
      <div className={s.assignments}>
        {missingAssignments > 0 && (
          <div className={s.warning}>
            ⚠️&nbsp;&nbsp;{missingAssignments} ass. missing
          </div>
        )}
        {R.take(showAllResources ? attachedResources.length : 2, attachedResources).map(res => {
          const isBlinking = blinkingResourceIds.some(({ resourceId }) => resourceId === res.id)
          const isLastAssigned = lastAssignedResourceId === res.id
          return (
            <div className={s.device} key={res.id}>
              {isLastAssigned && <span className={s.notice}>Last assigned&nbsp;&nbsp;&nbsp;</span>}
              {isBlinking && <span className={s.notice}>Blinking&nbsp;&nbsp;&nbsp;</span>}
              <Tooltip
                content="Click to blink"
                className={s.tooltipWrapper}
                arrowSize={5}
              >
                <div
                  onClick={e => handleResourceClick(e, res.id)}
                >
                  {res.model || 'Device'}
                </div>
              </Tooltip>
              {" "}<span className={s.remove} onClick={e => handleRemoveClick(e, res.id)}>🅧</span>
            </div>
          )
        })}
        {attachedResources.length > 2 && (
          <span className={s.action} onClick={e => {
            setShowAllResources(!showAllResources)

            e.stopPropagation()
            e.preventDefault()
          }}>
            {showAllResources ? "Show less" :  `... and ${attachedResources.length - 2} more`}
          </span>
        )}
      </div>
    </div>
  )
}

const attachedResourceIdsSelector = (state, props) => props.object.attachedResources || []
const attachedResourcesSelector = createSelector(
  [siteConfigurationSelector, attachedResourceIdsSelector],
  (siteConfiguration, ids) => R.reverse(siteConfiguration.resources.filter(r => ids.indexOf(r.id) >= 0))
)

const mapStateToProps = createStructuredSelector({
  attachedResources: attachedResourcesSelector
})

export default connect(mapStateToProps)(ObjectItem)
