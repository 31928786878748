import React from 'react'
import classnames from 'classnames'
import { ReactComponent as BluetoothActiveIcon } from '../assets/ble-black-active.svg'
import { ReactComponent as BluetoothInactiveIcon } from '../assets/ble-black-inactive.svg'
import s from './BluetoothIcon.module.scss'

const BluetoothIcon = ({ className, active, onClick }) => {
  const C = active ? BluetoothActiveIcon : BluetoothInactiveIcon
  return (
    <C className={classnames(s.bluetoothIcon, className)}
      onClick={onClick}
      alt="bluetooth icon"
    />
  )
}

export default BluetoothIcon
