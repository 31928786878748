import {
  WIREPAS_DIRECT,
  WIREPAS_DIRECT_SUCCESS,
  WIREPAS_DIRECT_FAILURE,
} from '../constants/actions'

export const wirepasDirectCommand = (siteId, action) => ({
  type: WIREPAS_DIRECT,
  payload: { siteId, action },
})
export const wirepasDirectCommandSuccess = (siteId) => ({
  type: WIREPAS_DIRECT_SUCCESS,
  payload: { siteId },
})
export const wirepasDirectCommandFailure = (siteId, error) => ({
  type: WIREPAS_DIRECT_FAILURE,
  payload: { siteId, error },
})
