import React from 'react'
import cn from 'classnames'

import s from './SectionBlock.module.scss'

const SectionBlock = ({ className, ...props }) => (
  <div className={cn(s.container, className)} {...props} />
)

export default SectionBlock
