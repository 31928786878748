import React from 'react'
import classnames from 'classnames'

import s from './Select.module.scss'
import { useState } from 'react'
import { useEventListener } from '../hooks'

const Option = ({ label, onClick }) => (
  <div className={s.option} onClick={onClick}>
    {label}
  </div>
)

const Select = ({
  value,
  onSelection,
  options,
  border,
  icon,
}) => {
  const selectedOption = options.find(option => option.value === value)
  const [isOpen, setOpen] = useState(false)
  const select = option => {
    onSelection(option.value)
    setOpen(false)
  }

  useEventListener('keydown', (ev) => {
    if (isOpen && ev.key === 'Escape') {
      ev.preventDefault()
      ev.stopPropagation()
      setOpen(false)
    }
  })

  return (
    <div className={classnames(s.select, { [s.withBorder]: border })} tabIndex={-1} onBlurCapture={() => setOpen(false)}>
      <div
        className={classnames(s.currentSelection, { [s.triangleFlipped]: isOpen })}
        onClick={() => setOpen(!isOpen)}
      >
        {icon && <span className={s.icon}>{icon}</span>}{selectedOption && selectedOption.label}
      </div>
      <div className={classnames(s.options, { [s.optionsVisible]: isOpen })}>
        {options.map(option => (
          <Option
            key={option.value}
            value={option.value}
            label={option.label}
            onClick={() => select(option)}
          />
        ))}
      </div>
    </div>
  )
}

export default Select
