import {
  API_KEY_LIST,
  API_KEY_LIST_SUCCESS,
  API_KEY_LIST_FAILURE,
  API_KEY_CREATE,
  API_KEY_CREATE_SUCCESS,
  API_KEY_CREATE_FAILURE,
  API_KEY_UPDATE_PART,
  API_KEY_UPDATE_PART_SUCCESS,
  API_KEY_UPDATE_PART_FAILURE,
  API_KEY_DELETE,
  API_KEY_DELETE_SUCCESS,
  API_KEY_DELETE_FAILURE,
  API_KEY_LAST_CLEAR,
} from '../constants/actions'

export const apiKeyList = (siteId) => ({
  type: API_KEY_LIST,
  payload: { siteId },
})
export const apiKeyListSuccess = (siteId, keyList) => ({
  type: API_KEY_LIST_SUCCESS,
  payload: { siteId, keyList },
})
export const apiKeyListFailure = (siteId) => ({
  type: API_KEY_LIST_FAILURE,
  payload: { siteId },
})

export const apiKeyCreate = (siteId, keyBody) => ({
  type: API_KEY_CREATE,
  payload: { siteId, keyBody },
})
export const apiKeyCreateSuccess = (siteId, keyId, keyBody, token) => ({
  type: API_KEY_CREATE_SUCCESS,
  payload: { siteId, keyId, keyBody, token },
})
export const apiKeyCreateFailure = (siteId) => ({
  type: API_KEY_CREATE_FAILURE,
  payload: { siteId },
})

export const apiKeyUpdatePart = (siteId, keyId, keyBodyPart) => ({
  type: API_KEY_UPDATE_PART,
  payload: { siteId, keyId, keyBodyPart },
})
export const apiKeyUpdatePartSuccess = (siteId, keyId) => ({
  type: API_KEY_UPDATE_PART_SUCCESS,
  payload: { siteId, keyId },
})
export const apiKeyUpdatePartFailure = (siteId, keyId) => ({
  type: API_KEY_UPDATE_PART_FAILURE,
  payload: { siteId, keyId },
})

export const apiKeyDelete = (siteId, keyId) => ({
  type: API_KEY_DELETE,
  payload: { siteId, keyId },
})
export const apiKeyDeleteSuccess = (siteId, keyId) => ({
  type: API_KEY_DELETE_SUCCESS,
  payload: { siteId, keyId },
})
export const apiKeyDeleteFailure = (siteId, keyId) => ({
  type: API_KEY_DELETE_FAILURE,
  payload: { siteId, keyId },
})

export const apiKeyLastClear = (siteId) => ({
  type: API_KEY_LAST_CLEAR,
  payload: { siteId },
})
