import {
  SITE_CONFIGURATION,
  SITE_RAW_CONFIGURATION,
  SITE_STATE,
  SITE_INDEX,
  SITE_SUBSCRIBE,
  SITE_SUBSCRIBE_SUCCESS,
  SITE_SUBSCRIBE_FAILURE,
  SITE_CREATE,
  SITE_CREATE_SUCCESS,
  SITE_CREATE_FAILURE,
  SITE_UPDATE,
  SITE_UPDATE_SUCCESS,
  SITE_UPDATE_FAILURE,
  SITE_UPDATE_PART,
  SITE_UPDATE_PART_SUCCESS,
  SITE_UPDATE_PART_FAILURE,
  SITE_DELETE,
  SITE_DELETE_SUCCESS,
  SITE_DELETE_FAILURE,
  SITE_FLOOR_CREATE,
  SITE_FLOOR_CREATE_FAILURE,
  SITE_FLOOR_CREATE_SUCCESS,
  SITE_FLOOR_UPDATE,
  SITE_FLOOR_UPDATE_FAILURE,
  SITE_FLOOR_UPDATE_SUCCESS,
  SITE_FLOOR_DELETE,
  SITE_FLOOR_DELETE_FAILURE,
  SITE_FLOOR_DELETE_SUCCESS,
  SITE_SPACE_CREATE,
  SITE_SPACE_CREATE_FAILURE,
  SITE_SPACE_CREATE_SUCCESS,
  SITE_SPACE_UPDATE,
  SITE_SPACE_UPDATE_FAILURE,
  SITE_SPACE_UPDATE_SUCCESS,
  SITE_OBJECT_UPDATE_PART,
  SITE_OBJECT_UPDATE_PART_FAILURE,
  SITE_OBJECT_UPDATE_PART_SUCCESS,
  SITE_SPACE_UPDATE_PART,
  SITE_SPACE_UPDATE_PART_FAILURE,
  SITE_SPACE_UPDATE_PART_SUCCESS,
  SITE_SPACE_DELETE,
  SITE_SPACE_DELETE_FAILURE,
  SITE_SPACE_DELETE_SUCCESS,
  SITE_SPACE_RESOURCE_RESET,
  SITE_SPACE_RESOURCE_RESET_SUCCESS,
  SITE_SPACE_RESOURCE_RESET_FAILURE,
  SITE_ROOM_CREATE,
  SITE_ROOM_CREATE_FAILURE,
  SITE_ROOM_CREATE_SUCCESS,
  SITE_ROOM_UPDATE,
  SITE_ROOM_UPDATE_FAILURE,
  SITE_ROOM_UPDATE_SUCCESS,
  SITE_ROOM_DELETE,
  SITE_ROOM_DELETE_FAILURE,
  SITE_ROOM_DELETE_SUCCESS,
  SITE_OBJECT_CREATE,
  SITE_OBJECT_CREATE_FAILURE,
  SITE_OBJECT_CREATE_SUCCESS,
  SITE_OBJECT_UPDATE,
  SITE_OBJECT_UPDATE_FAILURE,
  SITE_OBJECT_UPDATE_SUCCESS,
  SITE_OBJECT_DELETE,
  SITE_OBJECT_DELETE_FAILURE,
  SITE_OBJECT_DELETE_SUCCESS,
  SITE_OBJECT_ASSIGN_RESOURCE,
  SITE_OBJECT_ASSIGN_RESOURCE_FAILURE,
  SITE_OBJECT_ASSIGN_RESOURCE_SUCCESS,
  SITE_OBJECT_ASSIGN_RAW_RESOURCE,
  SITE_OBJECT_ASSIGN_RAW_RESOURCE_FAILURE,
  SITE_OBJECT_ASSIGN_RAW_RESOURCE_SUCCESS,
  SITE_OBJECT_UNASSIGN_RESOURCE,
  SITE_OBJECT_UNASSIGN_RESOURCE_FAILURE,
  SITE_OBJECT_UNASSIGN_RESOURCE_SUCCESS,
  SITE_SCENE_CREATE,
  SITE_SCENE_CREATE_SUCCESS,
  SITE_SCENE_CREATE_FAILURE,
  INPUT,
  INPUT_CAPTURE_START,
  INPUT_CAPTURE_STOP,
  INPUT_CAPTURE_STATUS,
  INPUT_CAPTURE_SUCCESS,
  INPUT_CAPTURE_FAILURE,
  INPUT_CAPTURE_CANCEL,
  SITE_RESOURCE_UPDATE_PART,
  SITE_RESOURCE_UPDATE_PART_SUCCESS,
  SITE_RESOURCE_UPDATE_PART_FAILURE,
  SITE_GATEWAY_UPDATE_PART,
  SITE_GATEWAY_UPDATE_PART_FAILURE,
  SITE_GATEWAY_UPDATE_PART_SUCCESS,
  SITE_HARD_RESET,
  SITE_HARD_RESET_SUCCESS,
  SITE_HARD_RESET_FAILURE,
  UPDATE_SITE_VIEW_PARAMS,
  SET_SITE_VIEW_PARAMS_VALIDATED,
  SITE_RESOURCE_DELETE,
  SITE_RESOURCE_DELETE_SUCCESS,
  SITE_RESOURCE_DELETE_FAILURE,
  SITE_SCENE_UPDATE,
  SITE_SCENE_UPDATE_SUCCESS,
  SITE_SCENE_UPDATE_FAILURE,
  SITE_SCENE_DELETE,
  SITE_SCENE_DELETE_SUCCESS,
  SITE_SCENE_DELETE_FAILURE,
  SITE_RESOURCE_FORCE_RESYNC,
  SITE_RESOURCE_FORCE_RESYNC_FAILURE,
  SITE_RESOURCE_FORCE_RESYNC_SUCCESS,
  SITE_APPLY,
  SITE_APPLY_SUCCESS,
  SITE_APPLY_FAILURE,
  SITE_OBJECT_UPDATE_MATERIALS
} from '../constants/actions'

export const siteConfiguration = (siteId, siteConfiguration) => ({
  type: SITE_CONFIGURATION,
  payload: { siteId, siteConfiguration }
})
export const siteRawConfiguration = (siteId, siteConfiguration) => ({
  type: SITE_RAW_CONFIGURATION,
  payload: { siteId, siteConfiguration }
})
export const siteState = (siteId, siteStateList) => ({
  type: SITE_STATE,
  payload: { siteId, siteStateList },
})

export const siteIndex = (siteList) => ({
  type: SITE_INDEX,
  payload: { siteList },
})

export const siteSubscribe = (siteId) => ({
  type: SITE_SUBSCRIBE,
  payload: { siteId },
})
export const siteSubscribeSuccess = (siteId) => ({
  type: SITE_SUBSCRIBE_SUCCESS,
  payload: { siteId },
})
export const siteSubscribeFailure = (siteId, error) => ({
  type: SITE_SUBSCRIBE_FAILURE,
  payload: { siteId, error },
})

export const siteCreate = (siteBody) => ({
  type: SITE_CREATE,
  payload: { siteBody },
})
export const siteCreateSuccess = (siteId, siteBody) => ({
  type: SITE_CREATE_SUCCESS,
  payload: { siteId, siteBody },
})
export const siteCreateFailure = () => ({
  type: SITE_CREATE_FAILURE,
})

export const siteUpdate = (siteId, siteBody) => ({
  type: SITE_UPDATE,
  payload: { siteId, siteBody },
})
export const siteUpdateSuccess = (siteId, siteBody) => ({
  type: SITE_UPDATE_SUCCESS,
  payload: { siteId, siteBody },
})
export const siteUpdateFailure = (siteId) => ({
  type: SITE_UPDATE_FAILURE,
  payload: { siteId },
})

export const siteUpdatePart = (siteId, siteBodyPart) => ({
  type: SITE_UPDATE_PART,
  payload: { siteId, siteBodyPart },
})
export const siteUpdatePartSuccess = (siteId, siteBodyPart) => ({
  type: SITE_UPDATE_PART_SUCCESS,
  payload: { siteId, siteBodyPart },
})
export const siteUpdatePartFailure = (siteId) => ({
  type: SITE_UPDATE_PART_FAILURE,
  payload: { siteId },
})

export const siteDelete = (siteId, siteBody) => ({
  type: SITE_DELETE,
  payload: { siteId, siteBody },
})
export const siteDeleteSuccess = (siteId) => ({
  type: SITE_DELETE_SUCCESS,
  payload: { siteId },
})
export const siteDeleteFailure = (siteId) => ({
  type: SITE_DELETE_FAILURE,
  payload: { siteId },
})

export const siteFloorCreate = (siteId, floorBody, sourceFloorId = null) => ({
  type: SITE_FLOOR_CREATE,
  payload: { siteId, floorBody, sourceFloorId },
})
export const siteFloorCreateSuccess = (siteId, floorId, floorBody) => ({
  type: SITE_FLOOR_CREATE_SUCCESS,
  payload: { siteId, floorId, floorBody },
})
export const siteFloorCreateFailure = (siteId) => ({
  type: SITE_FLOOR_CREATE_FAILURE,
  payload: { siteId },
})

export const siteFloorUpdate = (siteId, floorId, floorBody) => ({
  type: SITE_FLOOR_UPDATE,
  payload: { siteId, floorId, floorBody },
})
export const siteFloorUpdateSuccess = (siteId, floorId) => ({
  type: SITE_FLOOR_UPDATE_SUCCESS,
  payload: { siteId, floorId },
})
export const siteFloorUpdateFailure = (siteId, floorId) => ({
  type: SITE_FLOOR_UPDATE_FAILURE,
  payload: { siteId, floorId },
})

export const siteFloorDelete = (siteId, floorId) => ({
  type: SITE_FLOOR_DELETE,
  payload: { siteId, floorId },
})
export const siteFloorDeleteSuccess = (siteId, floorId) => ({
  type: SITE_FLOOR_DELETE_SUCCESS,
  payload: { siteId, floorId },
})
export const siteFloorDeleteFailure = (siteId, floorId) => ({
  type: SITE_FLOOR_DELETE_FAILURE,
  payload: { siteId, floorId },
})

export const siteSpaceCreate = (siteId, spaceBody, sourceSpaceId = null) => ({
  type: SITE_SPACE_CREATE,
  payload: { siteId, spaceBody, sourceSpaceId },
})
export const siteSpaceCreateSuccess = (siteId, spaceId, spaceBody) => ({
  type: SITE_SPACE_CREATE_SUCCESS,
  payload: { siteId, spaceId, spaceBody },
})
export const siteSpaceCreateFailure = (siteId) => ({
  type: SITE_SPACE_CREATE_FAILURE,
  payload: { siteId },
})

export const siteSpaceResourceReset = (siteId, spaceId) => ({
  type: SITE_SPACE_RESOURCE_RESET,
  payload: { siteId, spaceId },
})
export const siteSpaceResourceResetSuccess = (siteId, spaceId) => ({
  type: SITE_SPACE_RESOURCE_RESET_SUCCESS,
  payload: { siteId, spaceId },
})
export const siteSpaceResourceResetFailure = (siteId, spaceId) => ({
  type: SITE_SPACE_RESOURCE_RESET_FAILURE,
  payload: { siteId, spaceId },
})

export const siteSpaceUpdate = (siteId, spaceId, spaceBody) => ({
  type: SITE_SPACE_UPDATE,
  payload: { siteId, spaceId, spaceBody },
})
export const siteSpaceUpdateSuccess = (siteId, spaceId) => ({
  type: SITE_SPACE_UPDATE_SUCCESS,
  payload: { siteId, spaceId },
})
export const siteSpaceUpdateFailure = (siteId, spaceId) => ({
  type: SITE_SPACE_UPDATE_FAILURE,
  payload: { siteId, spaceId },
})

export const siteSpaceUpdatePart = (siteId, spaceId, spaceBodyPart) => ({
  type: SITE_SPACE_UPDATE_PART,
  payload: { siteId, spaceId, spaceBodyPart },
})
export const siteSpaceUpdatePartSuccess = (siteId, spaceId) => ({
  type: SITE_SPACE_UPDATE_PART_SUCCESS,
  payload: { siteId, spaceId },
})
export const siteSpaceUpdatePartFailure = (siteId, spaceId) => ({
  type: SITE_SPACE_UPDATE_PART_FAILURE,
  payload: { siteId, spaceId },
})

export const siteSpaceDelete = (siteId, spaceId) => ({
  type: SITE_SPACE_DELETE,
  payload: { siteId, spaceId },
})
export const siteSpaceDeleteSuccess = (siteId, spaceId) => ({
  type: SITE_SPACE_DELETE_SUCCESS,
  payload: { siteId, spaceId },
})
export const siteSpaceDeleteFailure = (siteId, spaceId) => ({
  type: SITE_SPACE_DELETE_FAILURE,
  payload: { siteId, spaceId },
})

export const siteRoomCreate = (siteId, roomBody) => ({
  type: SITE_ROOM_CREATE,
  payload: { siteId, roomBody },
})
export const siteRoomCreateSuccess = (siteId, roomId, roomBody) => ({
  type: SITE_ROOM_CREATE_SUCCESS,
  payload: { siteId, roomId, roomBody },
})
export const siteRoomCreateFailure = (siteId) => ({
  type: SITE_ROOM_CREATE_FAILURE,
  payload: { siteId },
})

export const siteRoomUpdate = (siteId, roomId, roomBody) => ({
  type: SITE_ROOM_UPDATE,
  payload: { siteId, roomId, roomBody },
})
export const siteRoomUpdateSuccess = (siteId, roomId) => ({
  type: SITE_ROOM_UPDATE_SUCCESS,
  payload: { siteId, roomId },
})
export const siteRoomUpdateFailure = (siteId, roomId) => ({
  type: SITE_ROOM_UPDATE_FAILURE,
  payload: { siteId, roomId },
})

export const siteRoomDelete = (siteId, roomId) => ({
  type: SITE_ROOM_DELETE,
  payload: { siteId, roomId },
})
export const siteRoomDeleteSuccess = (siteId, roomId) => ({
  type: SITE_ROOM_DELETE_SUCCESS,
  payload: { siteId, roomId },
})
export const siteRoomDeleteFailure = (siteId, roomId) => ({
  type: SITE_ROOM_DELETE_FAILURE,
  payload: { siteId, roomId },
})

export const siteSceneCreate = (siteId, sceneBody) => ({
  type: SITE_SCENE_CREATE,
  payload: { siteId, sceneBody },
})
export const siteSceneCreateSuccess = (siteId, sceneId, sceneBody) => ({
  type: SITE_SCENE_CREATE_SUCCESS,
  payload: { siteId, sceneId, sceneBody },
})
export const siteSceneCreateFailure = (siteId) => ({
  type: SITE_SCENE_CREATE_FAILURE,
  payload: { siteId },
})

export const siteSceneUpdate = (siteId, sceneId, sceneBody) => ({
  type: SITE_SCENE_UPDATE,
  payload: { siteId, sceneId, sceneBody },
})
export const siteSceneUpdateSuccess = (siteId, sceneId) => ({
  type: SITE_SCENE_UPDATE_SUCCESS,
  payload: { siteId, sceneId },
})
export const siteSceneUpdateFailure = (siteId, sceneId) => ({
  type: SITE_SCENE_UPDATE_FAILURE,
  payload: { siteId, sceneId },
})

export const siteSceneDelete = (siteId, sceneId) => ({
  type: SITE_SCENE_DELETE,
  payload: { siteId, sceneId },
})
export const siteSceneDeleteSuccess = (siteId, sceneId) => ({
  type: SITE_SCENE_DELETE_SUCCESS,
  payload: { siteId, sceneId },
})
export const siteSceneDeleteFailure = (siteId, sceneId) => ({
  type: SITE_SCENE_DELETE_FAILURE,
  payload: { siteId, sceneId },
})

export const siteObjectCreate = (siteId, objectBody) => ({
  type: SITE_OBJECT_CREATE,
  payload: { siteId, objectBody },
})
export const siteObjectCreateSuccess = (siteId, objectId, objectBody) => ({
  type: SITE_OBJECT_CREATE_SUCCESS,
  payload: { siteId, objectId, objectBody },
})
export const siteObjectCreateFailure = (siteId) => ({
  type: SITE_OBJECT_CREATE_FAILURE,
  payload: { siteId },
})

export const siteObjectUpdate = (siteId, objectId, objectBody) => ({
  type: SITE_OBJECT_UPDATE,
  payload: { siteId, objectId, objectBody },
})
export const siteObjectUpdateSuccess = (siteId, objectId) => ({
  type: SITE_OBJECT_UPDATE_SUCCESS,
  payload: { siteId, objectId },
})
export const siteObjectUpdateFailure = (siteId, objectId) => ({
  type: SITE_OBJECT_UPDATE_FAILURE,
  payload: { siteId, objectId },
})

export const siteObjectUpdatePart = (siteId, objectId, updates) => ({
  type: SITE_OBJECT_UPDATE_PART,
  payload: { siteId, objectId, updates },
})
export const siteObjectUpdatePartSuccess = (siteId, objectId, updates) => ({
  type: SITE_OBJECT_UPDATE_PART_SUCCESS,
  payload: { siteId, objectId, updates },
})
export const siteObjectUpdatePartFailure = (siteId, objectId, updates) => ({
  type: SITE_OBJECT_UPDATE_PART_FAILURE,
  payload: { siteId, objectId, updates },
})
export const siteObjectUpdateMaterials = (siteId, objectId, updates) => ({
  type: SITE_OBJECT_UPDATE_MATERIALS,
  payload: { siteId, objectId, updates },
})

export const siteResourceForceResync = (siteId, objectId, resourceId) => ({
  type: SITE_RESOURCE_FORCE_RESYNC,
  payload: { siteId, objectId, resourceId },
})
export const siteResourceForceResyncSuccess = (siteId, objectId, resourceId) => ({
  type: SITE_RESOURCE_FORCE_RESYNC_SUCCESS,
  payload: { siteId, objectId, resourceId },
})
export const siteResourceForceResyncFailure = (siteId, objectId, resourceId) => ({
  type: SITE_RESOURCE_FORCE_RESYNC_FAILURE,
  payload: { siteId, objectId, resourceId },
})

export const siteObjectDelete = (siteId, objectId) => ({
  type: SITE_OBJECT_DELETE,
  payload: { siteId, objectId },
})
export const siteObjectDeleteSuccess = (siteId, objectId) => ({
  type: SITE_OBJECT_DELETE_SUCCESS,
  payload: { siteId, objectId },
})
export const siteObjectDeleteFailure = (siteId, objectId) => ({
  type: SITE_OBJECT_DELETE_FAILURE,
  payload: { siteId, objectId },
})

export const siteObjectAssignResource = (siteId, objectId, resourceId) => ({
  type: SITE_OBJECT_ASSIGN_RESOURCE,
  payload: { siteId, objectId, resourceId },
})
export const siteObjectAssignResourceSuccess = (siteId, objectId, resourceId) => ({
  type: SITE_OBJECT_ASSIGN_RESOURCE_SUCCESS,
  payload: { siteId, objectId, resourceId },
})
export const siteObjectAssignResourceFailure = (siteId, objectId, resourceId) => ({
  type: SITE_OBJECT_ASSIGN_RESOURCE_FAILURE,
  payload: { siteId, objectId, resourceId },
})

export const siteObjectAssignRawResource = (siteId, objectId, resourceBody) => ({
  type: SITE_OBJECT_ASSIGN_RAW_RESOURCE,
  payload: { siteId, objectId, resourceBody },
})
export const siteObjectAssignRawResourceSuccess = (siteId, objectId, resourceBody) => ({
  type: SITE_OBJECT_ASSIGN_RAW_RESOURCE_SUCCESS,
  payload: { siteId, objectId, resourceBody },
})
export const siteObjectAssignRawResourceFailure = (siteId, objectId, resourceBody) => ({
  type: SITE_OBJECT_ASSIGN_RAW_RESOURCE_FAILURE,
  payload: { siteId, objectId, resourceBody },
})

export const siteObjectUnassignResource = (siteId, objectId, resourceId) => ({
  type: SITE_OBJECT_UNASSIGN_RESOURCE,
  payload: { siteId, objectId, resourceId },
})
export const siteObjectUnassignResourceSuccess = (siteId, objectId, resourceId) => ({
  type: SITE_OBJECT_UNASSIGN_RESOURCE_SUCCESS,
  payload: { siteId, objectId, resourceId },
})
export const siteObjectUnassignResourceFailure = (siteId, objectId, resourceId) => ({
  type: SITE_OBJECT_UNASSIGN_RESOURCE_FAILURE,
  payload: { siteId, objectId, resourceId },
})

export const siteResourceUpdatePart = (siteId, resourceId, updates) => ({
  type: SITE_RESOURCE_UPDATE_PART,
  payload: { siteId, resourceId, updates },
})
export const siteResourceUpdatePartSuccess = (siteId, resourceId, updates) => ({
  type: SITE_RESOURCE_UPDATE_PART_SUCCESS,
  payload: { siteId, resourceId, updates },
})
export const siteResourceUpdatePartFailure = (siteId, resourceId, updates) => ({
  type: SITE_RESOURCE_UPDATE_PART_FAILURE,
  payload: { siteId, resourceId, updates },
})

export const siteResourceDelete = (siteId, resourceId) => ({
  type: SITE_RESOURCE_DELETE,
  payload: { siteId, resourceId },
})
export const siteResourceDeleteSuccess = (siteId, resourceId,) => ({
  type: SITE_RESOURCE_DELETE_SUCCESS,
  payload: { siteId, resourceId },
})
export const siteResourceDeleteFailure = (siteId, resourceId) => ({
  type: SITE_RESOURCE_DELETE_FAILURE,
  payload: { siteId, resourceId },
})

export const siteGatewayUpdatePart = (siteId, gatewayId, updates) => ({
  type: SITE_GATEWAY_UPDATE_PART,
  payload: { siteId, gatewayId, updates }
})
export const siteGatewayUpdatePartSuccess = (siteId, gatewayId, updates) => ({
  type: SITE_GATEWAY_UPDATE_PART_SUCCESS,
  payload: { siteId, gatewayId, updates }
})
export const siteGatewayUpdatePartFailure = (siteId, gatewayId, updates) => ({
  type: SITE_GATEWAY_UPDATE_PART_FAILURE,
  payload: { siteId, gatewayId, updates }
})

export const resourceInput = (siteId, { resource, data }) => ({
  type: INPUT,
  payload: { siteId, resource, data },
})

export const inputCaptureStart = (siteId, { type }) => ({
  type: INPUT_CAPTURE_START,
  payload: { siteId, type },
})
export const inputCaptureStop = () => ({
  type: INPUT_CAPTURE_STOP,
})
export const inputCaptureStatus = ({ progress }) => ({
  type: INPUT_CAPTURE_STATUS,
  payload: { progress },
})
export const inputCaptureSuccess = (resource) => ({
  type: INPUT_CAPTURE_SUCCESS,
  payload: { resource },
})
export const inputCaptureFailure = (error, { existingResourceId, existingObjectId } = {}) => ({
  type: INPUT_CAPTURE_FAILURE,
  payload: { error, existingResourceId, existingObjectId },
})
export const inputCaptureCancel = () => ({
  type: INPUT_CAPTURE_CANCEL,
})

export const siteHardReset = (siteId) => ({
  type: SITE_HARD_RESET,
  payload: { siteId },
})
export const siteHardResetSuccess = (siteId) => ({
  type: SITE_HARD_RESET_SUCCESS,
  payload: { siteId },
})
export const siteHardResetFailure = (siteId) => ({
  type: SITE_HARD_RESET_FAILURE,
  payload: { siteId },
})

export const updateSiteViewParams = (siteId, params) => ({
  type: UPDATE_SITE_VIEW_PARAMS,
  payload: { siteId, params }
})
export const setSiteViewValidated = (siteId, siteView) => ({
  type: SET_SITE_VIEW_PARAMS_VALIDATED,
  payload: { siteId, siteView }
})

export const siteApply = (siteId, params) => ({
  type: SITE_APPLY,
  payload: { siteId, params },
})
export const siteApplySuccess = (siteId, params) => ({
  type: SITE_APPLY_SUCCESS,
  payload: { siteId, params },
})
export const siteApplyFailure = (siteId, params) => ({
  type: SITE_APPLY_FAILURE,
  payload: { siteId, params },
})
