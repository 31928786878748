import React from 'react'

import s from './ListItemPlaceholder.module.scss'

const ListItemPlaceholder = ({ label }) => (
  <div className={s.container}>
    <span className={s.label}>{label}</span>
  </div>
)

export default ListItemPlaceholder
