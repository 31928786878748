import React, { Component } from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import cn from 'classnames'

import s from './LoginScreen.module.scss'
import { authLogin } from '../actions'

class LoginScreen extends Component {
  state = {
    username: '',
    password: '',
  }

  handleSubmit = (ev) => {
    ev.preventDefault()

    const { dispatch } = this.props
    const { username, password } = this.state

    dispatch(authLogin({ username, password }))

    this.setState({ password: '' })
  }

  handleUsernameChange = (ev) => {
    this.setState({ username: ev.target.value })
  }

  handlePasswordChange = (ev) => {
    this.setState({ password: ev.target.value })
  }

  render() {
    const { error } = this.props
    const { username, password } = this.state

    return (
      <div className={s.container}>
        <div className={s.heading}>
          Plan, design and configure your buildings from one place.
        </div>
        <form className={s.form} onSubmit={this.handleSubmit}>
          <div className={s.inputHolder}>
            <input
              name="username"
              className={s.input}
              value={username}
              placeholder="Email"
              onChange={this.handleUsernameChange}
              autoFocus={true}
            />
          </div>
          <div className={s.inputHolder}>
            <input
              type="password"
              name="password"
              className={cn(s.input, s.inputPassword)}
              value={password}
              placeholder="Password"
              onChange={this.handlePasswordChange}
            />
          </div>
          <div className={s.submitHolder}>
            <button type="submit" className={s.submit}>
              Login
            </button>
          </div>
          <div className={s.footer}>
            {error != null &&
              <div className={s.errorHolder}>
                <span className={s.error}>
                  {error === 'invalid_grant'
                    ? 'Please check username and password.'
                    : 'Server is too busy providing a premium experience.'
                  }
                </span>
              </div>
            }
          </div>
        </form>
      </div>
    )
  }
}

const loginErrorSelector = (state) => state.login.error

const mapStateToProps = createStructuredSelector({
  error: loginErrorSelector,
})

export default connect(mapStateToProps)(LoginScreen)
