import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector, createSelector } from 'reselect'
import cn from 'classnames'
import * as R from 'ramda'
import immer from 'immer'
import ColorSpace from 'color-space'

import s from './SceneSectionContainer.module.scss'
import { spacesSelector, objectsWithLocationSelector } from '../selectors'
import Section from '../components/Section'
import Heading from '../components/Heading'
import TextField from '../components/TextField'
import Checkbox from '../components/Checkbox'
import RadioButtonGroup from '../components/RadioButtonGroup'
import GroupHeading from '../components/GroupHeading'
import { objectApply, siteSceneUpdate, siteSceneDelete, siteApply } from '../actions'
import { scenesSelector, isSceneJustCreatedSelector } from '../selectors/scene'

const percentToState = percent => percent === 0 ? { on: false} : { on: true, bri: Math.round(percent * 255 / 100) }
const colorToState = (percent, hue, saturation) => percent === 0 ? { on: false} :
                    { on: true, bri: Math.round(percent * 255 / 100),
                      hue:  Math.round(hue * 65535 / 360), saturation:  Math.round(saturation * 65535 / 100)}
const isLogicalConfigurationEditable = space => !space.linkedSpaceId
const location = light => `${light.roomName} . ${light.name}`

const compareApplyListItems = (a, b) => {
  if (a.objectId !== b.objectId) return false
  if (a.state && b.state && (a.state.on !== b.state.on || a.state.bri !== b.state.bri ||
    a.state.hue !== b.state.hue || a.state.saturation !== b.state.saturation) ) return false
  if (a.action && b.action && a.action !== b.action) return false
  return true
}

const SceneSectionContainer = ({ siteId, space, scene, lights, curtains, isJustCreated, linkedSpacesCount, dispatch }) => {
    const handleRename = (name) => {
      dispatch(siteSceneUpdate(siteId, scene.id, { ...scene, name }))
    }

    const [applyList, setApplyList] = useState([])
    const [activated, setActivated] = useState(false)

    const hasChanges = R.compose(R.not, R.isEmpty, R.symmetricDifferenceWith(compareApplyListItems))(applyList, scene.applyList)
    useEffect(() => {
      setApplyList(scene.applyList)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
      if(!isJustCreated && activated) {
        dispatch(siteApply(siteId, { action: 'sceneOn', targetSceneId: scene.id }))
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activated])

    const updateApplyList = (object, updates) => {
      const updatedApplyList = immer(applyList, draft => {
        const itemIndex = draft.findIndex(({ objectId }) => objectId === object.id)
        if(itemIndex >= 0) {
          draft[itemIndex] = { ...draft[itemIndex], ...updates }
        }
      })

      setApplyList(updatedApplyList)
    }

    const addToApplyList = object => {
      if (object.capabilities.includes("color")) {
        setApplyList(R.uniqBy(R.prop("objectId"), [...applyList, { objectId: object.id, state: colorToState(100, 360, 100) }]))
        dispatch(objectApply(siteId, { objectId: object.id }, 'lightSet', { on: true, bri: 255, hue: 65535, saturation: 65535 } ))
      } else {
        setApplyList(R.uniqBy(R.prop("objectId"), [...applyList, { objectId: object.id, state: percentToState(100) }]))
        dispatch(objectApply(siteId, { objectId: object.id }, 'lightSet', { on: true, bri: 255 } ))
      }
    }

    const addCurtainToApplyList = object => {
      setApplyList(R.uniqBy(R.prop("objectId"), [...applyList, { objectId: object.id, action: 'curtainOpen' }]))
      dispatch(objectApply(siteId, { objectId: object.id }, 'curtainOpen'))
    }

    const removeFromApplyList = object => {
      setApplyList(applyList.filter(({ objectId }) => objectId !== object.id))
      dispatch(objectApply(siteId, { objectId: object.id }, 'lightSet', { on: false } ))
    }

    const removeCurtainFromApplyList = object => {
      setApplyList(applyList.filter(({ objectId }) => objectId !== object.id))
    }

    const setPercent = (conf, object, percent) => {
      const clamped = Math.max(0, Math.min(100, percent))
      if (object.capabilities.includes("color")){
        const state = { hue: 65535, saturation: 65535, ...conf.state, ...percentToState(clamped) }
        updateApplyList(object, { state})
        const brightness = Math.round(255 * clamped / 100)
        dispatch(objectApply(siteId, { objectId: object.id }, 'lightSet', {on: (brightness > 0), ...brightness > 0 && state}))
      } else {
        updateApplyList(object, { state:percentToState(clamped)})
        const brightness = Math.round(255 * clamped / 100)
        dispatch(objectApply(siteId, { objectId: object.id }, 'lightSet', { on: (brightness > 0), ...brightness > 0 && {bri: brightness} } ))
      }
    }

    const setHue = (conf, object, degree) => {
      const clamped = Math.max(0, Math.min(360, degree))
      const hue = Math.round(65535 * clamped / 360)
      const state = {saturation: 65535, ...conf.state, hue}
      updateApplyList(object, { state } )
      dispatch(objectApply(siteId, { objectId: object.id }, 'lightSet', {on: (conf.state.on),  ...conf.state.on && state} ))
    }

    const setSaturation = (conf, object, percent) => {
      const clamped = Math.max(0, Math.min(100, percent))
      const saturation = Math.round(65535 * clamped / 100)
      const state = {hue: 65535, ...conf.state, saturation}
      updateApplyList(object, { state } )
      dispatch(objectApply(siteId, { objectId: object.id }, 'lightSet', {on: (conf.state.on), ...conf.state.on && state} ))
    }

    const hslStyleFromHue = (hue, saturation) => {
      const hsv = ColorSpace.hsv.hsl([hue, saturation, 100])
      return 'hsl('+hsv[0]+', '+hsv[1]+'%, '+hsv[2]+'%)'
    }

    const setCurtainAction = (object) => (event) => {
      const action = event.target.value
      updateApplyList(object, { action: action})
      dispatch(objectApply(siteId, { objectId: object.id }, action))
    }

    const synchronize = () => {
      dispatch(siteSceneUpdate(siteId, scene.id, { ...scene, applyList }))
    }

    const discardChanges = () => {
      setApplyList(scene.applyList)
      dispatch(siteApply(siteId, { action: 'sceneOn', targetSceneId: scene.id }))
    }

    const deleteScene = () => {
      dispatch(siteSceneDelete(siteId, scene.id))
    }

    return (
      <Section minWidth="528px" className={cn(s.section, { [s.disabled]: !isLogicalConfigurationEditable(space) })}>
        <div className={cn([s.overlay, { [s.hidden]: activated || isJustCreated }])}>
          <div className={cn(s.action, s.expand)} onClick={() => setActivated(true)}>
            Activate scene and enable scene editor
          </div>
        </div>
        <div className={s.row}>
          <div className={s.delete} onClick={deleteScene}>Delete this scene</div>
        </div>
        <Heading label='Name' />
        <TextField
          initialValue={scene.name}
          onSave={handleRename}
          activateOnMount={isJustCreated}
        />
        <Heading label='Configuration' />
        <GroupHeading label={'Lights'} />
        {lights.map(light => {
          const conf = applyList.find(({ objectId }) => objectId === light.id)
          const isInScene = Boolean(conf)
          const percent = conf && conf.state.on ? Math.max(1,  Math.round((conf.state.bri || 0) / 255 * 100)) : 0
          const hue = conf && conf.state.hue ? Math.max(1,  Math.round((conf.state.hue || 0) / 65535 * 360)) : 0
          const saturation = conf && conf.state.saturation ? Math.max(1,  Math.round((conf.state.saturation || 0) / 65535 * 100)) : 0

          return  (
            <div className={cn(s.item, { [s.inScene]: isInScene })} key={light.id}>
              <div className={s.column}>
                <Checkbox
                  bg="white"
                  checked={isInScene}
                  className={s.checkbox}
                  onChange={e => isInScene ? removeFromApplyList(light) : addToApplyList(light)}
                  label={location(light)}
                />
              </div>
              <div className={s.column}>
              {light.capabilities.includes("color") &&
                  <>
                    <div className={s.picker}
                    onClick={e => {if (isInScene) setHue(conf, light, e.nativeEvent.offsetX*360/80) } } >
                    </div>
                    <TextField
                      className={s.color}
                      value={isInScene ? saturation : ""}
                      onSave={value => setSaturation(conf, light, parseInt(value, 10))} />
                    <div className={s.colorIndicator} style={{backgroundColor: hslStyleFromHue(hue, saturation)}} />
                  </>}
                <div className={s.actions}>
                  <span className={s.action} onClick={() => setPercent(conf, light, 0)}>Off</span>
                  <span className={s.action} onClick={() => setPercent(conf, light, percent - 10)}>Dimmer</span>
                  <span className={s.action} onClick={() => setPercent(conf, light, percent + 10)}>Brighter</span>
                  <span className={s.action} onClick={() => setPercent(conf, light, 100)}>On</span>
                </div>
                <TextField className={s.percentage} value={isInScene ? percent : ""} onSave={value => setPercent(conf, light, parseInt(value, 10))} />
                <span>%</span>
              </div>
            </div >
          )
        })}
        <GroupHeading label={'Curtains'} />
        {curtains.map(curtain => {
          const conf = applyList.find(({ objectId }) => objectId === curtain.id)
          const isInScene = Boolean(conf)
          const action = conf && conf.action

          return  (
            <div className={cn(s.item, { [s.inScene]: isInScene })} key={curtain.id}>
              <div className={s.column}>
                <Checkbox
                  bg="white"
                  checked={isInScene}
                  className={s.checkbox}
                  onChange={e => isInScene ? removeCurtainFromApplyList(curtain) : addCurtainToApplyList(curtain)}
                  label={location(curtain)}
                />
              </div>
              <div className={s.row}>
              <RadioButtonGroup
                radioButtonClassName={s.radioButton}
                radioButtonGroupClassName={s.row}
                selected={action}
                onChange={setCurtainAction(curtain)}
                options={[
                  { label: 'Open', value: 'curtainOpen' },
                  { label: 'Close', value: 'curtainClose' },
                ]}
              />
              </div>
            </div >
          )
        })}
        <div className={s.footer}>
          {hasChanges && (
            <div className={s.synchronizeContainer}>
              <span>
                You are modifying the scene. Once you are finished adjusting the levels,
                press Sync{linkedSpacesCount > 0 ? `, and the adjustments are synced to all ${linkedSpacesCount} linked spaces.` : "."}
              </span>
              <div className={s.actions}>
                <span className={s.action} onClick={discardChanges}>Discard changes</span>
                <span className={s.action} onClick={synchronize}>Sync</span>
              </div>
            </div>
          )}
        </div>
      </Section>
    )
}

const spaceIdSelector = (state, props) => props.spaceId
const spaceSelector = createSelector(
  [spacesSelector, spaceIdSelector],
  (spaces, spaceId) => spaces.find(({ id }) => id === spaceId)
)

const sortedLightsSelector = createSelector(
  [spaceSelector, objectsWithLocationSelector],
  (space, objects) => R.sortBy(location, objects.filter(o => o.spaceId === space.id && o.type === 'light'))
)

const sortedCurtainsSelector = createSelector(
  [spaceSelector, objectsWithLocationSelector],
  (space, objects) => R.sortBy(location, objects.filter(o => o.spaceId === space.id && o.type === 'curtain'))
)

const linkedSpacesCountSelector = createSelector(
  [spaceSelector, spacesSelector],
  (space, spaces) => spaces.filter(s => s.linkedSpaceId === space.id).length
)

const sceneIdSelector = (state, props) => props.sceneId
const sceneSelector = createSelector(
  [scenesSelector, sceneIdSelector],
  (scenes, sceneId) => scenes.find(({ id }) => id === sceneId)
)

const mapStateToProps = createStructuredSelector({
  isJustCreated: isSceneJustCreatedSelector,
  objects: objectsWithLocationSelector,
  lights: sortedLightsSelector,
  curtains: sortedCurtainsSelector,
  linkedSpacesCount: linkedSpacesCountSelector,
  space: spaceSelector,
  scene: sceneSelector
})

export default connect(mapStateToProps)(SceneSectionContainer)
