import { Component } from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import { indexedSitesSelector } from '../selectors'
import { siteSubscribe } from '../actions/site'

class SiteSubscribeContainer extends Component {
  componentDidMount() {
    const { siteId, indexedSites, dispatch } = this.props
    if (siteId && indexedSites[siteId] != null) {
      dispatch(siteSubscribe(siteId))
    }
  }

  componentDidUpdate(prevProps) {
    const { siteId, indexedSites, dispatch } = this.props
    const { siteId: prevSiteId, indexedSites: prevIndexedSites } = prevProps
    if (siteId && ((siteId !== prevSiteId && indexedSites[siteId] != null) || prevIndexedSites[siteId] == null)) {
      dispatch(siteSubscribe(siteId))
    }
  }

  render() {
    return null
  }
}

const mapStateToProps = createStructuredSelector({
  indexedSites: indexedSitesSelector,
})

export default connect(mapStateToProps)(SiteSubscribeContainer)
