import {
  BLINK_ONCE,
  BLINK_ONCE_FAILURE,
  BLINK_ONCE_SUCCESS,
  BLINK_START,
  BLINK_START_FAILURE,
  BLINK_START_SUCCESS,
  BLINK_STOP,
  BLINK_STOP_FAILURE,
  BLINK_STOP_SUCCESS,
} from '../constants/actions'

export const blinkOnce = (siteId, resourceId) => ({
  type: BLINK_ONCE,
  payload: { siteId, resourceId },
})

export const blinkOnceSuccess = (siteId, resourceId) => ({
  type: BLINK_ONCE_SUCCESS,
  payload: { siteId, resourceId },
})

export const blinkOnceFailure = (siteId, resourceId, error) => ({
  type: BLINK_ONCE_FAILURE,
  payload: { siteId, resourceId, error },
})

export const blinkStart = (siteId, resourceId) => ({
  type: BLINK_START,
  payload: { siteId, resourceId },
})

export const blinkStartSuccess = (siteId, resourceId) => ({
  type: BLINK_START_SUCCESS,
  payload: { siteId, resourceId },
})

export const blinkStartFailure = (siteId, resourceId, error) => ({
  type: BLINK_START_FAILURE,
  payload: { siteId, resourceId, error },
})

export const blinkStop = (siteId, resourceId) => ({
  type: BLINK_STOP,
  payload: { siteId, resourceId },
})

export const blinkStopSuccess = (siteId, resourceId) => ({
  type: BLINK_STOP_SUCCESS,
  payload: { siteId, resourceId },
})

export const blinkStopFailure = (siteId, resourceId, error) => ({
  type: BLINK_STOP_FAILURE,
  payload: { siteId, resourceId, error },
})
