import { READY } from '../constants/actions'

const initialState = false
const ready = (state = initialState, { type }) => {
  switch (type) {
    case READY:
      return true
    default:
      return state
  }
}

export default ready
