import React, { Component } from 'react'
import { Route, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import { authPresentSelector } from '../selectors'

class PrivateRoute extends Component {
  renderRoute = (props) => {
    const { authenticated, component } = this.props

    if (authenticated) {
      return React.createElement(component, props)
    }

    return (
      <Redirect to="/" />
    )
  }

  render() {
    const { authenticated, component, ...rest } = this.props

    return (
      <Route {...rest} render={this.renderRoute} />
    )
  }
}

const mapStateToProps = createStructuredSelector({
  authenticated: authPresentSelector,
})

export default connect(mapStateToProps, null)(PrivateRoute)
