import React from 'react'
import cn from 'classnames'
import ListItemPlaceholder from '../../components/ListItemPlaceholder'
import TextField from '../../components/TextField'

import s from './MaterialsList.module.scss'

const MaterialsList = ({
  onUpdate,
  materials
}) => {
  const handleMaterialAmountUpdate = (materialId, amount) => {
    onUpdate(materials.map(m => m.id === materialId ? { ...m, amount } : m))
  }

  const handleMaterialDelete = (materialId) => {
    onUpdate(materials.filter(m => m.id !== materialId))
  }

  return materials.length === 0
    ? <ListItemPlaceholder label="No materials" />
    : materials.map(material =>
        <div className={s.item} key={material.id}>
          <div className={s.column}>
            <div>
              <span>{material.name}</span>
              <br />
              <span
                className={cn(s.materialDataLink, { [s.disabled]: !material.url })} 
                onClick={() => material.url && window.open(material.url)}
              >
                Datasheet
              </span>
            </div>
          </div>
          <div className={s.column}>
            <TextField 
              className={s.amount}
              value={material.amount.toString()}
              onSave={value => {
                const amount = parseInt(value, 10)
                if (amount && amount !== material.amount) {
                  handleMaterialAmountUpdate(material.id, amount)
                }
              }}
            />
            <div className={s.actions}>
              <span
                className={s.action} 
                onClick={() => {
                  handleMaterialAmountUpdate(material.id, material.amount + 1)
                }}
              >
                Inc
              </span>
              <span
                className={cn(s.action, material.amount <= 0 && s.disabled)}
                onClick={() => {
                  if (material.amount > 0) {
                    handleMaterialAmountUpdate(material.id, material.amount - 1)
                  }
                }}
              >
                Dec
              </span>
              <span 
                className={cn(s.action, s.danger)} 
                onClick={() => handleMaterialDelete(material.id)}
              >
                Delete
              </span>
            </div>
          </div>
        </div >
      )
}

export default MaterialsList
