import { materialsSort } from '../utils/sort'

const AVAILABLE_MATERIALS = [
    {
       "name":"BLE switch, 1-key",
       "id":"a9f626f2-72cf-4e2f-9bf8-cb283c93509a",
       "price": 50
    },
    {
       "name":"BLE switch, 2-key",
       "id":"ec804f9d-42d6-4d15-a73c-1de042153486",
       "price": 50
    },
    {
       "name":"Danlers MK-CEFL motion sensor, flush mount",
       "id":"ad3307c7-0a17-4d85-9f68-e8201f69cd28",
       "price": 85
    },
    {
       "name":"Danlers MK-CESR motion sensor, surface mount",
       "id":"d96edaa3-0c64-4cc8-8f62-3fced9045dec",
       "price": 85
    },
    {
       "name":"Danlers MK-HBWD motion sensor, surface mount, high bay wide",
       "id":"282b6b21-c459-4eee-b1a2-0c8624eaf4cc",
       "price": 114
    },
    {
       "name":"Ensto DaliMesh",
       "id":"c02ff1ea-09d1-4929-8f7c-ce81dcf88e4d",
       "price": 50
    },
    {
       "name":"Helvar LL4-CV-FD CV control node",
       "id":"ede4ba76-292e-46c3-9315-a7acc2a29992",
       "price": 70
    },
    {
       "name":"Helvar Freedom CC control node (with cable)",
       "id":"4399d7f8-0192-412c-9e3a-5bbd829b325e",
       "price": 32
    },
    {
       "name":"Helvar ACD-FD AC driver",
       "id":"bb64bf9f-898a-41e2-ad89-6adf1527c779",
       "price": 65
    },
    {
       "name":"Helvar Active+ LC1x35 CC driver",
       "id":"2d69629a-0edd-49a2-99e3-03f2551d520a",
       "price": 0
    },
    {
       "name":"Helvar LL1x120-CV24 CV driver, 24V/120W",
       "id":"7d1a6941-5086-4340-8fd5-07c7d9e3def1",
       "price": 15
    },
    {
       "name":"Helvar LL1x180-CV24 CV driver, 24V/180W",
       "id":"b566f2f6-4c51-4a44-bb4f-37b624996180",
       "price": 20
    },
    {
       "name":"Helvar LL1x30-CV12 CV driver, 12V/30W",
       "id":"19500f61-a321-47fa-8226-4bac13bfef7e",
       "price": 15
    },
    {
       "name":"Helvar LL1x30-E-CV24 CV driver, 24V/30W",
       "id":"114022ce-6694-4b7e-814a-a83af5e87a4e",
       "price": 15
    },
    {
       "name":"Helvar LL1x75-CV12 CV driver, 12V/75W",
       "id":"83c0618a-6d61-4c45-92ab-0f6f888f1699",
       "price": 15
    },
    {
       "name":"Helvar LL1x75-E-CV24 CV driver, 24V/75W",
       "id":"b48b172a-0097-47b0-a2df-052deb1f0adb",
       "price": 15
    },
    {
       "name":"Helvar Freedom CC compact driver, 350-700mA/25W ",
       "id":"6bd2eee5-0899-4afa-8c3d-c2f96f9ed352",
       "price": 50
    },
    {
       "name":"Helvar Freedom CC compact driver, 350-700mA/26W",
       "id":"699b0259-9f8f-4b32-adab-37a4335ab7f3",
       "price": 50
    },
    {
       "name":"Helvar Freedom CC linear driver, 120-350mA/10-42W",
       "id":"2cf18c13-28ce-45f4-aebc-8c5e864c379c",
       "price": 25
    },
    {
       "name":"Ensto motion/luminance sensor (compatible with Helvar Freedom CC control node)",
       "id":"b7c345ce-dda2-4c54-abf6-3d48bceaa56c",
       "price": 0
    },
    {
       "name":"NodOn MK-1CH 1-channel relay",
       "id":"7deed7fe-023e-42e7-86de-b8ec8f29e788",
       "price": 30
    },
    {
       "name":"NodOn MK-2CH 2-channel relay",
       "id":"5d14d098-0d27-4349-a732-ba2a1d734ec9",
       "price": 30
    },
    {
       "name":"Mount Kelvin BLE door sensor",
       "id":"3db00016-8732-4b8c-8028-c4ff36412b68",
       "price": 20
    },
    {
       "name":"Mount Kelvin BLE motion sensor",
       "id":"7c35351e-e88a-4b81-8b5c-a059cb2c2e84",
       "price": 0
    },
    /*
    {
       "name":"Output: 2nd channel of 2 channel relay MK-2CH",
       "id":"8c2268e5-64e2-49ae-8d99-3ca395b02096",
       "price": 20
    },
    {
       "name":"Input: Motion Sensor using existing relay input",
       "id":"087be1f7-f94a-4793-9ce7-faea79e0fffa",
       "price": 20
    },
    {
       "name":"Input: Door Sensor using existing relay input",
       "id":"97059f7f-047f-4bc6-b7f0-29d286f9e6de",
       "price": 20
    },
    {
       "name":"Input: Other sensor/input using existing relay input",
       "id":"a5fe0dd3-ca9d-4d57-8249-498ba853bce7",
       "price": 20
    }
    */
]

export default materialsSort(AVAILABLE_MATERIALS)
