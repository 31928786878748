import React from 'react'
import { connect } from 'react-redux'
import { createSelector, createStructuredSelector } from 'reselect'
import cn from 'classnames'
import { FixedSizeList } from 'react-window'
import AutoSizer from 'react-virtualized-auto-sizer'

import { resourcesWithObjectSelector, unassignedResourcesSelector, matchSiteIdSelector, resourcesLastSeenSelector } from '../selectors'
import { blinkStart, blinkStop, discover, wirepasDirectCommand, updateSiteViewParams, siteResourceDelete } from '../actions'
import { setProximityData, setResourceSearch, setResourceSortOrder, setResourceSortBy } from '../actions/resourceOverviewScreen'
import s from './ResourceOverviewScreen.module.scss'
import { getIconSrcForResource, resourceLastSeenFormatted, isResourceLastSeenOffline } from '../utils'
import ListItemPlaceholder from '../components/ListItemPlaceholder'
import { siteViewSelector } from '../selectors/siteView'
import PopupMenu from '../components/PopupMenu'
import { ReactComponent as ThreeDotIcon } from '../assets/three-dot.svg'
import SortOrder from '../components/SortOrder'
import { ProximityScannerContext } from '../utils/proximityScanner'

const assignment = resource => {
  const { assignedObject } = resource
  if(assignedObject) {
    return `${assignedObject.floorName} • ${assignedObject.spaceName} • ${assignedObject.roomName} • ${assignedObject.name}`
  }

  return "Unassigned"
}

const RowWrapper = ({ index, style, data }) => {
  const {
    resources,
    selectedResourceId,
    onClick,
    onDelete,
    onToggleAutoBlink,
    proximities,
    isRssiFeedAlive,
    autoBlinkEnabled,
    resourcesLastSeen
  } = data

  const r = resources[index]
  return (
    <Row
      resource={r}
      selected={r.id === selectedResourceId}
      onClick={() => onClick(r)}
      onDelete={() => onDelete(r)}
      onToggleAutoBlink={onToggleAutoBlink}
      autoBlinkEnabled={autoBlinkEnabled}
      proximity={Math.round(proximities[r.address] || -Infinity)}
      isRssiFeedAlive={isRssiFeedAlive}
      lastSeen={resourcesLastSeen[r.id]}
      style={style}
    />
  )
}

const Row = ({ resource, style, selected, onClick, isRssiFeedAlive, proximity, lastSeen, onToggleAutoBlink, autoBlinkEnabled, onDelete }) => (
  <div className={cn(s.tableRow, { [s.selected]: selected })} style={style} onClick={onClick}>
    <span className={cn(s.action, s.blinkingText, { [s.visible]: selected })} onClick={onToggleAutoBlink}>
      {autoBlinkEnabled ? "Blinking" : "Not blinking"}<br />(B)
    </span>
    <span className={s.make}>
      <img src={getIconSrcForResource(resource)} alt={resource.type} className={s.icon} />
      {resource.model || "Unknown make and model"}
    </span>
    <span className={s.id}>{resource.id}</span>
    <span className={s.address}>{resource.address}</span>
    <span className={s.assignment}>{assignment(resource)}</span>
    <span className={s.rssi}>
      {isRssiFeedAlive ? <>{proximity}&nbsp;<span className={s.dbm}>dBm</span></> : '----'}
    </span>
    <span className={s.heartbeat}>
      <span className={cn({ [s.offline]: isResourceLastSeenOffline(lastSeen) })}>
        {resourceLastSeenFormatted(lastSeen)}
      </span>
    </span>
    <span className={s.firmwareVersion}>{(resource.firmwareVersion) ? resource.firmwareVersion : 'N/A'}</span>
    <PopupMenu
      className={s.threeDots}
      optionGroups={[[{ label: "Remove from network", danger: true, onClick: onDelete }]]}
      align="topRight"
      width="160px"
    >
      <ThreeDotIcon className={s.threeDotIcon} />
    </PopupMenu>
  </div>
)

class ResourceOverviewScreen extends React.Component {
  searchRef = React.createRef()
  state = { isRssiFeedAlive: false, autoBlinkEnabled: false }

  componentDidMount() {
    window.document.addEventListener("keydown", this.onKeyDown)
    this.onMountOrUpdate()
    this.updateProximities()
  }

  componentWillUnmount() {
    window.document.removeEventListener("keydown", this.onKeyDown)

    const { dispatch, resourceId, siteId } = this.props
    if(this.state.autoBlinkEnabled) {
      dispatch(blinkStop(siteId, resourceId))
    }
  }

  componentDidUpdate(prevProps, prevState) {
    this.onMountOrUpdate(prevProps, prevState)
  }

  onMountOrUpdate(prevProps = {}, prevState = {}) {
    const { siteId, dispatch } = this.props
    if(prevState.autoBlinkEnabled !== this.state.autoBlinkEnabled || 
       prevProps.resourceId !== this.props.resourceId
       ) {
      if(prevState.autoBlinkEnabled) {
        clearTimeout(this.autoBlinkTimer)
        this.autoBlinkTimer = null
        dispatch(blinkStop(siteId, prevProps.resourceId))
      }

      if (this.state.autoBlinkEnabled) {
        const prevResourceId = this.props.resourceId
        this.autoBlinkTimer = setTimeout(() => {
          // check that resourceId is still the same as what triggered the timer
          if(prevResourceId === this.props.resourceId) {
            this.props.resourceId && dispatch(blinkStart(siteId, this.props.resourceId))
          }
          this.autoBlinkTimer = null
        }, 400)
      }
    }

    if(prevProps.resourceId !== this.props.resourceId ||
       prevProps.resources !== this.props.resources) {
         const { resources, resourceId } = this.props
         if(!resources.find(r => r.id === resourceId)) {
           this.gotoNext()
         }
       }
  }

  onKeyDown = (ev) => {
    const key = ev.key.toLowerCase()
    const searchInputHasFocus = this.searchRef.current === document.activeElement

    if(ev.ctrlKey || ev.metaKey) return

    if (searchInputHasFocus) {
      switch(key) {
        case 'arrowup':
        case 'arrowdown':
        case 'escape':
        case 'enter':
          this.searchRef.current.blur()
          break
        default:
          return
      }
    }

    switch(key) {
      case 'arrowup':
        this.gotoPrev()
        break
      case 'arrowdown':
        this.gotoNext()
        break
      case 'b':
        this.handleToggleAutoBlink()
        break
      case 'r':
        this.updateProximities()
        break
      case 'f':
        this.searchRef.current.focus()
        break
      default:
        return
    }

    ev.preventDefault()
    ev.stopPropagation()
  }

  updateProximities = () => {
    const { dispatch } = this.props
    const proximityScanner = this.context

    dispatch(setProximityData(proximityScanner.getProximities()))
    this.setState({ isRssiFeedAlive: !proximityScanner.isDead() })
  }

  handleToggleAutoBlink = () => {
    this.setState({ autoBlinkEnabled: !this.state.autoBlinkEnabled })
  }

  handleDeleteResource = (resource) => {
    const { dispatch, siteId } = this.props
    dispatch(siteResourceDelete(siteId, resource.id))
  }

  navigateResource = (resource) => {
    const { dispatch, siteId } = this.props
    dispatch(updateSiteViewParams(siteId, { resourceId: resource.id }))
  }
  gotoNext = () => {
    const { dispatch, siteId, nextResourceId } = this.props
    dispatch(updateSiteViewParams(siteId, { resourceId: nextResourceId || null }))
  }
  gotoPrev = () => {
    const { dispatch, siteId, prevResourceId } = this.props
    dispatch(updateSiteViewParams(siteId, { resourceId: prevResourceId || null }))
  }

  handleSearchChange(ev) {
    const { dispatch } = this.props
    dispatch(setResourceSearch(ev.target.value))
  }

  handleDiscover = () => {
    const { siteId, dispatch } = this.props
    dispatch(discover(siteId))
  }

  handleSwitchLightsOff = () => {
    const { siteId, dispatch } = this.props
    dispatch(wirepasDirectCommand(siteId, 'lightOff'))
  }

  handleSwitchLightsOn = () => {
    const { siteId, dispatch } = this.props
    dispatch(wirepasDirectCommand(siteId, 'lightOn'))
  }

  handleHeartbeatSortClicked = () => {
    const { dispatch, sortBy } = this.props

    // heartbeat can only be sorted descending, so no need to toggle between asc and desc
    if(sortBy !== 'heartbeat') {
      dispatch(setResourceSortOrder('desc'))
      dispatch(setResourceSortBy('heartbeat'))
    }
  }

  handleSortClicked = (column) => {
    const { dispatch, sortBy, sortOrder } = this.props
    if(sortBy === column) {
      dispatch(setResourceSortOrder(sortOrder === 'asc' ? 'desc' : 'asc'))
    } else {
      dispatch(setResourceSortOrder('asc'))
      dispatch(setResourceSortBy(column))
    }
  }

  renderTable() {
    const { resources, resourcesLastSeen, resourceId, proximities, resourceSearch, sortBy, sortOrder } = this.props
    const { isRssiFeedAlive, autoBlinkEnabled } = this.state

    const rowItemData = {
      resources,
      resourcesLastSeen,
      proximities,
      isRssiFeedAlive,
      autoBlinkEnabled,
      selectedResourceId: resourceId,
      onClick: r => this.navigateResource(r),
      onDelete: r => this.handleDeleteResource(r),
      onToggleAutoBlink: this.handleToggleAutoBlink
    }

    return (
      <div className={s.table}>
        <div className={s.tableHeaders}>
          <span className={s.make}>Make and model</span>
          <span className={s.id}>Identifier</span>
          <span className={s.address}>
              Address
          </span>
          <span className={s.assignment}>Assignment</span>
          <span className={s.rssi}>
            <span className={s.action} onClick={this.updateProximities}>Refresh (R)</span>
            <span className={s.sortable} onClick={() => this.handleSortClicked('proximity')}>
              RSSI
              <SortOrder active={sortBy === 'proximity'} order={sortOrder}/>
            </span>
          </span>
          <span className={s.heartbeat}>
            <span className={s.sortable} onClick={this.handleHeartbeatSortClicked}>
              Heartbeat
              <SortOrder active={sortBy === 'heartbeat'} order={sortOrder} asc={false} />
            </span>
          </span>
          <span className={s.firmwareVersion}>
            <span className={s.sortable} onClick={() => this.handleSortClicked('firmwareVersion')}>
              Firmware Version
              <SortOrder active={sortBy === 'firmwareVersion'} order={sortOrder}/>
            </span>
          </span>
        </div>
        <div className={s.tableRows}>
          {resources.length === 0 && <ListItemPlaceholder label={resourceSearch.length > 0 ? "No resources match the filter" : "No resources found"} />}
          <AutoSizer>
            {({ width, height }) => (
              <FixedSizeList
                className={s.rowList}
                itemData={rowItemData}
                itemCount={resources.length}
                width={Math.max(width, 1278)}
                height={height}
                itemSize={32}
              >
                {RowWrapper}
              </FixedSizeList>
            )}
          </AutoSizer>
        </div>
      </div>
    )
  }

  render() {
    const { resourceSearch, unassignedWirepasCount, unfilteredResources, isDiscovering } = this.props

    return (
      <div className={s.root}>
        <div className={s.topBar}>
          <div>
            <span className={s.heading}>Search Wirepas devices</span>
            <span className={cn(s.action, { [s.disabled]: isDiscovering })} onClick={() => this.handleDiscover()}>
              {isDiscovering ? "Discovering..." : "Discover new devices"}
            </span>
            <span>{unassignedWirepasCount} unassigned devices, {unfilteredResources.length} devices in total</span>
          </div>
          <div className={s.actions}>
            <span className={s.action} onClick={() => this.handleSwitchLightsOn()}>All on</span>
            <span className={s.action}  onClick={() => this.handleSwitchLightsOff()}>All off</span>
          </div>
        </div>
        <input
          className={s.search}
          placeholder="Search by make and model, identifier, assignment and heartbeat"
          ref={this.searchRef}
          value={resourceSearch}
          onChange={e => this.handleSearchChange(e)}
        />
        {this.renderTable()}
      </div>
    )
  }
}

const resourceIdSelector = createSelector([siteViewSelector], siteView => siteView.resourceId)

const wirepasResourcesSelector = createSelector(
  [resourcesWithObjectSelector],
  (resources) => resources.filter(r => r.protocol === "wirepas")
)

const resourceSearchTerm = (r, lastSeen) =>
  `${r.model || "unknown make and model"} ${r.id} ${assignment(r)} ${resourceLastSeenFormatted(lastSeen[r.id])} ${isResourceLastSeenOffline(lastSeen[r.id]) ? "offline" : ""}`
const isMatch = (source, search) => {
  const sourceWords = source.split(" ")
  const searchWords = search.split(" ")

  return searchWords.every(searchWord => sourceWords.some(sourceWord => sourceWord.toLowerCase().includes(searchWord.toLowerCase())))
}

const resourceSearchSelector = state => state.resourceOverviewScreen.search
const filteredResourcesSelector = createSelector(
  [wirepasResourcesSelector, resourceSearchSelector, resourcesLastSeenSelector],
  (resources, search, lastSeen) =>
    resources
      .filter(r => search.length === 0 || isMatch(resourceSearchTerm(r, lastSeen), search))
)

const nextResourceId = createSelector(
  [filteredResourcesSelector, resourceIdSelector],
  (resources, currentId) => {
    const currentResource = resources.find(r => r.id === currentId)
    const currentIndex = resources.indexOf(currentResource)

    const nextIndex = currentIndex === -1
      ? 0
      : ((currentIndex + 1) % resources.length)

    const nextResource = resources[nextIndex]
    return nextResource && nextResource.id
  }
)

const prevResourceId = createSelector(
  [filteredResourcesSelector, resourceIdSelector],
  (resources, currentId) => {
    const currentResource = resources.find(r => r.id === currentId)
    const currentIndex = resources.indexOf(currentResource)

    const prevIndex = currentIndex === -1
      ? 0
      : ((currentIndex + resources.length - 1) % resources.length)

    const prevResource = resources[prevIndex]
    return prevResource && prevResource.id
  }
)

const unassignedWirepasCountSelector = createSelector(
  [unassignedResourcesSelector],
  (resources) => resources.filter(r => r.protocol === 'wirepas').length
)

const mapStateToProps = createStructuredSelector({
  siteId: matchSiteIdSelector,
  resources: filteredResourcesSelector,
  unfilteredResources: wirepasResourcesSelector,
  resourceId: resourceIdSelector,
  resourcesLastSeen: resourcesLastSeenSelector,
  nextResourceId,
  prevResourceId,
  unassignedWirepasCount: unassignedWirepasCountSelector,
  proximities: (state) => state.resourceOverviewScreen.proximities,
  resourceSearch: resourceSearchSelector,
  sortBy: (state) => state.resourceOverviewScreen.sortBy,
  sortOrder: (state) => state.resourceOverviewScreen.sortOrder,
  isDiscovering: state => state.resourceOverviewScreen.isDiscovering
})

ResourceOverviewScreen.contextType = ProximityScannerContext
export default connect(mapStateToProps)(ResourceOverviewScreen)
