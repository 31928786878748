import React, { useState, useEffect, useRef } from 'react'
import { connect } from 'react-redux'
import * as R from 'ramda'
import { createSelector, createStructuredSelector } from 'reselect'
import cn from 'classnames'
import { siteConfigurationSelector, resourcesWithObjectSelector } from '../../selectors'
import { siteObjectAssignResource, discover, blinkStart, blinkStop, discoverClearNew, siteResourceUpdatePart, wirepasDirectCommand } from '../../actions'
import { setAutoBlinkEnabled, setProximityData, setResourceSortBy, setResourceSortOrder } from '../../actions/resourceOverviewScreen'
import { useEventListener } from '../../hooks'
import Modal from '../../components/Modal'
import Heading from '../../components/Heading'
import GroupHeading from '../../components/GroupHeading'
import SortOrder from '../../components/SortOrder'
import ListItemPlaceholder from '../../components/ListItemPlaceholder'
import TransitionGroup from './TransitionGroup'
import ResourceItem from './ResourceItem'
import ObjectItem from './ObjectItem'
import useScanner from './useScanner'
import { shouldEnableBleReceiverOnAssign } from '../../utils'

import s from './ResourceOverviewModal.module.scss'
import { roomsSort, objectsSort } from '../../utils/sort'

const flattenGroup = (rooms, group) => {
  let arr = []
  rooms.forEach(room => {
    const elems = group[room.id]
    if (elems) arr = arr.concat(elems)
  })
  return arr
}

const matchesResourceType = resource => object => {
  if (!resource) return false
  switch (resource.type) {
    case 'actuator':
      return object.type === 'light'
    default:
      return object.type === resource.type
  }
}

const matchesResourceCapability = resource => object => {
  if (!resource) return false
  if (object.type === 'light' && object.capabilities && object.capabilities.includes('color')) return resource.capabilities.includes('color')
  else return true // default if no capabilities are specified
}

const mapObj = (obj, fn) => {
  const res = {}
  Object.keys(obj).forEach(key => {
    res[key] = fn(obj[key])
  })
  return res
}

const getInitResourceInfo = (resources, object) => {
  const res = resources.find(r => matchesResourceType(r)(object))
  return res && {
    res,
    index: resources.findIndex(r => r.id === res.id)
  }
}

const ResourceOverviewModal = ({
  siteId,
  space,
  rooms,
  groupedObjects,
  object: initObj,
  resources,
  onRequestClose,
  dispatch,
  autoBlinkEnabled,
  proximities,
  newResources,
  isDiscovering,
  sortOrder
}) => {

  const [query, setQuery] = useState("")
  const [isRssiFeedAlive, setIsRssiFeedAlive] = useState(false)
  const [lastAssignedResourceId, setLastAssignedResourceId] = useState(null)
  const handleQueryChange = ev => setQuery(ev.target.value)

  const queriedResources = resources.filter(r => (r.model||"device").toLowerCase().indexOf(query.toLowerCase()) >= 0)

  const [selectedResInfo, setSelectedResInfo] = useState(getInitResourceInfo(queriedResources, initObj))
  const selectedResId = selectedResInfo && selectedResInfo.res.id
  const selectedRes = selectedResInfo && selectedResInfo.res

  const moveResSelection = d => {
    if (selectedResId) {
      const ix = queriedResources.findIndex(r => r.id === selectedResId)
      const newIx = ix === -1 ? 0 : ((ix +  queriedResources.length + d) % queriedResources.length)
      setSelectedResForPosition(newIx)
    }
  }

  const setSelectedResForPosition = (index) => {
    const res = queriedResources[index]
    setSelectedResInfo(res ? { index, res } : undefined) 
  }

  useEffect(() => {
    if(!selectedResInfo) { 
      setSelectedResForPosition(0)
      return 
    }

    const ix = queriedResources.findIndex(r => r.id === selectedResId)
    
    if (ix === -1) {
      const newRes = queriedResources[selectedResInfo.index]

      if (newRes) {
        setSelectedResInfo({
          index: selectedResInfo.index,
          res: newRes
        })
      } else {
        setSelectedResForPosition(0)
      }
    } else if (selectedResInfo.index !== ix) {
      setSelectedResInfo({
        index: ix,
        res: selectedResInfo.res
      })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resources])

  useEffect(() => {
    dispatch(setResourceSortBy('proximity'))
    dispatch(setResourceSortOrder('desc'))
    updateProximities()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const groupedMatchingObjects = selectedRes ? mapObj(groupedObjects, objs => objs.filter(matchesResourceType(selectedRes))) : groupedObjects
  const allMatchingObjects = flattenGroup(rooms, groupedMatchingObjects)

  const [selectedObj, setSelectedObj] = useState(initObj)
  const selectedObjId = selectedObj && selectedObj.id

  const moveObjSelection = d => {
    if (selectedObjId) {
      const ix = allMatchingObjects.findIndex(o => o.id === selectedObjId)
      const newIx = ix === -1 ? 0 : ((ix +  allMatchingObjects.length + d) % allMatchingObjects.length)
      setSelectedObjForPosition(newIx)
    }
  }

  const setSelectedObjForPosition = (index) => {
    const obj = allMatchingObjects[index]
    setSelectedObj(obj)
  }

  useEffect(() => {
    if(!selectedObjId) {
      setSelectedObjForPosition(0)
      return
    }

    const ix = allMatchingObjects.findIndex(o => o.id === selectedObjId)

    if (ix === -1) {
      setSelectedObjForPosition(0)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupedObjects, selectedResInfo])

  const handleAssign = (object, resource) => {
    if (!resource || !object) return
    dispatch(siteObjectAssignResource(siteId, object.id, resource.id))

    setLastAssignedResourceId(resource.id)

    if(shouldEnableBleReceiverOnAssign(object, resource)) {
      dispatch(siteResourceUpdatePart(siteId, resource.id, { bleReceiverEnabled: true }))
    }
  }

  const handleSelectObject = (obj) => {
    const ix = allMatchingObjects.indexOf(obj)
    if (ix !== -1) {
      setSelectedObj(obj)
    }
  }

  const handleDiscover = () => {
    dispatch(discover(siteId))
  }

  const handleToggleAutoBlink = () => {
    dispatch(setAutoBlinkEnabled(!autoBlinkEnabled))
  }

  useEffect(() => {
    let timer = null
    let isBlinking = false
    if (autoBlinkEnabled && selectedResId) {
      timer = setTimeout(() => {
        dispatch(blinkStart(siteId, selectedResId))
        isBlinking = true
      }, 400)
    }
    return () => {
      clearTimeout(timer)
      if (isBlinking && selectedResId) {
        dispatch(blinkStop(siteId, selectedResId))
      }
    }
  }, [selectedResId, autoBlinkEnabled, dispatch, siteId])

  const scanner = useScanner()
  const updateProximities = () => {
    dispatch(setProximityData(scanner.getProximities()))
    setIsRssiFeedAlive(!scanner.isDead())
  }

  const turnAllOn = () => {
    dispatch(wirepasDirectCommand(siteId, 'lightOn'))
  }

  const turnAllOff = () => {
    dispatch(wirepasDirectCommand(siteId, 'lightOff'))
  }

  const searchRef = useRef(null)

  const matchesType = matchesResourceType(selectedRes)
  const matchesCapability = matchesResourceCapability(selectedRes)

  const closeModal = () => {
    dispatch(discoverClearNew())
    onRequestClose()
  }

  useEventListener('keydown', (ev) => {
    const key = ev.key.toLowerCase()
    const searchInputHasFocus = searchRef.current === document.activeElement

    if(ev.ctrlKey || ev.metaKey) return

    if (searchInputHasFocus) {
      switch(key) {
        case 'escape':
          searchRef.current.blur()
          return
        case 'arrowup':
        case 'arrowdown':
        case 'enter':
          searchRef.current.blur()
          break
        default:
          return
      }
    }

    switch(key) {
      case 'arrowup':
        ev.shiftKey ? moveObjSelection(-1) : moveResSelection(-1)
        break
      case 'arrowdown':
        ev.shiftKey ? moveObjSelection(1) : moveResSelection(1)
        break
      case 'enter':
        handleAssign(selectedObj, selectedRes)
        break
      case 'escape':
        closeModal()
        break
      case 'b':
        handleToggleAutoBlink()
        break
      case 'r':
        updateProximities()
        break
      case 'f':
        searchRef.current.focus()
        break
      default:
        return
    }

    ev.preventDefault()
    ev.stopPropagation()
  })

  return (
    <Modal onRequestClose={closeModal}>
      <div className={s.container}>
        <div className={s.left}>
          <div className={s.toolbar}>
            <Heading label='Unassigned Wirepas devices' actions={[{ label: "Done", onClick: closeModal }]} border />
            <div className={s.row}>
              <div className={cn(s.action, { [s.disabled]: isDiscovering })} onClick={handleDiscover}>
                {isDiscovering ? "Discovering..." : "Discover new Wirepas devices"}
              </div>
            </div>
            <input
              type="text"
              placeholder="Search devices"
              className={s.search}
              onChange={handleQueryChange}
              value={query}
              ref={searchRef}
            />
          </div>
          <div className={s.resourceHeaders}>
            <span className={s.make}>Make and model</span>
            <span className={s.rssi}>
              <span className={s.action} onClick={updateProximities}>Refresh (R)</span>
              <span className={cn(s.sortable, s.disabled)}>
                RSSI
                <SortOrder active={true} order={sortOrder} asc={false} />
              </span>
            </span>
          </div>
          <div className={s.resourceList}>
            <TransitionGroup>
              {queriedResources.map((res, index)=> (
                <ResourceItem
                  key={res.id}
                  siteId={siteId}
                  resource={res}
                  isNew={newResources.has(res.address)}
                  rssi={isRssiFeedAlive ? (proximities[res.address] || -Infinity) : '---'}
                  active={res.id === selectedResId}
                  autoBlinkEnabled={autoBlinkEnabled}
                  toggleAutoBlink={handleToggleAutoBlink}
                  highlight={false}
                  onClick={() => setSelectedResInfo({ res, index })}
                />
              ))}
            </TransitionGroup>
            {queriedResources.length === 0 && (
              <ListItemPlaceholder label="No available devices" />
            )}
          </div>
        </div>
        <div className={s.right}>
          <div className={s.toolbar}>
            <div className={s.titleRow}>
              <div className={s.spaceTitle}>
                {space.name}
              </div>
              <div className={s.controls}>
                All wirepas devices
                <div className={s.buttons}>
                  <div onClick={turnAllOn}>On</div>
                  &bull;
                  <div onClick={turnAllOff}>Off</div>
                </div>
              </div>
            </div>
            <div className={s.row}>
              <span className={s.infoicon}>i</span>
              <span className={s.info}>
                Shift-up/down moves selection
              </span>
            </div>
          </div>
          <div className={s.objectList}>
            {rooms.map(room => {
              return (
                <React.Fragment key={room.id}>
                  <GroupHeading label={room.name} />
                  {groupedObjects[room.id] && groupedObjects[room.id].map(obj => {
                    const match = matchesType(obj) && matchesCapability(obj)
                    return (
                      <ObjectItem
                        siteId={siteId}
                        key={obj.id}
                        object={obj}
                        selected={obj.id === selectedObjId}
                        disabled={!selectedRes || !match}
                        lastAssignedResourceId={lastAssignedResourceId}
                        onClick={() => {
                          handleSelectObject(obj)
                          handleAssign(obj, selectedRes)
                        }}
                      />
                    )
                  })}
                  {(!groupedObjects[room.id] || groupedObjects[room.id].length === 0) && (
                    <ListItemPlaceholder label="No logical devices" />
                  )}
                </React.Fragment>
              )
            })}
            {rooms.length === 0 && (
              <ListItemPlaceholder label="No rooms" />
            )}
          </div>
        </div>
      </div>
    </Modal>
  )
}

const filteredResourcesSelector = createSelector(
  [resourcesWithObjectSelector],
  (resources) => {
    return resources
      .filter(resource => resource.protocol === 'wirepas')
      .filter(resource => !resource.assignedObject)
  }
)

const roomsSelector = createSelector(
  [siteConfigurationSelector],
  (siteConfiguration) => siteConfiguration.rooms || []
)
const sortedRoomsSelector = createSelector(
  [roomsSelector],
  (rooms) => roomsSort(rooms)
)
const spacesSelector = createSelector(
  [siteConfigurationSelector],
  (siteConfiguration) => siteConfiguration.spaces
)
const objectsSelector = createSelector(
  [siteConfigurationSelector],
  (siteConfiguration) => siteConfiguration.objects || []
)
const sortedObjectsSelector = createSelector(
  [objectsSelector],
  (objects) => objectsSort(objects)
)

const spaceIdSelector = (state, props) => props.spaceId
const spaceSelector = createSelector(
  [spaceIdSelector, spacesSelector],
  (spaceId, spaces) => R.find(R.propEq('id', spaceId), spaces)
)

const filteredSpaceRoomsSelector = createSelector(
  [spaceIdSelector, sortedRoomsSelector],
  (spaceId, rooms) => rooms.filter(r => r.spaceId === spaceId)
)
const groupedSpaceObjectsSelector = createSelector(
  [filteredSpaceRoomsSelector, sortedObjectsSelector],
  (rooms, objects) => {
    const roomIds = rooms.map(r => r.id)
    const groups = {}
    objects
      .filter(o => roomIds.indexOf(o.roomId) >= 0)
      .filter(o => ['light', 'motionSensor', 'curtain', 'noiseSensor'].includes(o.type))
      .forEach(obj => {
        if (!(obj.roomId in groups)) {
          groups[obj.roomId] = []
        }
        groups[obj.roomId].push(obj)
      })
    return groups
  }
)

const mapStateToProps = createStructuredSelector({
  resources: filteredResourcesSelector,
  rooms: filteredSpaceRoomsSelector,
  space: spaceSelector,
  groupedObjects: groupedSpaceObjectsSelector,
  autoBlinkEnabled: (state) => state.resourceOverviewScreen.autoBlinkEnabled,
  proximities: (state) => state.resourceOverviewScreen.proximities,
  newResources: (state) => state.resourceOverviewScreen.newResources,
  isDiscovering: state => state.resourceOverviewScreen.isDiscovering,
  sortOrder: state => state.resourceOverviewScreen.sortOrder
})

export default connect(mapStateToProps)(ResourceOverviewModal)
