import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'

import './index.scss'

import { ProximityScannerContext, createProximityScanner } from './utils/proximityScanner'
import { configureStore } from './store'
import PropertyManager from './PropertyManager'
import * as serviceWorker from './serviceWorker'

const store = configureStore()

const proximityScanner = createProximityScanner('wss://kelvin-nelonen-ble-forwarder.herokuapp.com/listen', 10 * 1000)
ReactDOM.render(
  <Provider store={store}>
    <ProximityScannerContext.Provider value={proximityScanner}>
      <PropertyManager />
    </ProximityScannerContext.Provider>
  </Provider>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
