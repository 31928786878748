import React, { Component } from 'react'
import * as R from 'ramda'
import { connect } from 'react-redux'
import { createSelector, createStructuredSelector } from 'reselect'
import cn from 'classnames'
import { DateTime } from 'luxon'

import { matchSiteIdSelector, makeKeyListSelector, makeLastKeySelector } from '../selectors'
import { apiKeyList, apiKeyCreate, apiKeyDelete, apiKeyLastClear } from '../actions'
import s from './KeyOverviewScreen.module.scss'
import ListItemPlaceholder from '../components/ListItemPlaceholder'
import KeyUpdateModal from '../containers/KeyUpdateModal'

class KeyOverviewScreen extends Component {
  constructor(...args) {
    super(...args)
    this.state = {
      editKeyId: null,
      editKeyToken: null,
    }
  }

  componentDidMount() {
    const { siteId, dispatch } = this.props
    dispatch(apiKeyList(siteId))
  }

  componentDidUpdate(prevProps) {
    const { lastCreateKey, siteId, dispatch } = this.props
    if (lastCreateKey && lastCreateKey !== prevProps.lastCreateKey) {
      dispatch(apiKeyLastClear(siteId))
      this.setState({ editKeyId: lastCreateKey.id, editKeyToken: lastCreateKey.token })
    }
  }

  handleCreateClick = () => {
    const { siteId, dispatch } = this.props
    const name = 'Key ' + Math.floor(Math.random() * 0xffffff).toString(16).toUpperCase()
    dispatch(apiKeyCreate(siteId, { name }))
  }

  renderKey(apiKey) {
    const { siteId, dispatch } = this.props

    const handleEditClick = (ev) => {
      ev.preventDefault()
      this.setState({ editKeyId: apiKey.id, editKeyToken: null })
    }
    const handleDeleteClick = (ev) => {
      ev.preventDefault()
      if (window.confirm(`Permanently delete "${apiKey.name}" key?`)) {
        dispatch(apiKeyDelete(siteId, apiKey.id))
      }
    }

    return (
      <div key={apiKey.id} className={s.tableRow} onDoubleClick={handleEditClick}>
        <div className={cn(s.cellRow, s.cellWidthName)}>
          {apiKey.name || 'Unnamed key'}
        </div>
        <div className={cn(s.cellRow, s.cellWidthDate)}>
          {DateTime.fromISO(apiKey.createDate).toFormat('EEE d MMM yyyy')}
        </div>
        <div className={cn(s.cellRow, s.cellWidthDescription)}>
          {apiKey.description}
        </div>
        <div className={cn(s.cellRow, s.cellWidthUser)}>
          {apiKey.userEmail}
        </div>
        <div className={s.cellRowTail}>
          <div className={s.editLink} onClick={handleEditClick}>
            Edit key
          </div>
          <div className={s.deleteLink} onClick={handleDeleteClick}>
            Delete key
          </div>
        </div>
      </div>
    )
  }

  renderLastKeyModal() {
    const { siteId } = this.props
    const { editKeyId, editKeyToken } = this.state

    if (editKeyId == null) {
      return null
    }

    const handleModalClose = () => {
      this.setState({ editKeyId: null, editKeyToken: null })
    }

    return (
      <KeyUpdateModal
        siteId={siteId}
        keyId={editKeyId}
        keyToken={editKeyToken}
        onRequestClose={handleModalClose}
      />
    )
  }

  render() {
    const { keyList } = this.props

    return (
      <div className={s.root}>
        <div className={s.table}>
          <div className={s.tableHead}>
            <div className={cn(s.cellHeader, s.cellWidthName)}>API key name</div>
            <div className={cn(s.cellHeader, s.cellWidthDate)}>Date added</div>
            <div className={cn(s.cellHeader, s.cellWidthDescription)}>Description</div>
            <div className={cn(s.cellHeader, s.cellWidthUser)}>Created by</div>
            <div className={s.cellHeaderTail}>
              <div className={s.createLink} onClick={this.handleCreateClick}>
                Create a new key
              </div>
            </div>
          </div>
          <div className={s.tableBody}>
            {keyList.length === 0 &&
              <ListItemPlaceholder label="No API keys found" />
            }
            {keyList.map(this.renderKey, this)}
          </div>
        </div>
        {this.renderLastKeyModal()}
      </div>
    )
  }
}


const sortedKeyListSelector = createSelector(
  [
    makeKeyListSelector(matchSiteIdSelector),
  ],
  (keyList) => R.sortWith([R.descend(R.prop('createDate'))], keyList)
)
const lastCreateKeySelector = makeLastKeySelector(matchSiteIdSelector)

const mapStateToProps = createStructuredSelector({
  siteId: matchSiteIdSelector,
  keyList: sortedKeyListSelector,
  lastCreateKey: lastCreateKeySelector,
})

export default connect(mapStateToProps)(KeyOverviewScreen)
