import React from 'react'
import cn from 'classnames'

import s from './CatchableRoomObject.module.scss'

import { getIconSrcForObject, getObjectDisplayText } from '../../utils'
import { useScrollIntoView } from '../../hooks'
import { siteObjectUnassignResource } from '../../actions'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { resourcesSelector } from '../../selectors'

const getLabel = type => {
  switch(type) {
    case 'switch': return 'Press the switch three times'
    case 'doorSensor': return 'Open/close the door three times'
    case 'environmentalSensor': return 'Activate the sensor three times'
    default: return 'Trigger the device three times'
  }
}

const CatchableRoomObject = ({ dispatch, siteId, resources, object, selected, onClick, inputCaptureProgress }) => {
  const icon = getIconSrcForObject(object)
  const ref = useScrollIntoView(selected)

  const handleRemoveClick = (e, resourceId) => {
    dispatch(siteObjectUnassignResource(siteId, object.id, resourceId))

    e.preventDefault()
    e.stopPropagation()
  }

  const renderAssignment = () => {
    if(object.attachedResources.length === 0) {
      return null
    }

    const assignment = object.attachedResources[0]
    const assignedResource = resources.find(({ id }) => id === assignment)
    if(!assignedResource) return "Unknown resource"

    return (
      <div className={s.assignment}>
        <span>
          {assignedResource.model || 'Device'}
        </span>
        <span className={s.remove} onClick={e => handleRemoveClick(e, assignedResource.id)}>&nbsp;🅧</span>
      </div>
    )
  }

  const attachedResourcesCount = object.attachedResources.length
  const targetResourcesCount = 1
  return (
    <div className={cn(
      s.container, {
      [s.selected]: selected,
      [s.notice]: attachedResourcesCount < targetResourcesCount
    })} onClick={onClick} ref={ref}>
      <img src={icon} className={s.icon} />
      {getObjectDisplayText(object)}
      <div className={s.right}>
        {renderAssignment()}
        {selected && (
          <div className={s.progress}>
            {getLabel(object.type)}
            <div className={cn(s.progressDot, inputCaptureProgress >= 1 && s.progressDotFill)} />
            <div className={cn(s.progressDot, inputCaptureProgress >= 2 && s.progressDotFill)} />
            <div className={cn(s.progressDot, inputCaptureProgress >= 3 && s.progressDotFill)} />
          </div>
        )}
      </div>
    </div>
  )
}

const mapStateToProps = createStructuredSelector({
  resources: resourcesSelector
})

export default connect(mapStateToProps)(CatchableRoomObject)
