import * as R from 'ramda'

const getObjectTypePriority = (object) => {
  switch (object.type) {
    case 'light': return 1
    case 'motionSensor': return 2
    case 'switch': return 3
    case 'doorSensor': return 4
    case 'environmentalSensor': return 5
    default: return 6
  }
}

const sortByNameAsc = R.sortWith([R.ascend(R.prop('name'))])
const sortByNameDesc = R.sortWith([R.descend(R.prop('name'))])
const sortByObjectTypeAsc = R.sortWith([R.ascend(getObjectTypePriority)])

export const objectsSort = objects => sortByObjectTypeAsc(sortByNameAsc(objects))
export const roomsSort = sortByNameAsc
export const spacesSort = sortByNameAsc
export const scenesSort = sortByNameAsc
export const floorsSort = sortByNameDesc
export const sitesSort = R.sortWith([R.ascend(R.compose(R.toLower, R.prop('name')))])
export const lightsSort = R.sortWith([
  R.ascend(R.prop('spaceName')),
  R.ascend(R.prop('roomName')),
  R.ascend(R.prop('name'))
])
export const materialsSort  = sortByNameAsc
