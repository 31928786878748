import React from 'react'
import ListItem from '../../components/ListItem'
import { useScrollIntoView } from '../../hooks'

const Scene = ({ scene, selected, onSelect })  =>  {
  const ref = useScrollIntoView(selected)
  return  (
    <ListItem
      key={scene.id}
      innerRef={ref}
      label={scene.name}
      selected={selected}
      onClick={onSelect}
      />
    )
}

export default Scene
