import immer from 'immer'

import {
  SITE_INDEX,
  SITE_CREATE_SUCCESS,
  SITE_UPDATE_SUCCESS,
  SITE_UPDATE_PART_SUCCESS,
  SITE_DELETE_SUCCESS,
  SITE_CONFIGURATION,
  SITE_ROOM_CREATE,
  SITE_OBJECT_CREATE,
  SET_SITE_VIEW_PARAMS_VALIDATED,
  SITE_SCENE_CREATE,
} from '../constants/actions'

const initialState = {}
const sites = (state = initialState, { type, payload }) => {
  switch (type) {
    case SITE_INDEX:
      return immer({}, draftState => {
        const { siteList } = payload
        for (const { id, name } of siteList) {
          draftState[id] = { id, name }
        }
      })
    case SITE_CREATE_SUCCESS:
      return immer(state, draftState => {
        const { siteId, siteBody } = payload
        draftState[siteId] = { id: siteId, name: siteBody.name }
      })
    case SITE_UPDATE_SUCCESS:
      return immer(state, draftState => {
        const { siteId, siteBody } = payload
        draftState[siteId].name = siteBody.name
      })
    case SITE_UPDATE_PART_SUCCESS:
      return immer(state, draftState => {
        const { siteId, siteBodyPart } = payload
        if (siteBodyPart.name != null) {
          draftState[siteId].name = siteBodyPart.name
        }
      })
    case SITE_DELETE_SUCCESS:
      return immer(state, draftState => {
        const { siteId } = payload
        delete draftState[siteId]
      })
    case SITE_CONFIGURATION:
      return immer(state, draftState => {
        const { siteId, siteConfiguration } = payload
        draftState[siteId] = draftState[siteId] || { id: siteId, name: null }
        draftState[siteId].name = siteConfiguration.name
      })

    case SITE_OBJECT_CREATE:
      return immer(state, draftState => {
        const { siteId, objectBody } = payload
        draftState[siteId].lastCreatedObjectId = objectBody.id
      })
    case SITE_ROOM_CREATE:
      return immer(state, draftState => {
        const { siteId, roomBody } = payload
        draftState[siteId].lastCreatedRoomId = roomBody.id
      })
    case SITE_SCENE_CREATE:
      return immer(state, draftState => {
        const { siteId, sceneBody } = payload
        draftState[siteId].lastCreatedSceneId = sceneBody.id
      })
    case SET_SITE_VIEW_PARAMS_VALIDATED:
      return immer(state, draftState => {
        const { siteId, siteView: { objectId, roomId, sceneId } } = payload
        if(objectId !== state[siteId].lastCreatedObjectId) {
          draftState[siteId].lastCreatedObjectId = null
        }

        if(roomId !== state[siteId].lastCreatedRoomId) {
          draftState[siteId].lastCreatedRoomId = null
        }

        if(sceneId !== state[siteId].lastCreatedSceneId) {
          draftState[siteId].lastCreatedSceneId = null
        }
      })
    default:
      return state
  }
}

export default sites
