import { DISCOVER, DISCOVER_SUCCESS, DISCOVER_FAILURE, DISCOVER_CLEAR_NEW } from '../constants/actions'

export const discover = (siteId) => ({
  type: DISCOVER,
  payload: { siteId },
})

export const discoverSuccess = (siteId, resources) => ({
  type: DISCOVER_SUCCESS,
  payload: { siteId, resources },
})

export const discoverFailure = (siteId, error) => ({
  type: DISCOVER_FAILURE,
  payload: { siteId, error },
})

export const discoverClearNew = (siteId) => ({
  type: DISCOVER_CLEAR_NEW,
  payload: { siteId },
})
