import { takeEvery, select, put, actionChannel } from 'redux-saga/effects'
import * as R from 'ramda'
import { makeSiteConfigurationSelector } from '../selectors'
import { makeSiteViewSelector } from '../selectors/siteView'
import { roomsSort, objectsSort, spacesSort, floorsSort } from '../utils/sort'
import { UPDATE_SITE_VIEW_PARAMS, SITE_CONFIGURATION } from '../constants/actions'
import { setSiteViewValidated } from '../actions'

const EMPTY_SITE_VIEW = { floorId: null, spaceId: null, roomId: null, objectId: null, resourceId: null, sceneId: null }
const ensureDefaults = siteView => ({ ...EMPTY_SITE_VIEW, ...siteView })

// if for example floorId is null, then spaceId, roomId and objectId should be null also
const sanitize = (siteView) => {
  if(!siteView.floorId) {
    return { resourceId: siteView.resourceId, gatewayId: siteView.gatewayId }
  } else if(!siteView.spaceId) {
    return { floorId: siteView.floorId, resourceId: siteView.resourceId, gatewayId: siteView.gatewayId }
  }

  return {
    floorId: siteView.floorId,
    spaceId: siteView.spaceId,
    roomId: siteView.roomId || null,
    objectId: siteView.objectId || null,
    sceneId: siteView.sceneId || null,
    resourceId: siteView.resourceId,
    gatewayId: siteView.gatewayId
  }
}

// Validates that objectId is child of roomId, roomId is child of spaceId,
// and spaceId is child of floorId. Also validates that resourceId exists
const validateHierarchy = (siteView, siteConfiguration) => {
  const floor = siteConfiguration.floors.find(({ id }) => id === siteView.floorId)
  const space = siteConfiguration.spaces.find(({ id }) => id === siteView.spaceId)
  const room = siteConfiguration.rooms.find(({ id }) => id === siteView.roomId)
  const object = siteConfiguration.objects.find(({ id }) => id === siteView.objectId)
  const scene = siteConfiguration.scenes.find(({ id }) => id === siteView.sceneId)

  const resourceId = siteConfiguration.resources.some(({ id }) => id === siteView.resourceId) ? siteView.resourceId : null

  const gatewayId = siteConfiguration.gateways.some(({ id }) => id === siteView.gatewayId) ? siteView.gatewayId : null

  if (!floor) {
    return { resourceId, gatewayId }
  }

  if(!space || space.floorId !== floor.id) {
    return { floorId: floor.id, resourceId, gatewayId }
  }

  let roomId = room ? room.id : null
  if (room && room.spaceId !== space.id) {
    roomId = null
  }

  let objectId = object ? object.id : null
  let roomsWithSpaceId = space ? siteConfiguration.rooms.filter(({ spaceId }) => spaceId === space.id) : []
  if(object && (roomsWithSpaceId.length === 0 || !roomsWithSpaceId.some(({ id }) => id === object.roomId))) {
    objectId = null
  }

  let sceneId = scene ? scene.id : null
  if(scene && (scene.spaceId !== space.id)) {
    sceneId = null
  }

  return { floorId: floor.id, spaceId: space.id, roomId, objectId, sceneId, resourceId, gatewayId }
}

const firstFloorId = (siteConfiguration) => floorsSort(siteConfiguration.floors).map(R.prop("id"))[0] || null
const firstSpaceId = (siteConfiguration, floorId) => spacesSort(siteConfiguration.spaces).filter(s => s.floorId === floorId).map(R.prop("id"))[0] || null
const firstObjectId = (siteConfiguration, spaceId) => {
  const rooms = roomsSort(siteConfiguration.rooms)
  const objects = objectsSort(siteConfiguration.objects)

  const roomIds = rooms.filter(r => r.spaceId === spaceId).map(R.prop("id")) || []
  const firstRoomIdWithObject = roomIds.find(id => objects.some(R.prop("id")))
  return (firstRoomIdWithObject && objects.filter(o => o.roomId === firstRoomIdWithObject).map(R.prop("id"))[0]) || null
}

// If any ids are null, then try to select the first possible option
const deepSelect = (siteConfiguration, { floorId, spaceId, roomId, objectId, resourceId, sceneId, gatewayId }) => {
  floorId = floorId || firstFloorId(siteConfiguration)
  spaceId = spaceId || firstSpaceId(siteConfiguration, floorId)
  objectId = objectId ? objectId : (roomId || sceneId ? null : firstObjectId(siteConfiguration, spaceId))

  return { floorId, spaceId, roomId, objectId, resourceId, gatewayId, sceneId }
}

const validateParams = (params = {}) => {
  if(params.objectId) {
    return { ...params, roomId: null, sceneId: null }
  } else if(params.roomId) {
    return { ...params, objectId: null, sceneId: null }
  } else if(params.sceneId) {
    return { ...params, objectId: null, roomId: null }
  }

  return params
}

function* validateSiteView(action) {
  const { siteId, params } = action.payload
  const siteConfiguration = yield select(makeSiteConfigurationSelector(R.always(siteId)))
  const siteView = yield select(makeSiteViewSelector(R.always(siteId)))

  const validatedParams = validateParams(params)
  const sanitized = ensureDefaults(params ? sanitize({ ...siteView, ...validatedParams }) : siteView)
  const validated = ensureDefaults(validateHierarchy(sanitized, siteConfiguration))
  const deepSelected = deepSelect(siteConfiguration, validated)

  if(!R.equals(siteView, deepSelected)) {
    yield put(setSiteViewValidated(siteId, deepSelected))
  }
}

export default function*() {
  const siteViewUpdateTriggerActions = yield actionChannel(
    [UPDATE_SITE_VIEW_PARAMS, SITE_CONFIGURATION]
  )

  yield takeEvery(siteViewUpdateTriggerActions, validateSiteView)
}
