import immer from 'immer'

import { AUTH_LOGIN, AUTH_LOGIN_SUCCESS, AUTH_LOGIN_FAILURE } from '../constants/actions'

const initialState = {
  username: null,
  error: null,
}

const login = (state = initialState, { type, payload }) => {
  switch (type) {
    case AUTH_LOGIN:
      return immer(state, draftState => {
        draftState.username = payload.username
        draftState.error = null
      })
    case AUTH_LOGIN_SUCCESS:
      return immer(state, draftState => {
        draftState.error = null
      })
    case AUTH_LOGIN_FAILURE:
      return immer(state, draftState => {
        draftState.error = payload.error
      })
    default:
      return state
  }
}

export default login
