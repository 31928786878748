import {
  AUTH_LOGIN,
  AUTH_LOGIN_SUCCESS,
  AUTH_LOGIN_FAILURE,
  AUTH_LOGOUT,
  AUTH_LOGOUT_SUCCESS,
  AUTH_LOGOUT_FAILURE,
  AUTH_UPDATE,
  AUTH_REMOVE,
} from '../constants/actions'

export const authLogin = ({ username, password }) => ({
  type: AUTH_LOGIN,
  payload: { username, password },
})
export const authLoginSuccess = () => ({
  type: AUTH_LOGIN_SUCCESS,
})
export const authLoginFailure = ({ error }) => ({
  type: AUTH_LOGIN_FAILURE,
  payload: { error },
})

export const authLogout = () => ({
  type: AUTH_LOGOUT,
})
export const authLogoutSuccess = () => ({
  type: AUTH_LOGOUT_SUCCESS,
})
export const authLogoutFailure = () => ({
  type: AUTH_LOGOUT_FAILURE,
})

export const authUpdate = ({ username, accessToken, refreshToken }) => ({
  type: AUTH_UPDATE,
  payload: { username, accessToken, refreshToken },
})
export const authRemove = () => ({
  type: AUTH_REMOVE,
})
