import React, { useRef, useEffect } from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector, createSelector } from 'reselect'
import { withRouter } from "react-router-dom"
import { v4 as uuidv4 } from 'uuid'

import { siteConfigurationSelector, resourcesSelector, offlineWirepasDevicesSelector } from '../../selectors'
import { updateSiteViewParams, siteObjectCreate } from '../../actions/site'
import GroupHeading from '../../components/GroupHeading'
import ListItemPlaceholder from '../../components/ListItemPlaceholder'
import RoomObject from './RoomObject'
import { useEventListener } from '../../hooks'
import { objectsSort } from '../../utils/sort'

import s from './Room.module.scss'
import Modal from '../../components/Modal'

const hasBleReceiverAssigned = (object, resources) => {
  return object.attachedResources.map(id => resources.find(r => r.id === id)).filter(Boolean).some(r => r.bleReceiverEnabled)
}

const createObject = (dispatch, siteId, objectBody) => {
  const id = uuidv4()
  dispatch(siteObjectCreate(siteId, { id, ...objectBody }))
  dispatch(updateSiteViewParams(siteId, { objectId: id }))
}

const createLight = (dispatch, siteId, room, objects) => {
  const lightCount = objects.filter(o => o.type === 'light').length
  createObject(dispatch, siteId, {
    roomId: room.id,
    type: 'light',
    capabilities: ['dimmable'],
    name: `Light #${lightCount + 1}`,
  })
}

const createColorLight = (dispatch, siteId, room, objects) => {
  const lightCount = objects.filter(o => o.type === 'light').length
  createObject(dispatch, siteId, {
    roomId: room.id,
    type: 'light',
    capabilities: ['dimmable', 'color'],
    name: `Color light #${lightCount + 1}`,
  })
}

const createMotionSensor = (dispatch, siteId, room, objects) => {
  createObject(dispatch, siteId, {
    roomId: room.id,
    type: 'motionSensor',
    name: '',
    configuration: {
      motion: {
        action: "lightOn",
        roomId: room.id
      },
      absence: {
        action: "lightOff",
        roomId: room.id,
        offDelay: 30 * 60 * 1000
      }
    },
  })
}

const createDoorSensoor = (dispatch, siteId, room, objects) => {
  createObject(dispatch, siteId, {
    roomId: room.id,
    type: 'doorSensor',
    name: '',
    configuration: {
      open: {
        action: "lightOn",
        roomId: room.id
      },
      close: {
        action: "lightOff",
        objectIds: []
      }
    },
  })
}

const createSwitch = (dispatch, siteId, room, objects, type) => {
  createObject(dispatch, siteId, {
    roomId: room.id,
    type: 'switch',
    variant: type,
    name: '',
    configuration: {
      left: {
        action: 'lightPushDim',
        objectIds: [],
      },
      double: {},
      right: {
        action: 'lightPushDim',
        roomId: room.id,
      },
    },
  })
}

const createCurtain = (dispatch, siteId, room, objects) => {
  const curtainCount = objects.filter(o => o.type === 'curtain').length
  createObject(dispatch, siteId, {
    roomId: room.id,
    type: 'curtain',
    name: `Curtain #${curtainCount + 1}`,
  })
}

const createEnvironmentalSensor = (dispatch, siteId, room, objects) => {
  createObject(dispatch, siteId, {
    roomId: room.id,
    type: 'environmentalSensor',
    name: '',
  })
}

const createNoiseSensor = (dispatch, siteId, room, objects) => {
  createObject(dispatch, siteId, {
    roomId: room.id,
    type: 'noiseSensor',
    name: '',
  })
}

const RoomSectionContainer = ({
  dispatch, siteId, room, objects,
  resources, selected, selectedObjectId,
  offlineDevices, isToolbarActive,
  onOpenToolbar, onCloseToolbar,
  disabled
}) => {
  const headerRef = useRef()

  const handleObjectNavigate = (object) => {
    dispatch(updateSiteViewParams(siteId, { objectId: object.id }))
  }

  const selectRoom = () => {
    dispatch(updateSiteViewParams(siteId, { roomId: room.id }))
  }

  useEffect(() => {
    if(selected) {
      headerRef.current.scrollIntoView({ block: 'center', behavior: 'smooth' })
    }
  }, [selected])

  useEventListener("keydown", e => {
    if (document.activeElement && document.activeElement.tagName !== "BODY") {
      return
    }

    if(e.ctrlKey || e.metaKey) return
    if(!isToolbarActive) return

    const key = e.key.toUpperCase()
    if(key === 'L') {
      createRoomLight()
    } else if(key === 'M') {
      createRoomMotionSensor()
    } else if(key === 'D') {
      createRoomDoorSensor()
    } else if(key === '1')  {
      createRoomOneKey()
    } else if(key === '2') {
      createRoomTwoKey()
    } else if(key === 'C') {
      createRoomCurtain()
    } else if(key === 'R') {
      createRoomColorLight()
    } else if(key === 'E') {
      createRoomEnvironmentalSensor()
    } else if(key === 'N') {
      createRoomNoiseSensor()
    } else if(key === 'ESCAPE') {
      if(!Modal.isModalOpen()) {
        onCloseToolbar()
      }
    } else {
      return
    }

    e.stopPropagation()
    e.preventDefault()
  })

  const createRoomLight = () => createLight(dispatch, siteId, room, objects)
  const createRoomColorLight = () => createColorLight(dispatch, siteId, room, objects)
  const createRoomMotionSensor = () => createMotionSensor(dispatch, siteId, room, objects)
  const createRoomDoorSensor = () => createDoorSensoor(dispatch, siteId, room, objects)
  const createRoomOneKey = () => createSwitch(dispatch, siteId, room, objects, "1-key")
  const createRoomTwoKey = () => createSwitch(dispatch, siteId, room, objects, "2-key")
  const createRoomCurtain = () => createCurtain(dispatch, siteId, room, objects)
  const createRoomEnvironmentalSensor = () => createEnvironmentalSensor(dispatch, siteId, room, objects)
  const createRoomNoiseSensor = () => createNoiseSensor(dispatch, siteId, room, objects)

  return (
    <>
      <GroupHeading
        ref={headerRef}
        onClick={selectRoom}
        selected={selected}
        label={room.name}
        actions={
          isToolbarActive || disabled
            ? []
            : [{ label: "Add logical device", onClick: onOpenToolbar }]
        }
        showActionsOnHover
      />
      {isToolbarActive && (
        <div className={s.addLogicalDeviceToolbar}>
          <div className={s.toolbarActions}>
            <span className={s.toolbarAction} onClick={createRoomLight}>Light</span>
            <span className={s.toolbarAction} onClick={createRoomColorLight}>RGB light</span>
            <span className={s.toolbarAction} onClick={createRoomMotionSensor}>Motion sensor</span>
            <span className={s.toolbarAction} onClick={createRoomOneKey}>1-key</span>
            <span className={s.toolbarAction} onClick={createRoomTwoKey}>2-key</span>
            <span className={s.toolbarAction} onClick={createRoomDoorSensor}>Door sensor</span>
            <span className={s.toolbarAction} onClick={createRoomCurtain}>Curtain</span>
            <span className={s.toolbarAction} onClick={createRoomEnvironmentalSensor}>Environmental Sensor</span>
            <span className={s.toolbarAction} onClick={createRoomNoiseSensor}>Noise Sensor</span>
          </div>
          <span className={s.toolbarClose} onClick={onCloseToolbar}>
            ⓧ
          </span>
        </div>
      )}
      {objects.length === 0 && <ListItemPlaceholder label="No logical devices" />}
      {objects.map(obj => (
        <RoomObject
          key={obj.id}
          siteId={siteId}
          room={room}
          object={obj}
          onClick={() => handleObjectNavigate(obj)}
          isOffline={offlineDevices.some(d => d.assignedObject && d.assignedObject.id === obj.id)}
          bleReceiverAssigned={hasBleReceiverAssigned(obj, resources)}
          selected={selectedObjectId === obj.id}
        />
      ))}
    </>
  )
}

const objectsSelector = createSelector(
  [siteConfigurationSelector],
  (siteConfiguration) => siteConfiguration.objects || []
)
const sortedObjectsSelector = createSelector(
  [objectsSelector],
  (objects) => objectsSort(objects)
)

const roomIdSelector = (state, props) => props.room.id

const sortedRoomObjectsSelector = createSelector(
  [roomIdSelector, sortedObjectsSelector],
  (roomId, objects) => objects.filter(r => r.roomId === roomId)
)

const mapStateToProps = createStructuredSelector({
  objects: sortedRoomObjectsSelector,
  offlineDevices: offlineWirepasDevicesSelector,
  resources: resourcesSelector
})

export default connect(mapStateToProps)(withRouter(RoomSectionContainer))
