import React from 'react'
import { createSelector, createStructuredSelector } from 'reselect'
import { connect } from 'react-redux'
import cn from 'classnames'

import { objectsSelector, isObjectJustCreatedSelector } from '../../selectors'
import Heading from '../../components/Heading'
import TextField from '../../components/TextField'
import Checkbox from '../../components/Checkbox'
import { ReactComponent as BleScannerFramedIcon } from '../../assets/ble-scanner-framed.svg'

import s from './LightConfiguration.module.scss'

const uint16topercent = (value) => {
  return value*100/65535
}

const percentTouint16 = (percent) => {
  const clamped = Math.max(0, Math.min(100, percent))
  return clamped * 65535 / 100
}

const LightConfiguration = ({
  object,
  onRename,
  onBleScannerToggle,
  isJustCreated,
  disabled,
  onBrightnessLimitsToggle,
  onBrightnessLimitChanged,
  onLightLevelChanged,
  state,
}) => {

  const setPercent = (value) => {
    if(value == null) return
    const clamped = Math.max(0, Math.min(100, value))
    const brightness = Math.round(255 * clamped / 100)
    onLightLevelChanged(brightness)
  }

  const percent = state ? state.bri * 100 / 255 : null
  return (
    <>
      <div className={s.actions}>
        <span className={s.action} onClick={() => setPercent(0)}>Off</span>
        <span className={s.action} onClick={() => setPercent(1)}>Min</span>
        <span className={s.action} onClick={() => setPercent(percent - 10)}>-10</span>
        <span className={s.action} onClick={() => setPercent(percent - 1)}>-1</span>
        <span className={s.action} onClick={() => setPercent(percent + 1)}>+1</span>
        <span className={s.action} onClick={() => setPercent(percent + 10)}>+10</span>
        <span className={s.action} onClick={() => setPercent(100)}>Max</span>
      </div>
      <Heading label="Name" />
      <TextField
        initialValue={object.name}
        onSave={onRename}
        disabled={disabled}
        activateOnMount={isJustCreated}
      />
      <Heading label={<><BleScannerFramedIcon className={s.bleScannerIcon} />BLE scanners</>} />
      <Checkbox
        className={s.bleScannerCheckbox}
        checked={object.bleScanner}
        onChange={onBleScannerToggle}
        label="The first Wirepas device assigned should act as a BLE scanner"
      />
      <Heading label="Advanced"/>
      <Checkbox
        className={s.bleScannerCheckbox}
        checked={object.brightnessLimits}
        onChange={onBrightnessLimitsToggle}
        label="Use min and max values"
      />
      <div className={cn(s.adjust, { [s.disabled]: object.brightnessLimits === undefined })}>
        <Heading label="Min"/>
        <TextField
          value={object.brightnessLimits ? uint16topercent(object.brightnessLimits.min) : ""}
          onSave={value => onBrightnessLimitChanged(percentTouint16(value), object.brightnessLimits.max)}
          disabled={disabled}
        />
        <Heading label="Max"/>
        <TextField
          value={object.brightnessLimits ? uint16topercent(object.brightnessLimits.max) : ""}
          onSave={value => onBrightnessLimitChanged(object.brightnessLimits.min, percentTouint16(value))}
          disabled={disabled}
        />
      </div>
    </>
  )

}

const objectIdSelector = (state, props) => props.objectId
const objectSelector = createSelector(
  [objectIdSelector, objectsSelector],
  (objectId, objects) => objects.find(o => o.id === objectId)
)

const roomIdSelector = createSelector(
  [objectSelector],
  (object) => object.roomId
)

const mapStateToProps = createStructuredSelector({
  isJustCreated: isObjectJustCreatedSelector,
  object: objectSelector,
  roomId: roomIdSelector,
})

export default connect(mapStateToProps, null,  null, { forwardRef: true })(LightConfiguration)
