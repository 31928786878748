import { useEffect, useRef } from 'react'

// https://usehooks.com/useEventListener/
export function useEventListener(eventName, handler, element = window){
    // Create a ref that stores handler
    const savedHandler = useRef()

    // Update ref.current value if handler changes.
    // This allows our effect below to always get latest handler ...
    // ... without us needing to pass it in effect deps array ...
    // ... and potentially cause effect to re-run every render.
    useEffect(() => {
      savedHandler.current = handler
    }, [handler])

    useEffect(
      () => {
        const isSupported = element && element.addEventListener
        if (!isSupported) return

        const eventListener = event => savedHandler.current(event)
        element.addEventListener(eventName, eventListener)

        return () => element.removeEventListener(eventName, eventListener)
      },
      [eventName, element]
    )
  }

export const useScrollIntoView = (isActive, opts = {
  behavior: 'smooth',
  block: 'nearest',
  inline: 'nearest',
}) => {
  const ref = useRef(null)
  useEffect(() => {
    isActive && setImmediate(() => {
      ref.current && ref.current.scrollIntoView(opts)
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isActive])
  return ref
}

export const useSingleClickDoubleClickEvents = ({ onSingleClick, onDoubleClick, timeout = 300 }) => {
  const timeoutId = useRef(null)
  const onClick = (...props) => {
    const isClicked = timeoutId.current !== null
    if (isClicked) {
      onDoubleClick(...props)
      clearTimeout(timeoutId.current)
      timeoutId.current = null
    } else {
      timeoutId.current = setTimeout(() => {
        onSingleClick(...props)
        timeoutId.current = null
      }, timeout)
    }
  }

  return { onClick }
}
